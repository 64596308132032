import {routerConfig} from "../routerConfig.js";



const custom_routes = []



export function getRouterConfig() {
    return [...flattenRoutes(routerConfig), ...custom_routes];
}

export function getSidebarConfig() {
    return sidebarFilter(routerConfig);
}

function sidebarFilter(routes, parentPath = '') {
    return routes.reduce((filteredRoutes, route) => {
        const { children, path, sidebar, requiredAuth, ...rest } = route;

        if (sidebar && sidebar.visible) {
            const fullPath = parentPath + path;

            const { visible, ...sidebarRest } = sidebar;

            let transformedRoute = { ...rest, path: fullPath, ...sidebarRest };

            if (children && Array.isArray(children) && children.length > 0) {
                transformedRoute.children = sidebarFilter(children, fullPath);
            }

            filteredRoutes.push(transformedRoute);
        }

        return filteredRoutes;
    }, []);
}



function flattenRoutes(routes) {
    const flatRoutes = [];

    routes.forEach(route => {
        const { children, requiredAuth, name, path, component } = route;

        const meta = { requiresAuth: !!requiredAuth };

        if (component !== null) {
            flatRoutes.push({ name, path, component, meta });
        }

        if (children && children.length > 0) {
            const childRoutes = children.map(child => ({
                ...child,
                path: path + child.path,
                meta: { requiresAuth: !!child.requiredAuth || !!requiredAuth }
            }));

            flatRoutes.push(...flattenRoutes(childRoutes));
        }
    });

    return flatRoutes;
}
