<template>
    <div class="container-fluid main-content-container px-4 pb-4">
        <div class="d-flex flex-row py-4 m-0" style="height: 100px;">
            <div class="tsukiji-prod-page-header mb-2 mt-2">
                <h3 class="tsukiji-prod-page-title">Transaction History</h3>
            </div>
            <span class="spacer"></span>

            <input type="date" class="form-control w-auto" v-model="startDate" />
            <input type="date" class="form-control w-auto" v-model="endDate" />
            <button type="button" class="card btn rounded-4 btn-outline-light" @click="getAllReceipts">📅</button>
        </div>
        <div class="row">
            <div class="col-12">
                <VueGoodTable class="card p-4 rounded" :rows="tableData" mode="remote" v-on:page-change="onPageChange"
                    v-on:per-page-change="onPerPageChange" :totalRows="totalRecords" :columns="columns"
                    :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: false,
                setCurrentPage: currentPage,
            }" :sort-options="{ enabled: false }">
                    <template #table-row="{ row, column }">
                        <span v-if="column.field == 'payment_date'">
                            {{ formatDateAndTime(row.payment_date) }}
                        </span>
                        <span v-if="column.field == 'status'">
                            <a class="tsukiji-prod-text-success">
                                {{ row.status }}
                            </a>
                        </span>
                        <span v-if="column.field == 'total'">
                            <a class="tsukiji-prod-text-success">
                                {{ row.total }}
                            </a>
                            <Popover v-if="row.c_status === 'TERMINATED'" icon="bi-info-circle" :content="row.c_status"
                                title="Campaign Status" />
                        </span>
                    </template>
                </VueGoodTable>
            </div>
        </div>
    </div>
</template>

<script>
import { apiService } from "@/services/apiService/apiService";
import { VueGoodTable } from "vue-good-table-next";
import Popover from "@/components/Shared/Popper/Popover.vue"

export default {
    name: "TransactionComponent",
    components: {
        VueGoodTable,
        Popover
    },
    data() {
        return {
            columns: [
                { label: "S No", field: "SNo" },
                { label: "Date", field: "payment_date" },
                { label: "Type", field: "payment_type" },
                { label: "Status", field: "status" },
                { label: "Amount", field: "amount" },
            ],

            tableData: [],
            startDate: new Date(),
            endDate: new Date(),
            currentPage: 1,
            pageSize: 10,
            totalRecords: 0,


        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalRecords / this.pageSize);
        },
        paginatedData() {
            const startIndex = (this.currentPage - 1) * this.pageSize;
            const endIndex = startIndex + this.pageSize;
            return this.tableData.slice(startIndex, endIndex);
        },
    },
    methods: {
        getAllReceipts() {
            console.log("this.currentPage =>", this.currentPage)
            this.$store.state.isLoading = true;
            apiService.getReceipts(this.currentPage).then((res) => {
                this.$store.state.transactionHistory = apiService.objectItemWithIndex(res.data.receipt_details, this.currentPage - 1, 10);
                this.totalRecords = res.data.receipt_count;
                console.log(" this.$store.state.transactionHistory", this.$store.state.transactionHistory)
                this.tableData = Array.from(this.$store.state.transactionHistory);
            })


                .catch((error) => {
                    console.log("error", error);
                    this.$store.state.toastMessage = "Something Went Wrong";
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        formatDateAndTime(dateTimeString) {
            const [datePart, timePart] = dateTimeString.split(" ");
            const [year, month, day] = datePart.split("-");
            const [hour, minute, second] = timePart.split(":");
            const date = new Date(year, month - 1, day, hour, minute, second);
            const formattedDate = new Intl.DateTimeFormat("en", {
                day: "numeric",
                month: "short",
                year: "numeric",
            }).format(date);
            const formattedTime = new Intl.DateTimeFormat("en", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            }).format(date);
            return `${formattedDate} - ${formattedTime}`;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.getAllReceipts();
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.getAllReceipts();
            }
        },
        onPageChange(pageObject, id) {
            this.currentPage = pageObject.currentPage;
            this.getAllReceipts();
            this.$router.push({
                name: "transaction-history",
                params: { group: this.groupId, groupName: this.groupName, id: id }, query: { page: pageObject.currentPage }
            });

        },
        onPerPageChange(perPage) {
            this.pageSize = perPage;
            this.currentPage = 1;
            this.getAllReceipts();
        },
    },
    created() {
        this.getAllReceipts();
    },
};
</script>




<style scoped>
span.spacer {
    flex: 1 1 auto;
}

.fa {
    cursor: pointer;
}

:deep(label) {
    display: none !important;
}

:deep(.form-group) {
    padding: 10px 0px !important;
}

:deep(th) {
    text-align: center;
}

:deep(td) {
    text-align: center;
    max-width: 100px;
    vertical-align: middle !important;
}

:deep(th:first-child) {
    max-width: 25px !important;
}

.tsukiji-prod-text-success {
    color: #17c671 !important;
}

.pagination-container {
    color: #606266;
    font-size: 1.1rem;
    padding: 1em;
    border: 1px solid #DCDFE6;
    background: linear-gradient(#F4F5F8, #F1F3F6);
}

.pagination-buttons {
    display: flex;
    align-items: center;
}

.btn-paginated {
    margin: 0 5px;
    border: none;
}
::v-deep table.vgt-table {
    background-color: transparent !important;
}

::v-deep .vgt-wrap__footer,
::v-deep .vgt-table thead th {
    background: transparent !important;
}

</style>
