<template>
    <QueryModal :addAiTemplate="addAiTemplate" templateIcon="true" ref="queryModal" />
    <div ref="customEditor" id="customEditor"></div>
    <SharedModal :showModal="showModal" :handleClose="handleClose" :setImageToEditor="setImageToEditor"
        :activeTab="activeTab" v-if="showModal" />
</template>

<script>
import AiIcon from '../../../assets/icons/ai.png';
import QueryModal from '@/views/Templates/ai/QueryModal.vue';
import previewIcon from '@/assets/preview.png';
import myIcon from '@/assets/gallery.png';
import ImagePicker from "@/views/Templates/ImagePicker.vue";
import { apiService } from "@/services/apiService/apiService";

export default {
    props: ["content", "keyValue", "toggleView"],
    emits: ['updateContent'],
    components: {
        SharedModal: ImagePicker,
    },
    data() {
        return {
            editorContent: "",
            Editor: null,
            isUpdatingContent: false,
            showModal: false,
            activeTab: "existing",
            imageAddedData: {
                Editor: null,
                cursorLocation: null,
                resetUploader: null,
            },
            imageUploadedDate: [],
            uploadedImages: [],
            isMounted: false
        };
    },
    methods: {
        handleClose() {
            this.showModal = false;
        },
        setInitialValue(newValue) {
            if (newValue.length) this.Editor?.setData(newValue);
        },
        adjustEditorHeight() {
            const zoomLevel = window.devicePixelRatio;
            if (this.Editor?.getData() === '') {
                if (zoomLevel > 2.5) {
                    this.Editor.resize(null, 600);
                } else if (zoomLevel >= 1.7 && zoomLevel <= 2.5) {
                    this.Editor.resize(null, 400);
                } else {
                    this.Editor.resize(null, '100vh');
                }
            } else {
                this.enableAutoGrow();
            }
        },
        enableAutoGrow() {
            if (this.Editor && this.Editor.plugins.autoGrow) {
                if (!this.Editor.config.autoGrow_onStartup) {
                    this.Editor.config.autoGrow_onStartup = true;
                }

                this.Editor.plugins.autoGrow.autoGrow(this.Editor);
            }
        },
        setupCKEditorPlugin() {
            if (!window.CKEDITOR.plugins.get('imageUpload')) {
                window.CKEDITOR.plugins.add('imageUpload', {
                    icons: 'imageUpload',
                    init: (editor) => {
                        editor.addCommand('uploadImage', {
                            exec: () => {
                                this.showModal = true;
                                this.getDatesForImage();
                            }
                        });
                        editor.ui.addButton('ImageUpload', {
                            label: 'Upload Image',
                            command: 'uploadImage',
                            toolbar: 'upload',
                            icon: myIcon
                        });
                    }
                });
            }
            if (!window.CKEDITOR.plugins.get('previewTemplate')) {
                window.CKEDITOR.plugins.add('previewTemplate', {
                    icons: 'previewTemplate',
                    init: (editor) => {
                        editor.addCommand('previewTemplate', {
                            exec: () => {
                                if (this.editorContent) this.$emit('toggleView')
                            }
                        });
                        editor.ui.addButton('PreviewTemplate', {
                            label: 'Preview Template',
                            command: 'previewTemplate',
                            toolbar: 'insert',
                            icon: previewIcon
                        });
                    }
                });
            }
            if (!window.CKEDITOR.plugins.get('generateWithAi') && this.isDev) {
                window.CKEDITOR.plugins.add('generateWithAi', {
                    icons: 'generateWithAi',
                    init: (editor) => {
                        editor.addCommand('generateWithAi', {
                            exec: () => {
                                console.log("Generate icons Runs", this.$refs.queryModal);
                                this.$refs.queryModal.openModal();
                            }
                        });
                        editor.ui.addButton('GenerateWithAi', {
                            label: 'GenerateWithAi',
                            command: 'generateWithAi',
                            toolbar: 'insert',
                            icon: AiIcon
                        });
                    }
                });
            }
        },
        setImageToEditor(selectedImage, imgProperties) {
            let imgTag = ''
            imgTag = imgProperties.link ? `<a href="${imgProperties.link}"><img src="https://${selectedImage}" ` : `<img src="https://${selectedImage}" `;
            for (const [attrName, attrValue] of Object.entries(imgProperties)) {
                if (attrValue && attrName !== 'link') {
                    imgTag += ` ${attrName}="${attrValue}"`;
                }
            }
            imgTag += imgProperties.link ? '/></a> ' : '/>'
            this.Editor.insertHtml(imgTag)
            this.selectedImage = null;
            this.showModal = false;
        },
        getDatesForImage() {
            this.$store.state.isLoading = true;
            apiService.getDatesForTheImage().then((res) => {
                this.imageUploadedDate = res.data;
                this.$store.state.isLoading = false;
                this.imageUploadedDate.forEach((value, index) => {
                    if (index == this.imageUploadedDate.length - 1) {
                        this.getImagesByDate(value);
                    }
                });
            }).catch((e) => {
                console.error("Error fetching dates for the image:", e);
                this.$store.state.isLoading = false;
            });
        },
        getImagesByDate(date) {
            this.activeImageDate = date;
            this.$store.state.isLoading = true;
            apiService.getImagesByDate(date.replaceAll("/", "-")).then((res) => {
                this.uploadedImages = res.data;
                this.$store.state.isLoading = false;
            }).catch((e) => {
                console.error("Error fetching images by date:", e);
                this.$store.state.isLoading = false;
            });
        },

        initEditor() {
            if (!this.$refs.customEditor) return
            if (window.CKEDITOR.instances?.customEditor) {
                window.CKEDITOR.instances.customEditor.destroy();
            }
            const editorConfig = {
                docType: '<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">',
                toolbar: [
                    {
                        name: "document",
                        items: [
                            "Source",
                            "-",
                        ],
                    },
                    {
                        name: "forms",
                        items: [
                            "ImageUpload", "PreviewTemplate",
                        ],
                    },
                    {
                        name: "basicstyles",
                        items: [
                            "Bold",
                            "Italic",
                            "Underline"
                        ],
                    },
                    {
                        name: "paragraph",
                        items: [
                            "NumberedList",
                            "BulletedList",
                            "-",
                            "Outdent",
                            "Indent",
                            "-",
                            "JustifyLeft",
                            "JustifyCenter",
                            "JustifyRight",
                            "JustifyBlock",
                        ],
                    },
                    { name: "links", items: ["Link", "Unlink"] },
                    {
                        name: "insert",
                        items: [
                            "Image",
                            "Flash",
                            "Table",
                            "HorizontalRule",
                        ],
                    },
                    { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
                    { name: "colors", items: ["TextColor", "BGColor"] },
                    this.isDev && {
                        name: "extra", items: ["GenerateWithAi"]
                    }
                ],
                // add any additional configuration options here
                extraPlugins: this.isDev ? "autogrow,imageUpload,previewTemplate,generateWithAi" : "autogrow,imageUpload,previewTemplate",
                autoGrow_minHeight: 400,
                autoGrow_maxHeight: 2000,
                autoGrow_bottomSpace: 10,
                autoGrow_onStartup: true,
                language: "en",
                removePlugins: ["elementspath", "exportpdf"],
                resize_enabled: false,
                allowedContent: true,
            };
            setTimeout(() => {
                this.Editor = window.CKEDITOR.replace(this.$refs.customEditor, editorConfig);
                this.Editor?.on('instanceReady', () => {
                    this.adjustEditorHeight();
                });
                this.Editor?.on("change", () => {
                    this.editorContent = this.Editor?.getData();
                    this.$emit('updateContent', this.editorContent);
                });
                this.setInitialValue(this.content);
                window.addEventListener('resize', this.adjustEditorHeight);
                this.setupCKEditorPlugin();
            }, 70);
        },
        addAiTemplate(query) {
            console.log("query", query)
        }
    },
    computed: {
        isDev() {
            return process.env.VUE_APP_Environment === "dev";
        },
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustEditorHeight);
        if (this.Editor) this.Editor?.destroy();
        const scripts = document.querySelectorAll('script[src^="https://cdn.ckeditor.com"]');
        for (let i = 0; i < scripts.length; i++) scripts[i].remove();
        const styles = document.querySelectorAll('link[href^="https://cdn.ckeditor.com"]');
        for (let i = 0; i < styles.length; i++) styles[i].remove();
        window.CKEDITOR = undefined;
    },
    watch: {
        content: function (newValue) {
            if (!this.isUpdatingContent) {
                this.setInitialValue(newValue);
                this.isUpdatingContent = true;
            }
        },
    },
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js';
        script.onload = this.initEditor;
        document.head.appendChild(script);
    }
};
</script>

<style>
.cke_notification_warning {
    display: none !important;
}
</style>
