<template>
    <div class="form full-form auth-cover">
        <div class="form-container">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">Password Recovery</h1>
                            <p class="signup-link">Enter your email and instructions will sent to you!</p>
                            <form class="text-start" v-if="step.value == 1" @submit.prevent="submitStep1">
                                <div class="form">
                                    <div id="email-field" class="field-wrapper input">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-at-sign"
                                        >
                                            <circle cx="12" cy="12" r="4"></circle>
                                            <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                        </svg>
                                        <input type="email" class="form-control" :class="{'is-invalid':!isEmailValid.value}" placeholder="Email" v-model="email"  />
                                        <div class="invalid-feedback" v-if="!isEmailValid.value">{{ isEmailValid.message }}</div>
                                    </div>
                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form class="text-start" v-if="step.value == 2" @submit.prevent="submitStep()">
                                <div class="form">
                                    <div id="email-field" class="field-wrapper input">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-at-sign"
                                        >
                                            <circle cx="12" cy="12" r="4"></circle>
                                            <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                        </svg>
                                        <input type="text" class="form-control" placeholder="Verification Code" v-model="code" />
                                        <div class="invalid-feedback" v-if="!isCodeValid.value">{{ isCodeValid.message }}</div>
                                    </div>
                                    <div id="password-field" class="field-wrapper input pb-3" >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-lock"
                                        >
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <input type="password"  class="form-control" :class="{'is-invalid': !isPasswordValid.value}" placeholder="New Password" v-model="password"/>
                                        <div class="invalid-feedback" v-if="!isPasswordValid.value">{{ isPasswordValid.message }}</div>
                                    </div>
                                    <div id="password-field" class="field-wrapper input pb-3" >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-lock"
                                        >
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <input type="password"  class="form-control" :class="{'is-invalid': !isPasswordValid.value}" placeholder="Confirm Password" v-model="confirmPassword"/>
                                    </div>
                                    <div class="invalid-feedback d-block my-2" v-if="authError.value">{{ authError.message }}</div>
                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                            <p class="terms-conditions">
                                © 2020 All Rights Reserved. <router-link to="/">CORK</router-link> is a product of Arrangic Solutions LLP. <a href="javascript:void(0);">Cookie Preferences</a>,
                                <a href="javascript:void(0);">Privacy</a>, and <a href="javascript:void(0);">Terms</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-image">
                <div class="l-image"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import '@/assets/sass/authentication/auth.scss';
    import { reactive,ref } from 'vue';
    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Password Recovery Cover' });
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const router = useRouter();
    const store = useStore();
    const step = reactive({ value: 1 });
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const code = ref('');

    const isEmailValid = reactive({ value: true, message: '' });
    const isPasswordValid = reactive({ value: true, message: '' });
    const isCodeValid = reactive({value: true,message: ''});
    const authError = reactive({value: false,message: ''});

    const validateEmail = () => {
        if (email.value === '') {
            isEmailValid.value = false;
            isEmailValid.message = 'Please enter your email address.';
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        isEmailValid.value = emailRegex.test(email.value);

        if (!isEmailValid.value) {
            isEmailValid.value = false;
            isEmailValid.message = 'Please enter a valid email address.';
        }

        else {
            isEmailValid.value = true;
            isEmailValid.message = '';
        }

    };

    // Step 1 functions

    const submitStep1 = () => {
        validateEmail();

        if (isEmailValid.value) {
            step.value = 2;
        }
    };



    // Step 2 function
    const validatePassword = () => {
        if(password.value === '') {
            isPasswordValid.value = false;
            isPasswordValid.message = 'Please enter your password.';
            return;
        }
        else if(password.value.length < 4) {
            isPasswordValid.value = false;
            isPasswordValid.message = 'Please enter a valid password (minimum 4 characters).';
        }
        else if(password.value != confirmPassword.value){
            isPasswordValid.value = false;
            isPasswordValid.message = 'Password does not match';
            return;
        }
        else {
            isPasswordValid.value = true;
            isPasswordValid.message = '';
        }
    };
    const validateVerificationCode = () =>{
        if(code.value === ''){
            isCodeValid.value = false;
            isCodeValid.message = 'Please enter your verification code';
            return;
        }
        else if(code.value.length < 4){
            isCodeValid.value = false;
            isCodeValid.message = 'Please enter a valid verification code';
            return;
        }
        else{
            isCodeValid.value = true;
            isCodeValid.message = '';
        }
    }
    const submitStep = () =>{
        validateVerificationCode();
        validatePassword();

        if(isCodeValid.value && isPasswordValid.value){
            const data = {
                email: email.value,
                password: password.value,
                code: code.value
            };
        }
    }
</script>
<style scoped>
.auth-cover .form-form .form-form-wrap form .field-wrapper input{
    padding: 0px 0 0px 35px;
}
</style>
