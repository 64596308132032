<template>
    <div class="container-fluid pb-4">
        <div class="d-flex justify-content-between align-items-center tsukiji-prod-page-header py-4">
            <h3 class="tsukiji-prod-page-title"><b>Campaigns</b></h3>
            <button class="font-weight-bold btn btn-danger rounded-4" @click="navigateToCreateUser('new')">
                Create Campaign
            </button>

        </div>
        <!--List of All States-->
        <div class="d-flex justify-content-end align-items-center tsukiji-prod-page-header py-2">
            <p class="tsukiji-prod-text-title m-0 pt-1">Filter by:</p>
            <button
                :class="['font-weight-bold ms-2 btn rounded-4 btn-outline-danger rounded-4', { 'active': activeId === active }]"
                @click="getCampaignsByStatus('active')">
                Active
            </button>
            <button
                :class="['font-weight-bold btn rounded-4 btn-outline-danger ms-2 rounded-4', { 'active': activeId === progress }]"
                @click="getCampaignsByStatus('progress')">
                Progress
            </button>
            <button
                :class="['font-weight-bold btn rounded-4 btn-outline-danger ms-2 rounded-4', { 'active': activeId === pc }]"
                @click="getCampaignsByStatus('pc')">
                Partially Completed
            </button>
            <button
                :class="['font-weight-bold btn rounded-4 btn-outline-danger ms-2 rounded-4', { 'active': activeId === comp }]"
                @click="getCampaignsByStatus('comp')">
                Completed
            </button>
            <button @click="getCampaignsByStatus('terminated')" variant="outline-primary"
                :class="['font-weight-bold btn rounded-4 btn-outline-danger ms-2 rounded-4', { 'active': activeId === terminated }]">
                Terminated
            </button>
            <p class="tsukiji-prod-clear-all m-0 ms-2" @click="clearAll()">Clear All</p>
        </div>
        <!-- List Templates -->
        <div class="card custom-new-card p-4 mt-3" style="margin-left: 18px;">
            <VueGoodTable mode="remote" v-on:page-change="onPageChange" v-on:per-page-change="onPerPageChange"
                :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: false,
                setCurrentPage: currentPage,
            }" :sort-options="{ enabled: false }" :rows="rows" :columns="columns">
                <template #table-row="{ row, formattedRow, column }">
                    <span v-if="column.field == 'before_name'" style="width: 300px;" class="d-flex">
                        <span class="fa bi bi-pencil-fill" @click="navigateToCreateUser(row.id)"></span>
                        <h6 class="text-truncate"> {{ " " + row.name }}</h6>
                    </span>
                    <span v-if="column.field == 'delete'" style="padding: 20px">
                        <span class="fa bi bi-trash-fill" @click="deleteCampaign(row.id, row.c_status)"></span>
                    </span>
                    <span v-if="column.field == 'c_status'" style="padding: 20px;cursor: pointer;">
                        <a @click="openStats(row)">
                            <strong class="text-decoration-underline text-muted">{{ formattedRow[column.field] + " "
                                }}</strong>
                        </a>
                        <Popover v-if="row.c_status === 'TERMINATED'" icon="bi-info-circle" :content="row.c_status"
                            title="Campaign Status" />
                    </span>
                    <span v-else>
                        {{ formattedRow[column.field] }}
                    </span>
                </template>
            </VueGoodTable>
        </div>
        <!-- Modal -->
        <button type="button" class=" btn rounded-4 btn-primary d-none" data-bs-toggle="modal" ref="showModal"
            data-bs-target="#exampleModal">
            Launch static backdrop modal
        </button>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Vacation Testing Stats</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="closeStats()"></button>
                    </div>
                    <div class="modal-body">
                        <CampaignStats v-if="showStats && campaignStatsDetails"
                            :campaignStatsDetails="campaignStatsDetails" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 <script>
import { VueGoodTable } from 'vue-good-table-next';
import { apiService } from "@/services/apiService/apiService";
import CampaignState from './CampaignState.vue';


export default {
    name: "campaignComponent",
    components: {
        VueGoodTable,
        CampaignState
    },
    campaigns: [],
    data() {
        return {
            isLoading: false,
            showStats: false,
            campaignStatsDetails: null,
            totalRecords: 0,
            activeId: 1,
            currentPage: 1,
            active: 1,
            progress: 1,
            pc: 0,
            comp: 0,
            terminated: 0,
            columns: [
                {
                    label: "S No.",
                    field: "SNo",
                },
                {
                    label: "Name",
                    field: "before_name",
                },
                {
                    label: "Template",
                    field: "temp_name",
                },
                {
                    label: "Start Date",
                    field: "s_date",
                },
                {
                    label: "End Date",
                    field: "e_date",
                },
                {
                    label: "Status",
                    field: "c_status",
                },
                {
                    label: "Delete",
                    field: "delete",
                },
            ],
            rows: [],
            serverParams: {
                page: 1,
                perPage: 10,
            },
        };
    },
    methods: {
        navigateToCreateUser(id) {
            const query = {
                ...this.$route.query,
                active: this.active,
                progress: this.progress,
                pc: this.pc,
                comp: this.comp,
                terminated: this.terminated,
            };
            console.log("Navigation Query:", query);
           this.$router.push({
                name: "addCampaigns",
                params: { id: id },
                query: query,
            });

        },



        getCampaignsByStatus(string) {
            const currentPage = 1;
            this[string] = this[string] === 0 ? 1 : 0;
            const query = {
                ...this.$route.query,
                [string]: this[string],
                page: currentPage,
            };
            this.$router.push({ query });
            this.updateParams({ page: currentPage });
        },
        getCampaigns() {
            this.$store.state.isLoading = true;
            apiService.getCampaigns(
                this.serverParams.page,
                this.active,
                this.progress,
                this.pc,
                this.comp,
                this.terminated
            )
                .then((res) => {
                    this.currentPage = this.serverParams.page;
                    this.$store.state.isLoading = false;
                    this.$store.state.campaigns = res.data.campaign_details;
                    this.totalRecords = res.data.camp_count;
                    let rows = apiService.formateDate(apiService.objectItemIndex(this.$store.state.campaigns, this.serverParams.page - 1), "s_date");
                    rows = apiService.formateDate(rows, "e_date");
                    this.rows = rows;
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        clearAll() {
            this.active = 0;
            this.progress = 0;
            this.pc = 0;
            this.comp = 0;
            this.terminated = 0;
            const query = {
                ...this.$route.query,
                page: 1,
                active: this.active,
                progress: this.progress,
                pc: this.pc,
                comp: this.comp,
                terminated: this.terminated
            };
            this.$router.push({ query });
            this.updateParams({ page: 1 });
        },
        deleteCampaign(id, status) {
            if (status === "TERMINATED") {
                alert("Terminated campaigns cannot be deleted !");
                return
            }
            SweetAlert.Confirmation({ title: 'Are you sure want to delete this campaigns ?' }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.state.isLoading = true;
                    apiService.deleteCampaigns(id).then(() => {
                        this.$store.state.toastMessage = "Deleted successfully!";
                        this.getCampaigns(this.activeId);
                    })
                        .catch((err) => {
                            const error = { ...err };
                            this.$store.state.toastMessage = error?.response?.data || "Something went wrong!";
                        })
                        .finally(() => {
                            this.$store.state.isLoading = false;
                        });
                }
            });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.isLoading = false;
            this.getCampaigns();
        },
        onPageChange(params) {
            console.log("Page Change Params:", params);
            const currentPage = params.currentPage;
            const query = {
                ...this.$route.query,
                page: currentPage
            };
            console.log("New Query:", query);
            this.$router.push({ query: query });
            this.updateParams({ page: currentPage });
        },

        onPerPageChange(params) {
            const currentPerPage = params.currentPerPage;
            this.$router.push({ query: { ...this.$route.query, perPage: currentPerPage } });
        },
        closeStats() {
            this.showStats = false;
            this.campaignStatsDetails = null;
        },
        openStats(info) {
            console.log("it runs", info)
            this.showStats = true;
            this.campaignStatsDetails = info;
            this.$refs.showModal?.click();
        }
    },

    mounted() {

        this.getCampaigns();
    }

}

</script>
<style scoped>
.tsukiji-prod-page-title{
    margin-left: 17px !important;

}
::v-deep table.vgt-table {
    background-color: transparent;
}
::v-deep .vgt-wrap__footer,
::v-deep .vgt-table thead th {
    background: transparent !important;
}
</style>