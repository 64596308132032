<template>
    <div style="margin-left: 20px;">
        <div class=" tsukiji-prod-page-header mb-4 mt-4">
            <h3 class="tsukiji-prod-page-title" id="plans-title">Developer Setting</h3>
        </div>

        <form class="card col-lg-6 custom-new-card p-3 mt-4" @submit.prevent="createApiKey">
            <div class="alert alert-warning px-3 py-2 rounded mb-3">
                <p style="color:#515365;">
                    <i class="bi bi-info-circle"></i>
                    Developer setting is used to generate an API key for your application. You can use this API key to
                    access our
                    API.
                </p>
            </div>
            <div class="input-group mb-3">
                <input type="text" class="form-control" v-model="apiKey" placeholder="Developer Key"
                    aria-label="Developer Key" aria-describedby="basic-addon2" readonly>
                <div class="input-group-append btn-group" role="group" aria-label="Developer Key Access">
                    <button type="submit" class=" btn rounded-4 btn-info mx-2 font-italic" v-if="!apiKey">Generate
                        Api
                        Key</button>
                    <button type="button" @click="copyApiKey()"
                        class=" btn rounded-4 rounded btn-info me-2 ms-3 font-italic" v-if="apiKey">Copy</button>
                    <button type="button" @click="deleteApiKey()"
                        class=" btn rounded-4 rounded btn-danger mx-2 font-italic" v-if="apiKey">Delete</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import SweetAlert from "@/services/sweetAlerts/SweetAlerts";
import { apiService } from "@/services/apiService/apiService";

export default {
    name: "DeveloperSettings",
    data() {
        return {
            apiKey: "",
        }
    },
    created: function () {
        this.getApiKey()
    },
    methods: {
        getApiKey() {
            apiService.getApiKey().then((res) => {
                this.apiKey = res.data.apiKey;
            }).catch(() => {
                this.$store.state.toastMessage = "Failed to get api key!";
            })
        },
        createApiKey() {
            apiService.createApiKey().then((res) => {
                this.apiKey = res.data.apiKey;
                this.$store.state.toastMessage = "Api key generated!";
            }).catch(() => {
                this.$store.state.toastMessage = "Failed to generate api key!";
            })
        },
        async deleteApiKey() {
            const isDeletionConfirmed = await SweetAlert.Confirmation({ title: 'Are you sure want to api key ?' }).then((result) => result.isConfirmed);
            if (!isDeletionConfirmed) return;
            this.$store.state.isLoading = true;
            apiService.deleteApiKey().then((res) => {
                this.apiKey = res.data.apiKey;
                this.$store.state.toastMessage = "Api key deleted!";
                this.$store.state.isLoading = false;
            }).catch(() => {
                this.$store.state.isLoading = false;
                this.$store.state.toastMessage = "Failed to delete api key!";
            })
        },

        copyApiKey() {
            navigator.clipboard.writeText(this.apiKey).then(() => {
                this.$store.state.toastMessage = "Copied to clipboard!";
            }).catch(() => {
                this.$store.state.toastMessage = "Failed to copy to clipboard!";
            })
        },
    }
}
</script>
<style scoped>
.alert-warning {
    color: #fff;
    background-color: #faedc8 !important;
    border-color: #e2a03f;
}
</style>