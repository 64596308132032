<template>
    <div class="mt-3 w-100 custom-new-card p-4 mb-4">
        <h5 class="text-center text-capitalize mb-2 w-100">
            {{ widget.name }}
        </h5>
        <div class="rounded-2 position-relative " style="border:  1px solid #e9edf6">
            <iframe :src="this.nlTemplate" class="w-100" style="height: 226px;"></iframe>

            <button type="button" class=" btn rounded-2 rounded bg-primary-transparent p-0 copy-icon"
                @click="showModal = true" style="padding: 5px 8px!important;"
                v-if="widget.saved && widget.widget_url"><i class="bi bi-clipboard"></i></button>

            <div class="modal fade" id="testModal" tabindex="-1" v-if="widget.saved && widget.widget_url"
                aria-hidden="true" :class="{ 'show d-block': showModal }">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"> Widget Config</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModal"
                                aria-label="Close" @click="showModal = false"></button>
                        </div>
                        <div class="modal-body p-3">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">Height</label>
                                    <input type="number" class="form-control" v-model="embedHtml.height" required
                                        placeholder="Enter Height" />
                                </div>
                                <div class="col">
                                    <label class="form-label">Width</label>
                                    <input type="number" class="form-control" v-model="embedHtml.width" required
                                        placeholder="Enter Width" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary rounded-4" data-bs-dismiss="modal"
                                @click="showModal = false">Close</button>
                            <button type="button" class="btn btn-primary rounded-4"
                                v-if="embedHtml.width && embedHtml.height" @click="copyNLToClipboard()">Copy</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-end mt-3">
            <button class="btn btn-info rounded-4" @click="startEdit = !startEdit"><i class="me-1 bi"
                    :class="{ 'bi-pencil-square': !startEdit, 'bi-x-lg': startEdit }"></i> {{ !startEdit ? 'Edit' :
                'Cancel' }}</button>
            <button class="btn btn-danger rounded-4 ms-2 " v-if="widget.saved"
                @click.prevent="deleteWidget(this.widget?.id)"><i class="me-1 bi bi-trash3-fill"></i>Delete</button>
            <button class="btn btn-success rounded-4 ms-2" v-if="Edited || !widget.saved"
                @click.prevent="handleWidgetData()">Save</button>
        </div>
        <div class=" mt-2" v-if="startEdit">
            <div class="w-100 text-center" style="font-size: 18px; color: #333335;font-weight:600">
                Edit News Letter
            </div>
            <div class="mt-3">
                <form>
                    <div class="row m-0" style="row-gap: 10px;">
                        <div class="main-label">Heading</div>
                        <div class="col-4">
                            <label class="form-label">Text</label>
                            <input type="text" class="form-control" v-model="nlStyle.heading.text"
                                placeholder="Enter Custom Text">
                        </div>
                        <div class="col-4">
                            <label class="form-label">Color</label>
                            <input type="color" v-model="nlStyle.heading.color" style="height: 37px;"
                                class="form-control py-2" placeholder="Enter color">
                        </div>
                        <div class="col-4">
                            <label class="form-label">Font Size</label>
                            <input type="number" class="form-control" placeholder="Enter size"
                                v-model="nlStyle.heading.size">
                        </div>
                        <div class="main-label mt-3">Sub Heading</div>
                        <div class="col-4">
                            <label class="form-label">Text</label>
                            <input type="text" class="form-control" v-model="nlStyle.subHeading.text"
                                placeholder="Enter Custom TExt">
                        </div>
                        <div class="col-4">
                            <label class="form-label">Color</label>
                            <input type="color" style="height: 37px;" v-model="nlStyle.subHeading.color"
                                class="form-control py-2" placeholder="Enter color">
                        </div>
                        <div class="col-4">
                            <label class="form-label">Font Size</label>
                            <input type="number" class="form-control" placeholder="Enter size"
                                v-model="nlStyle.subHeading.size">
                        </div>
                        <div class="col-4 mt-2">
                            <span class="main-label mb-2">Background</span>
                            <div class="mt-2">
                                <label class="form-label">Background Color</label>
                                <input type="color" v-model="nlStyle.background" style="height: 37px;"
                                    class="form-control py-2" placeholder="Enter Background color">
                            </div>
                        </div>
                        <div class="col-4 mt-2">
                            <span class="main-label mb-2">Input</span>
                            <div class="mt-2">
                                <label class="form-label">Text</label>
                                <input type="text" class="form-control" placeholder="Enter Custom Text"
                                    v-model="nlStyle.input.text">
                            </div>
                        </div>
                        <div class="col-4 d-flex flex-column justify-content-end">
                            <label class="form-label">Width</label>
                            <input type="number" class="form-control" placeholder="Enter width"
                                v-model="nlStyle.input.width">
                        </div>
                        <div class="main-label">Button</div>
                        <div class="col-4">
                            <label class="form-label">Text</label>
                            <input type="text" class="form-control" placeholder="Enter Custom Text"
                                v-model="nlStyle.button.text">
                        </div>
                        <div class="col-4">
                            <label class="form-label">Color</label>
                            <input type="color" style="height: 37px;" v-model="nlStyle.button.color"
                                class="form-control py-2" placeholder="Enter color">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import getNlTemplate from "./nlTemplate"
export default {
    name: "EditNl",
    props: ['editWidget', 'index', "saveWidget",'deleteWidget','updateWidget', "widget",'apiKey'],
    data() {
        return {
            nlStyle: JSON.parse(JSON.stringify(this.widget.config)),
            startEdit: false,
            nlTemplate: null,
            Edited: false,
            embedHtml: {
                height: "",
                width: ""
            },
            showModal: false
        }
    },
    methods: {
        setNlTemplate(newConfig) {
            const htmlString = getNlTemplate(newConfig,this.apiKey, this.widget?.leadsGroup);
            const blob = new Blob([htmlString], { type: 'text/html' });
            this.nlTemplate = URL.createObjectURL(blob);
        },
        async copyNLToClipboard() {
            const embedText = `<embed src="${this.widget.widget_url}" width="${this.embedHtml.width}" height="${this.embedHtml.height}">`
            try {
                await navigator.clipboard.writeText(embedText);
                this.$refs.closeModal.click();
                this.embedHtml = {
                    height: "",
                    width: ""
                }
                this.$store.state.toastMessage = "Widget Copied Successfully!";
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
        saveNewsLetterData() {
            const obj = {
                name: this.widget.name,
                tag_name: "NewsLetter",
                html_content: getNlTemplate(this.nlStyle,this.apiKey,this.widget?.leadsGroup),
                json_content: this.nlStyle
            }
            if(this.widget.saved) this.updateWidget(obj, this.widget.id);
            else this.saveWidget(obj,this.index);
        },
        handleWidgetData(){
            this.Edited = false;
            this.saveNewsLetterData();
        },
    },
    mounted() {
        this.setNlTemplate(this.widget.config);
    },
    watch: {
        nlStyle: {
            deep: true,
            handler() {
                this.setNlTemplate(this.nlStyle);
                this.editWidget(this.nlStyle, this.index);
                this.Edited = true;
            }
        },
    }

}
</script>

<style>
.w-48 {
    width: 48% !important;
}

.main-label {
    text-decoration: underline;
    font-size: 17px;
    font-weight: 700;
    color: #333335 !important;
    /* padding: 3px 0px; */
}

.copy-icon {
    position: absolute;
    top: 4px;
    right: 7px;
}
</style>