<template>
    <div class="container-fluid px-4 h-100 pb-4">
        <div class="tsukiji-prod-page-header">
            <h3 class="tsukiji-prod-page-title mt-4" id="plans-title">Billing</h3>
        </div>

        <div class="tsukiji-prod-billing mt-4">

            <div v-if="currentPlanInfo" class="card custom-new-card p-3"> <!--Current Plan-->
                <div class="pb-2 border-bottom">
                    <p class="card-title my-0 ms-1 new-card-title">current plan</p>
                </div>
                <div class=" mt-4 d-flex justify-content-between">
                    <div>
                        <div class="mb-1">
                            <span class="fw-semibold">Start Date</span>:
                            <span class="text-muted ms-1">{{ currentPlanInfo.currentPeriodStart ? new
                                Date(currentPlanInfo.currentPeriodStart * 1000).toISOString().substring(0, 10) : ''
                                }}</span>
                        </div>
                        <div class="mb-1">
                            <span class="fw-semibold">Renewal Date</span>:
                            <span class="text-muted ms-1">{{ currentPlanInfo.currentPeriodEnd ? new
                                Date(currentPlanInfo.currentPeriodEnd * 1000).toISOString().substring(0, 10) : ''
                                }}</span>
                        </div>
                        <div class="mb-1">
                            <span class="fw-semibold">Email Offer</span>:
                            <span class="text-muted ms-1">{{ currentPlanInfo.emails_offer }}</span>
                        </div>
                        <div class="mb-1">
                            <span class="fw-semibold">Price</span>:
                            <span class="text-muted ms-1">{{ currentPlanInfo.price }}</span>
                        </div>
                        <div class="mb-1">
                            <span class="fw-semibold">Status</span>:
                            <span class="text-muted ms-1">{{ currentPlanInfo.status }}</span>
                        </div>
                    </div>

                    <div class="d-flex  justify-content-center flex-column me-3">
                        <Plans :showModal="showModal" :currentPlanInfo="currentPlanInfo" @handleClose="handleClose"
                            @subscribePlan="subscribePlan($event)" />
                        <button class=" btn rounded-4 btn-outline-danger mt-3" @click="cancelPlan">Cancel Plan</button>
                    </div>
                </div>
            </div>

            <div v-if="!currentPlanInfo" class="card mt-5 custom-new-card p-3"> <!--Subscribe Plan-->
                <div class="pb-2 border-bottom">
                    <p class="ms-2 new-card-title m-1 fw-bold">Subscribe Plan</p>
                </div>
                <div class="mt-4 d-flex justify-content-center" v-if="showPlan">
                    <Plans :showModal="showModal" :currentPlanInfo="currentPlanInfo" @handleClose="handleClose"
                        @subscribePlan="subscribePlan($event)" />
                </div>
            </div>

            <div class=" card mt-5 custom-new-card p-3"> <!--Payment Method-->
                <div class="pb-2 border-bottom">
                    <p class="ms-2 new-card-title m-1 fw-bold">Payment Method</p>

                </div>
                <div class="mt-2">
                    <div v-if="cardList && cardList.length" class="d-flex flex-column" style="max-width: 440px;">
                        <div v-for="(item, i) in cardList" :key="i">
                            <div class="d-flex justify-content-between mt-2">
                                <div class="d-flex align-items-center">
                                    <img style="height: 40px;width: 40px;" :src="getImageUrl(item)">
                                    <div class="ms-3">XXXX{{ item.last4Digits }}</div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="m-0">Expires {{ item.expMonth }}/{{ item.expYear }} </p>
                                    <div class="d-flex ms-4">
                                        <div @click="editCard(item)" class="tsukiji-prod-pointer">
                                            <i class="bi bi-pencil-fill text-primary" aria-hidden="true"></i>
                                        </div>
                                        <div class=" ms-2 tsukiji-prod-pointer" @click="removeCard(item)">
                                            <i class="bi bi-trash-fill text-muted" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-link to="/billing/add-card/new">
                        <p class="text-muted tsukiji-prod-pointer mt-3 mb-2" id="add-details">
                            <i class="bi bi-plus-lg me-1"></i>Add Payment method
                        </p>
                    </router-link>
                </div>
            </div>

            <div class="mt-5  custom-new-card p-3" v-if="BillsData && BillsData.length"> <!-- Billing History -->
                <div class="pb-2 border-bottom">
                    <p class="new-card-title ms-1 m-0 ">Billing History</p>
                </div>

                <div class="mt-4" style="max-width: 440px;">
                    <div class="d-flex w-100" v-for="(item, index) in getFilteredBillingDetails" :key="index">
                        <div class="d-flex w-100 justify-content-between" v-if="item.payment_date != null">
                            <p class="m-0">{{ formattedDate(item.payment_date) }}</p>
                            <p class="m-0">${{ item.amount }}</p>
                            <p class="m-0 text-success">{{ item.status }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class=" btn rounded-4 btn-danger d-none" ref="editCard" data-bs-toggle="modal"
            data-bs-target="#changeCard">
            edit card
        </button>
        <div class="modal fade" id="changeCard" tabindex="-1" aria-labelledby="changeCardLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Your Card</h5>
                        <button type="button" class="btn-close" ref="cardClose" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row my-1">
                                <div class="col-sm-3"><label for="month">Month:</label></div>
                                <div class="col-sm-9">
                                    <select class="form-select" id="month" v-model="updateForm.expMonth">
                                        <option v-for="(month, index) in months" :key="index" :value="month.value">{{
                                            month.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row my-1">
                                <div class="col-sm-3"><label for="year">Year:</label></div>
                                <div class="col-sm-9">
                                    <select class="form-select" id="year" v-model="updateForm.expYear">
                                        <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row my-1">
                                <div class="col-sm-3"><label for="pin">Pin Code:</label></div>
                                <div class="col-sm-9">
                                    <input class="form-control" id="pin" type="text" maxlength="5"
                                        v-model="updateForm.zip"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                            <button type="submit" class=" btn rounded-4 btn-danger mt-4 float-end"
                                @click="updateDetails">Update
                                Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PlansComponent from './Plans.vue'
import { apiService } from '@/services/apiService/apiService';
import SweetAlert from "@/services/sweetAlerts/SweetAlerts"
export default {
    name: 'Billing-vue',
    components: {
        Plans: PlansComponent,
    },
    data() {
        return {
            popper: null,
            cardList: this.$store.state.cardList || [],
            showModal: false,
            showEditModal: false,
            months: [],
            years: [],
            updateForm: {
                expMonth: null,
                expYear: null,
                zip: null,
                stripeTokenId: null,
                currentUser: null
            },
            currentPlanInfo: this.$store.state.currentPlanInfo || {},
            BillsData: [],
            showPlan: false
        }
    },
    methods: {
        formattedDate(value) {
            const date = new Date(value);
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
            return formattedDate
        },
        addUpdateCard() {
            this.$router.push({
                name: "payment-profile-card",
                params: { cardId: "new" },
            });
        },

        getStripeCards() {
            this.$store.state.isLoading = true;
            apiService.getStripeCards().then(res => {
                this.$store.state.isLoading = false;
                this.$store.state.cardList = res.data;
                this.cardList = this.$store.state.cardList;
            }).catch(error => {
                const errorMessage = { ...error };
                this.$store.state.isLoading = false;
                // this.$store.state.toastMessage = errorMessage.response.data.message || errorMessage.response.data;
            });
        },


        removeCard(item) {
            SweetAlert.Confirmation({ title: 'Are you sure want to delete this card ?' }).then((result) => {
                if (result.isConfirmed) {
                    const body = {
                        methodId: item.id,
                    }
                    this.$store.state.isLoading = true;
                    apiService.deleteStripeCard(body).then(() => {
                        this.getStripeCards();
                    }).catch(error => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage = errorMessage.response.data.message || errorMessage.response.data;
                    }).finally(() => {
                        this.$store.state.toastMessage = "";
                        this.$store.state.isLoading = false;
                    });
                }
            });
        },


        editCard(item) {
            SweetAlert.Confirmation({ title: 'Are you sure want to edit this card?' }).then((result) => {
                if (result.isConfirmed) {
                    this.updateForm = {
                        expMonth: item.expMonth,
                        expYear: item.expYear,
                        zip: null,
                        stripeTokenId: item.cardId
                    }
                    this.$refs.editCard.click();
                }
            });
        },

        getImageUrl(item) {
            return require('@/assets/creditCard/' + item.brand + '.svg')
        },

        openPlans() {
            this.$store.state.isShowModal = true;
            this.showModal = true;
        },
        handleClose() {
            this.showModal = false;
        },

        handleEditCardClose() {
            this.showEditModal = false;
        },

        updateDetails() {
            apiService.updateStripeCard(this.updateForm).then(() => {
                this.getStripeCards();
                this.handleEditCardClose();
                this.$refs.cardClose.click()
            }, error => {
                const errorMessage = { ...error };
                this.$store.state.toastMessage = errorMessage.response.data.message || errorMessage.response.data;
                this.$store.state.isLoading = false;
            }).finally(() => {
                this.$store.state.toastMessage = null;

            });
        },

        subscribePlan(plan) {
            if (!this.cardList || !this.cardList.length) {
                this.$store.state.toastMessage = "";
                setTimeout(() => {
                    this.$store.state.toastMessage = "Please add at least one card in order to buy plan";
                }, 10);
                this.addUpdateCard();
                return false;
            }
            const body = {
                subscriptionPlanCode: plan.planCode,
                methodId: 31,
            }
            this.$store.state.isLoading = true;
            apiService.subscribePlan(body).then(() => {
                this.$store.state.toastMessage = "Plan subscribed successfully";
                this.getCurrentPlan(true);
                this.showModal = false;
            }).catch(error => {
                const errorMessage = { ...error };
                this.$store.state.toastMessage = errorMessage.response.data.message || errorMessage.response.data;
            }).finally(() => {
                this.$store.state.isLoading = false;
                this.$store.state.toastMessage = null;
            });
        },


        getCurrentPlan(onSubscribe = false) {
            console.log("getCurrentPlan method called");
            if (this.showPlan) {
                this.showPlan = false;
            }
            this.$store.state.isLoading = true;
            apiService.getCurrentPlan().then(res => {
                console.log("Response:", res);
                if (res.data && res.data.stripeSubscriptionId) {
                    this.$store.state.currentPlanInfo = res.data;
                    this.currentPlanInfo = this.$store.state.currentPlanInfo;
                } else {
                    console.error("Error: Response data or stripeSubscriptionId is undefined.");
                    this.$store.state.currentPlanInfo = null;
                    this.currentPlanInfo = null;
                }
                if (!onSubscribe || this.$store.state.currentPlanInfo) return;
                this.getCurrentPlan(true);
            }).catch(error => {
                console.error("Error fetching current plan:", error);
                const errorMessage = { ...error };
                this.$store.state.toastMessage = errorMessage.response?.data?.message || errorMessage.response?.data;
            }).finally(() => {
                console.log("getCurrentPlan method completed");
                this.$store.state.isLoading = false;
                this.showPlan = true;
            });
        },


        cancelPlan() {
            SweetAlert.Confirmation({ title: 'Are you sure you want to Cancel the plan ? ' }).then((result) => {
                if (result.isConfirmed) {
                    const body = {
                        subscriptionPlanCode: plan.planCode,
                        methodId: 31,
                    }
                    this.$store.state.isLoading = true;
                    apiService.cancelPlan(body).then(() => {
                        this.getCurrentPlan();
                        this.$store.state.toastMessage = "Plan cancelled successfully";
                    }).catch(error => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage = errorMessage.response.data.message || errorMessage.response.data;
                    }).finally(() => {
                        this.$store.state.isLoading = false;
                    });
                }
            });
        },
    },
    mounted() {
        this.getCurrentPlan();
        this.getStripeCards();
    },
    computed: {
        getFilteredBillingDetails() {
            return this.BillsData.filter(item => item.type === 'order')
        }
    }
}
</script>
<style scoped>
span.spacer {
    flex: 1 1 auto;
}

.card-body {
    padding: 1rem;
}

.card_number {
    font-size: 18px;
    font-weight: 600;
}

.card_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
}

.thru {
    font-size: 10px !important;
    line-height: 1 !important;
}

.credit-card {
    margin: 0 10px;
}

.delete_card {
    display: none;
}

.edit_card {
    display: none;
}

.credit-card:hover .delete_card {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    color: #dd6b10;
    cursor: pointer;
}

.credit-card:hover .edit_card {
    display: block;
    position: absolute;
    right: 20px;
    top: 5px;
    color: #022e63;
    cursor: pointer;
}


img {
    -webkit-user-drag: none;
}

.panel-heading {
    text-transform: uppercase;
}

.panel-heading h5 {
    font-weight: 600;
}

li {
    list-style-type: disc;
    text-align: left;
    font-size: 12px;
    line-height: 1.5;
}

.tsukiji_card {
    box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 10%),
        0 0.9375rem 1.40625rem rgb(90 97 105 / 10%),
        0 0.25rem 0.53125rem rgb(90 97 105 / 12%),
        0 0.125rem 0.1875rem rgb(90 97 105 / 10%);
    border-radius: 15px;
}

.top-border {
    height: 15px;
    background: #00a1ff;
    width: calc(100% + 30px);
    margin: 0 -15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.best_plan {
    margin-top: -15px;
}

.plan-block:first-child .top-border {
    border-top-right-radius: 0px;
}

.plan-block:last-child .top-border {
    border-top-left-radius: 0px;
}

.trash-main-block {
    height: calc(100vh - 210px);
}

.small-font {
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: 1.8;
}

.popover-header {
    padding: 14px 18px;
    font-size: 15px;
    color: #3D5170;
    line-height: 14px;
    background-color: #f5f5f6;
    border-bottom: 1px solid #e7e9ea;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
}

.tsukiji-prod-billing {
    max-width: 800px;
}

.tsukiji-prod-pointer {
    cursor: pointer;
}

.custom-new-card {
    font-size: larger;
}
</style>
