<template>
    <div class="mt-3 w-100 custom-new-card p-4 mb-4">
        <h5 class="text-center text-capitalize mb-2 w-100">
            {{ widget.name }}
        </h5>
        <div class="rounded-2 position-relative" style="border:  1px solid #e9edf6">
            <iframe :src="this.pbTemplate" class="w-100" style="height: 223px;"></iframe>
            <button type="button" class=" btn rounded-2 rounded bg-primary-transparent p-0 copy-icon"
                @click="showModal = true" style="padding: 5px 8px!important;"
                v-if="widget.saved && widget.widget_url"><i class="bi bi-clipboard"></i></button>

            <div class="modal fade" id="testModal" tabindex="-1" v-if="widget.saved && widget.widget_url"
                aria-hidden="true" :class="{ 'show d-block': showModal }">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"> Widget Config</h5>
                            <button type="button" class="btn-close" @click="showModal = false" data-bs-dismiss="modal"
                                ref="closeModal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-3">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">Height</label>
                                    <input type="number" class="form-control" v-model="PbEmbedHtml.height" required
                                        placeholder="Enter Height" />
                                </div>
                                <div class="col">
                                    <label class="form-label">Width</label>
                                    <input type="number" class="form-control" v-model="PbEmbedHtml.width" required
                                        placeholder="Enter Width" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary rounded-4" @click="showModal = false"
                                data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary rounded-4"
                                v-if="PbEmbedHtml.width && PbEmbedHtml.height"
                                @click="copyPBToClipboard()">Copy</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-end mt-3">
            <button class="btn btn-info rounded-4" @click="startEdit = !startEdit"><i class="me-1 bi"
                    :class="{ 'bi-pencil-square': !startEdit, 'bi-x-lg': startEdit }"></i> {{ !startEdit ? 'Edit' :
                'Cancel' }}</button>
            <button class="btn btn-danger rounded-4 ms-2 " v-if="widget.saved"
                @click.prevent="deleteWidget(this.widget?.id)"><i class="me-1 bi bi-trash3-fill"></i>Delete</button>
            <button class="btn btn-success rounded-4 ms-2" v-if="Edited || !widget.saved"
                @click.prevent="handleWidgetData()">Save</button>
        </div>
        <div class=" mt-2" v-if="startEdit">
            <div class="w-100 text-center" style="font-size: 18px; color: #333335;font-weight:600">
                Edit PromoCode Banner
            </div>
            <div class="mt-3">
                <form>
                    <div class="row m-0" style="row-gap: 10px;">
                        <div class="col-4">
                            <span class="main-label mb-2">Main Component</span>
                            <div class="mt-2">
                                <label class="form-label">Background Color</label>
                                <input type="color" v-model="pbStyle.background" style="height: 37px;"
                                    class="form-control py-2" placeholder="Enter Background color">
                            </div>
                        </div>
                        <div class="col-4 d-flex flex-column justify-content-end">
                            <label class="form-label">Direction</label>
                            <div class="dropdown">
                                <span class="form-control " role="button" id="Direction-menu-link"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ pbStyle.direction }}
                                </span>
                                <ul class="dropdown-menu" aria-labelledby="Direction-menu-link">
                                    <li><a class="dropdown-item" @click="pbStyle.direction = 'start'">Start</a></li>
                                    <li><a class="dropdown-item" @click="pbStyle.direction = 'center'">Center</a></li>
                                    <li><a class="dropdown-item" @click="pbStyle.direction = 'end'">End</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-4 d-flex flex-column justify-content-end">
                            <label class="form-label">Link</label>
                            <input type="text" class="form-control" placeholder="Enter link" v-model="pbStyle.link">
                        </div>
                        <div class="col-4 mt-2">
                            <span class="main-label mb-2">First Text</span>
                            <div class="mt-2">
                                <label class="form-label">Text</label>
                                <input type="text" class="form-control" placeholder="Enter Custom Text"
                                    v-model="pbStyle.first.text">
                            </div>
                        </div>
                        <div class="col-4 d-flex  flex-column justify-content-end">
                            <label class="form-label">Color</label>
                            <input type="color" v-model="pbStyle.first.color" style="height: 37px;"
                                class="form-control py-2" placeholder="Enter color">
                        </div>
                        <div class="col-4 d-flex flex-column justify-content-end">
                            <label class="form-label">Font Size</label>
                            <input type="number" class="form-control" placeholder="Enter size"
                                v-model="pbStyle.first.size">
                        </div>
                        <div class="col-4 mt-2">
                            <span class="main-label mb-2">Second Text</span>
                            <div class="mt-2">
                                <label class="form-label">Text</label>
                                <input type="text" class="form-control" placeholder="Enter Custom Text"
                                    v-model="pbStyle.second.text">
                            </div>
                        </div>
                        <div class="col-4 d-flex  flex-column justify-content-end">
                            <label class="form-label">Color</label>
                            <input type="color" v-model="pbStyle.second.color" style="height: 37px;"
                                class="form-control py-2" placeholder="Enter color">
                        </div>
                        <div class="col-4 d-flex flex-column justify-content-end">
                            <label class="form-label">Font Size</label>
                            <input type="number" class="form-control" placeholder="Enter size"
                                v-model="pbStyle.second.size">
                        </div>
                        <div class="col-4 mt-2">
                            <span class="main-label mb-2">Third Text</span>
                            <div class="mt-2">
                                <label class="form-label">Text</label>
                                <input type="text" class="form-control" placeholder="Enter Custom Text"
                                    v-model="pbStyle.third.text">
                            </div>
                        </div>
                        <div class="col-4 d-flex  flex-column justify-content-end">
                            <label class="form-label">Color</label>
                            <input type="color" v-model="pbStyle.third.color" style="height: 37px;"
                                class="form-control py-2" placeholder="Enter color">
                        </div>
                        <div class="col-4 d-flex flex-column justify-content-end">
                            <label class="form-label">Font Size</label>
                            <input type="number" class="form-control" placeholder="Enter size"
                                v-model="pbStyle.third.size">
                        </div>
                        <div class="col-4 mt-2">
                            <span class="main-label mb-2">Input</span>
                            <div class="mt-2">
                                <label class="form-label">Text</label>
                                <input type="text" class="form-control" placeholder="Enter Custom Text"
                                    v-model="pbStyle.input.text">
                            </div>
                        </div>
                        <div class="col-4 d-flex flex-column justify-content-end">
                            <label class="form-label">Width</label>
                            <input type="number" class="form-control" placeholder="Enter width"
                                v-model="pbStyle.input.width">
                        </div>
                        <div class="main-label">Button</div>
                        <div class="col-4">
                            <label class="form-label">Text</label>
                            <input type="text" class="form-control" placeholder="Enter Custom Text"
                                v-model="pbStyle.button.text">
                        </div>
                        <div class="col-4">
                            <label class="form-label">Color</label>
                            <input type="color" style="height: 37px;" v-model="pbStyle.button.color"
                                class="form-control py-2" placeholder="Enter color">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import getPbTemplate from './pbTemplate';
export default {
    name: "EditPb",
    props: ['index', 'editWidget','deleteWidget','updateWidget', 'saveWidget', 'widget','apiKey'],
    data() {
        return {
            pbStyle: JSON.parse(JSON.stringify(this.widget.config)),
            startEdit: false,
            testColor: '#ffff',
            pbTemplate: null,
            Edited: false,
            PbEmbedHtml: {
                height: "",
                width: ""
            },
            showModal: false
        }
    },
    methods: {
        setPbTemplate(newConfig) {
            const htmlString = getPbTemplate(newConfig,this.apiKey, this.widget?.leadsGroup);
            const blob = new Blob([htmlString], { type: 'text/html' });
            this.pbTemplate = URL.createObjectURL(blob);
        },
        async copyPBToClipboard() {
            const embedText = `<embed src="${this.widget.widget_url}" width="${this.PbEmbedHtml.width}" height="${this.PbEmbedHtml.height}">`
            try {
                await navigator.clipboard.writeText(embedText);
                this.$refs.closeModal.click();
                this.PbEmbedHtml = {
                    height: "",
                    width: ""
                }
                this.$store.state.toastMessage = "Widget Copied Successfully!";
            } catch (err) {
                console.error("Error copying to clipboard:", err);
            }
            this.PbEmbedHtml = {
                height: "",
                width: ""
            }
        },
        handleWidgetData(){
            this.Edited = false;
            this.savePromotionsData();
        },
        savePromotionsData() {
            const obj = {
                name: this.widget.name,
                tag_name: "PromotionBanner",
                html_content: getPbTemplate(this.pbStyle,this.apiKey, this.widget?.leadsGroup),
                json_content: this.pbStyle
            }
            if(this.widget.saved) this.updateWidget(obj, this.widget.id);
            else this.saveWidget(obj,this.index);
        }
    },
    mounted() {
        console.log("widget ==>",this.widget);
        this.setPbTemplate(this.widget.config);
    },
    watch: {
        pbStyle: {
            deep: true,
            handler() {
                this.setPbTemplate(this.pbStyle);
                this.editWidget(this.pbStyle, this.index)
                this.Edited = true;
            }
        }
    }
}
</script>

<style>
.w-48 {
    width: 48% !important;
}

.main-label {
    text-decoration: underline;
    font-size: 17px;
    font-weight: 700;
    color: #333335 !important;
    /* padding: 3px 0px; */
}

.copy-icon {
    position: absolute;
    top: 4px;
    right: 7px;
}
</style>
