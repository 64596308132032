<template>
    <div class="container-fluid main-content-container px-4 pb-4">
        <div class="row no-gutters page-header py-4">
            <h3 class="page-title p-0">
                <i class="bi bi-arrow-left" @click="navigateTo" aria-hidden="true"></i>&nbsp;
                {{ leadsGroupsId ? "Update" : "Add" }} Leads Group
            </h3>
            <span class="spacer"></span>
            <button v-if="!leadsGroupsId" class=" btn rounded-4 btn-danger" @click="addLeadsGroup">
                <i class="bi bi-plus" aria-hidden="true"></i>
            </button>
        </div>
        <div class="card custom-new-card p-3">
            <div class="row no-gutters page-header" v-if="leadsGroups.length">
                <div class="card-group deck">
                    <div v-for="(item, index) in leadsGroups" :key="index" class=" tsukiji-prod-leads_group_card">
                        <div class="card-header">
                            <h6 class="mb-0 groups-card-body">
                                <span class="spacer"></span>
                                <i v-if="!leadsGroupsId" class="bi bi-trash tsukiji-prod-icons_info"
                                    @click="onDelete(index)" aria-hidden="true"></i>
                            </h6>
                        </div>
                        <form @submit="onSubmit" @reset="onReset">
                            <div class="mb-3">
                                <label for="group-name" class="form-label">Group Name</label>
                                <input type="text" class="form-control" id="group-name" v-model="item.groupName"
                                    required placeholder="Enter group name">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row no-gutters page-header py-4" v-else>
                <h5 class="w-100 text-center">No Leads group found</h5>
            </div>
            <div class="row no-gutters page-header d-flex ms-1" v-if="leadsGroups.length">
                <span class="spacer"></span>
                <button class=" btn rounded-4 btn-primary rounded-4" @click="onSubmit">{{ leadsGroupsId ? "Update" :
                    "Submit" }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute} from 'vue-router'
import {apiService} from '@/services/apiService/apiService';
import utilsService from '@/services/utils/utils.service';
export default {
    data() {
        return {
            leadsGroups: [],
            leadsGroupsId: null,

        }
    },
    methods: {
        addLeadsGroup() {
            const data = {
                groupName: '',
            }
            this.leadsGroups.push(data);
        },
        onSubmit(evt) {
            evt.preventDefault();
            this.$store.state.isLoading = true;

            if (this.leadsGroupsId) {
                console.log("it runs");
                const checkName = utilsService.checkLeadsName(this.leadsGroups[0].groupName, this.$store.state.leadsGroups);
                if (!checkName) {
                    this.$store.state.toastMessage = "Group Name Can't Be same";
                    this.$store.state.isLoading = false;
                    return false
                }
                const updatedData = {
                    id: this.leadsGroupsId,
                    name: this.leadsGroups[0].groupName,
                    "status": "ACTIVE"
                }
                apiService.updateCustomerGroups(updatedData).then(() => {
                    this.$store.state.isLoading = false;
                    this.$store.state.toastMessage = "Updated successfully!";
                    this.navigateTo();
                }).catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                }).finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                })
            } else {
                const validLeadsGroup = this.leadsGroups.filter((group) => group.groupName);
                apiService.createCustomerGroups(validLeadsGroup).then(() => {
                    this.$store.state.isLoading = false;
                    this.$store.state.toastMessage = "Created successfully!";
                    this.navigateTo();
                }).catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                }).finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                })
            }
        },
        onReset(evt) {
            evt.preventDefault()
        },
        onDelete(event) {
            this.leadsGroups.splice(event, 1);
        },
        navigateTo() {
            let currentPage = 1;

             const route = this.$route;
            if (route.query && route.query.leadsGroupPage) {
                currentPage = Number(route.query.leadsGroupPage);
            }
            this.$router.push({ name: "Leads", params: { id: this.leadsGroupsId }, query: { leadsGroupPage: currentPage } });
        },
    },
    created() {
        const route = useRoute();
        console.log("const route = useRoute();", route.query)
        if (this.$route.query && this.$route.query.page) {
            this.currentPage = Number(this.$route.query.page);
        } else {
            this.currentPage = 1;
        }

        const params = this.$route.params;
        if (params.id && params.id !== 'new') {
            const data = {
                groupName: params.name,
            }
            this.leadsGroupsId = params.id;
            this.leadsGroups.push(data);
        }
    }

}
</script>

<style scoped>
span.spacer {
    flex: 1 1 auto;
}

.groups-card-body {
    display: flex;
    position: absolute;
    bottom: -22px;
    right: 6px;
}

.card-header {
    padding: 0;
}

.tsukiji-prod-icons_info {
    color: red;
    cursor: pointer;
}

.tsukiji-prod-leads_group_card {
    min-width: 22%;
    border-radius: 0;
}

.card-deck {
    width: 100%;
    margin: 0;
}
.ms-1 {

    width: 100px;
}
</style>
