import { createStore } from 'vuex';
import i18n from '../i18n';
import { apiService } from '@/services/apiService/apiService';

export default new createStore({
    state: {
        layoutConfig:{
            title:"Email Editor",
            logo:"@/assets/images/logo.svg",
        },
        showProfile: false,
        session: null,
        JwtToken: null,
        isLoading: false,
        isAuthenticated:false,
        user: null,
        toastMessage: null,
        currentPlanInfo: null,
        balance:"00",
        // Default Templates States *****
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' },
        ],
    },
    mutations: {
        setLoading(state, payload) {
            state.isLoading = payload;
        },
        setJwtToken(state, token) {
            state.JwtToken = token;
        },
        setIsAuthenticated(state, payload) {
            state.isAuthenticated = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setLayout(state, payload) {
            state.layout = payload;
            if(payload == 'auth'){
                document.querySelector('body').classList.add('auth');
            }
            else {
                document.querySelector('body').classList.remove('auth');
            }
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        jwtToken(state) {
            return state.JwtToken;
        },
    },
    actions: {
        async fetchJwtToken({ commit }) {
            const token = await apiService.getJwtToken();
            commit("setJwtToken", token);
        },
    },
    modules: {},
});
