import dashboard from '../views/dashboard/dashboard.vue';
import Template from '../views/Templates/Template.vue';
import Campaigns from '../views/Campaigns/Campaigns.vue'
import Leads from '../views/Leads/Leads.vue'
import Login from '@/views/auth/login.vue';
import Register from '@/views/auth/register.vue';
import PassRecovery from '@/views/auth/pass_recovery.vue';
import History from '@/views/History/History.vue';
import Identity from '@/views/Identity/Identity.vue';
import Integration from '../views/app&Integrate/Integration.vue';
import Trash from '../views/Trash/Trash.vue';
import Support from '@/views/Support/Support.vue';
import Launch from '../views/Launch/Launch.vue'
import Widgets from '../views/Widgets/Widgets.vue'
import AddCampaigns from '@/views/Campaigns/AddCampaigns.vue';
import Transaction from '@/views/Transaction/Transaction.vue';
import DeveloperSettings from '@/views/Developer/Developer-Setting.vue'
import UserProfile from '@/views/Profile/UserProfile.vue';
import Billing from '@/views/payment/Billing.vue'
import AddCard from "@/views/payment/AddCard.vue";
import TemplateEditor from '@/views/Templates/TemplateEditor.vue'
import AllLeads from '@/views/Leads/AllLeads.vue';
import EditLeads from '@/views/Leads/EditLeads.vue';
import EditLeadsGroup from '@/views/Leads/EditLeadsGroup.vue';
import LeadsInfo from '@/views/Leads/LeadsInfo.vue';

const  routerConfig = [

    {
        name:"PassRecovery",
        icon:"",
        path: '/pass-recovery',
        component:PassRecovery,
        requiredAuth:false,
        sidebar:{
            visible:false
        },
        children:null
    },
    {
        name:"Register",
        icon:"",
        path: '/register',
        component:Register,
        requiredAuth:false,
        sidebar:{
            visible:false
        },
        children:null
    },
    {
        name:"Login",
        icon:"",
        path: '/login',
        component:Login,
        requiredAuth:false,
        sidebar:{
            visible:false
        },
        children:null
    },
    {
        name:"Dashboard",
        icon:"bi-house",
        path: '/',
        component:dashboard,
        requiredAuth:true,
        sidebar:{
            visible:true
        },
        children:null
    },

    {
        name: "Template",
        icon: "bi bi-layout-text-sidebar-reverse",
        path: "/templates",
        component: Template,
        requiredAuth: true,
        sidebar: {
            visible: true
        },
        children: null
    },
    {
        name: "Template-Editor",
        path: "/templates/editor/:templateId",
        component: TemplateEditor,
        requiredAuth: true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "createTemplate",
        path: "/templates",
        component: Template,
        requiredAuth: true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "Campaigns",
        icon: "bi bi-megaphone",
        path: '/campaigns',
        component: Campaigns,
        requiredAuth: true,
        sidebar: {
            visible: true
        },
        children: [
            {
                name: "addCampaigns",
                path: '/:id',
                requiredAuth: true,
                component: AddCampaigns,
                icon: "",
                sidebar: {
                    visible: false
                },
                children: null
            },
        ]
    },
    {
        name: "Leads",
        icon: "bi bi-award",
        path: '/leads',
        component: Leads,
        requiredAuth: true,
        sidebar: {
            visible: true
        },
        children: null
    },
    {
        name: "viewAll",
        path: "/leads/viewAll",
        component: AllLeads,
        requiredAuth: true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "createLeads",
        path: "/leads/create-leads",
        component: EditLeads,
        requiredAuth: true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "addUpdateLeads",
        path: "/leads/:group/:groupName/:id",
        component: EditLeads,
        requiredAuth: true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "manageGroup",
        path: "/leads/manage-group/:id",
        component: EditLeadsGroup,
        requiredAuth:true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "leads-group",
        path: "/leads/:group/:groupName",
        component: LeadsInfo,
        requiredAuth: true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "Launch",
        icon: "bi bi-rocket",
        path: '/launch',
        component: Launch,
        requiredAuth: true,
        sidebar: {
            visible: true
        },
        children: null
    },
    {
        name: "History",
        icon: "bi bi-arrow-clockwise",
        path: '/history',
        component: History,
        requiredAuth: true,
        sidebar: {
            visible: true
        },
        children: null
    },
    {
        name: "Identity",
        icon: "bi bi-person-lines-fill",
        path: '/Identity',
        component: Identity,
        requiredAuth: true,
        sidebar: {
            visible: true
        },
        children: null
    },
    {
        name: "App & Integration",
        icon: "bi bi-x-diamond-fill",
        path: '/integrate',
        component: Integration,
        requiredAuth: true,
        sidebar: {
            visible: false
        },
        children: null
    },
    {
        name: "Widgets",
        icon: "bi bi-grid",
        path: '/widgets',
        component: Widgets,
        requiredAuth: true,
        sidebar: {
            visible: false,
        },
        children: null
    },
    {
        name: "Trash",
        icon: "bi bi-trash",
        path: '/trash',
        component: Trash,
        requiredAuth: true,
        sidebar: {
            visible: false
        },
        children: null
    },
    {
        name: "Support",
        icon: "bi bi-question-circle",
        path: '/support',
        component: Support,
        requiredAuth: true,
        sidebar: {
            visible: false
        },
        children: null
    },
    {
        name: "transaction-history",
        path: "/transaction-history",
        requiredAuth: true,
        component: Transaction,
        icon: "",
        sidebar: {
            visible: false
        },
        children: null
    },
    {
        name: "DeveloperSettings",
        path: "/developer-setting",
        requiredAuth: true,
        component: DeveloperSettings,
        icon: "",
        sidebar: {
            visible: false
        },
        children: null
    },
    {
        name: "UserProfileComponent",
        path: "/user-profile",
        requiredAuth: true,
        component: UserProfile,
        icon: "",
        sidebar: {
            visible: false
        },
        children: null
    },
    {
        name: "billing",
        path: "/billing",
        requiredAuth: true,
        component: Billing,
        icon: "",
        sidebar: {
            visible: false
        },
        children: null
    },
    {
        name: "payment-profile-card",
        path: "/billing/add-card/:cardId",
        requiredAuth: true,
        component: AddCard,
        icon: "",
        sidebar: {
            visible: false
        },
        children: null
    },

]

export {routerConfig}