<template>
    <div class="d-flex m-0 tsukiji-prod-trash-main-block">
        <div>
            <img src="@/assets/trash.png" />
            <div class="w-100 mt-3">
                <h4 style="font-weight: 600">There is nothing here</h4>
                <p>Your deleted template will come here</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TrashComponent",
    computed: {},
};
</script>

<style>
.tsukiji-prod-trash-main-block {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.tsukiji-prod-trash-main-block img {
    width: 300px !important;
}
</style>
