<template>
    <div class="container-fluid px-4 pb-4">
        <div class="tsukiji-prod-page-header mt-4 mb-2">
            <h3 class="tsukiji-prod-page-title ">
                <i aria-hidden="true" @click="goBackToCard" class="bi bi-arrow-left mr-2" style="cursor: pointer"></i>
                Add a payment method
            </h3>
        </div>

        <div class="mt-5">
            <p class="mb-1">We accept the following cards:</p>
            <div class="d-flex">
                <div style="width: 50px;height: 50px;" v-for="(item, i) in acceptableCards" :key="i" class="me-1">
                    <img class="w-100 h-100" v-bind:src="getImageUrl(item)" />
                </div>
            </div>

        </div>
        <div no-gutters class="tsukiji-prod-page-header row py-4 mx-0">
            <div class="card col-6 custom-new-card p-4">
                <div class="position-relative">
                    <label for="card-number">Card number</label>
                    <div ref="cardNumber" class=" tsukiji-prod-cardElement"></div>
                    <div class="tsukiji-prod-file-custom-card" v-if="this.cardBrand"><img
                            :src="getImageUrl(this.cardBrand)" style="width:44px;height: 27px;" /></div>
                </div>


                <div class="row">
                    <div class="col-6">
                        <label for="card-expiry">Expiration date</label>
                        <div ref="cardExpiry" class="tsukiji-prod-cardElement"></div>
                    </div>
                    <div class="col-6">
                        <label for="card-cvc">CVV</label>
                        <div ref="cardPin" class="tsukiji-prod-cardElement"></div>
                    </div>
                </div>


                <div ref="cardAddress"></div>

                <div id="card-errors" role="alert" class="mt-2 tsukiji-prod-card-errors"></div>

                <div class="w-100 mt-4">
                    <button class=" btn rounded-4 btn-danger pull-right" @click="createStripeCardToken">
                        Add Card
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import {apiService } from "@/services/apiService/apiService";

let style = {};

export default {
    data() {
        return {
            cardBrand: 'SimpleCard',
            token: {},
            stripeAPIToken: process.env.VUE_APP_stripe_api_token,
            acceptableCards: [
                "Visa",
                "mastercard-old",
                "American Express",
                "Discover",
                "Diners Club",
            ],
            FilterCards: [
                {
                    name: 'visa',
                    value: "Visa"
                },
                {
                    name: "mastercard",
                    value: 'MasterCard'
                },
                {
                    name: "amex",
                    value: 'American Express'
                },
                {
                    name: "discover",
                    value: 'Discover'
                },
                {
                    name: "diners",
                    value: "Diners Club"
                },
                {
                    name: "jcb",
                    value: "JCB"
                },
                {
                    name: "unionpay",
                    value: 'UnionPay'
                }
            ],
            stripe: "",
            elements: "",
            card: "",
            addressElement: ""
        };
    },
    methods: {
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = "script",
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = "//" + URL;
            if (callback) {
                object.addEventListener(
                    "load",
                    function (e) {
                        callback(null, e);
                    },
                    false
                );
            }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },

        configureStripe() {
            this.stripe = window.Stripe(this.stripeAPIToken);

            this.elements = this.stripe.elements();

            this.card = this.elements.create("cardNumber", {
                style: {},
                placeholder: "Card number",
            });
            this.card.mount(this.$refs.cardNumber);

            this.addressElement = this.elements.create("address", {
                mode: "billing",
                defaultValues: {
                    address: {
                        line1: '\u200B\u200B\u200B',
                        line2: '',
                        city: '',
                        state: '',
                        postal_code: '',
                        country: 'US',
                    },
                },
                style: style,
            });

            this.addressElement.mount(this.$refs.cardAddress);

            const cardExpiry = this.elements.create("cardExpiry", {
                style: style,
                placeholder: "Expiration date",
            });
            cardExpiry.mount(this.$refs.cardExpiry);

            const cardCvc = this.elements.create("cardCvc", {
                style: style,
                placeholder: "CVV",
            });
            cardCvc.mount(this.$refs.cardPin);

            this.card.on("change", (event) => {
                if (event.error && (event.error?.code == "invalid_number" || event.error?.code == 'incomplete_number')) {
                    this.cardBrand = 'CardError'
                }
                else if (event.brand) {
                    const newValue = event.brand;
                    if (newValue == 'unknown') {
                        this.cardBrand = 'SimpleCard'
                    } else {
                        this.getCardBrand(newValue)
                    }

                }
            });

            [this.card, cardExpiry, cardCvc].forEach((element) => {
                element.on("change", (event) => {
                    const displayError = document.getElementById("card-errors");
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = "";
                    }
                });
            });
        },

        getCardBrand(detectedBrand) {
            for (let i = 0; i < this.FilterCards.length; i++) {
                if (this.FilterCards[i].name && this.FilterCards[i].name == detectedBrand) {
                    this.cardBrand = this.FilterCards[i].value;
                    break;
                }
            }

        },

        async createStripeCardToken() {
            const { complete, value } = await this.addressElement.getValue();
            const addressValues = {
                address_line1: value.address.line1.replace(/\u200B/g, ''),
                address_line2: value.address.line2 ? value.address.line2 : '',
                address_city: value.address.city,
                address_state: value.address.state,
                address_zip: value.address.postal_code,
                address_country: value.address.country,
            }

            if (complete) {
                this.$store.state.isLoading = true;
                this.stripe.createToken(this.card, addressValues).then((result) => {
                    this.token = result.token;
                    this.$store.state.isLoading = false;
                    if (result.token)
                        this.addStripeCard(result.token);
                });
            }

        },

        loadStripeUser() {
            this.includeStripe(
                "js.stripe.com/v3/",
                function () {
                    this.configureStripe();
                }.bind(this)
            );
        },

        addStripeCard(stripe_info) {
            const body = {
                methodType: "card",
                stripeTokenId: stripe_info.id,
                defaultMethod: true,
            };
            this.$store.state.isLoading = true;
            apiService.addStripeCard(body)
                .then(() => {
                    this.$store.state.toastMessage = "Card added successfully";
                    this.goBackToCard();
                    this.$store.state.isLoading = false;
                },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message || errorMessage.response.data;
                        this.$store.state.isLoading = false;
                    }
                )
                .finally(() => {
                    this.$store.state.toastMessage = null;
                });
        },

        goBackToCard() {
            this.$router.push({ name: "billing" });
        },

        getImageUrl(name) {
            return require("@/assets/creditCard/" + name + ".svg");
        },
    },
    mounted() { },

    created() {
        this.loadStripeUser();
    },
    beforeUnmount() {
        if (this.card) this.card.destroy(this.$refs.card);
    },
};
</script>

<style scoped>
.tsukiji-prod-cardElement {
    border: 1px solid lightgray;
    padding: 5px;
}

.credit-card-info {
    box-shadow: none !important;
    border: 1px solid #dfdfdf !important;
}

.list-group-item:not(:last-child) {
    border-bottom: 2px solid #dfdfdf;
}

.check-mark {
    color: #1dd51dbf;
}

.tsukiji-prod-cardElement {
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    background-color: white;
    margin-bottom: 10px;
}

.tsukiji-prod-file-custom-card {
    position: absolute;
    top: 47px;
    right: 10px;
    z-index: 999;
}

.tsukiji-prod-card-errors {
    color: red;
}

.Label {
    margin-bottom: 8px !important;
}
</style>
