import { Auth } from 'aws-amplify';

export async function handleSignIn(obj) {
    const { email, password } = obj;
  try {
    return  await Auth.signIn( email, password );
  } catch (error) {
    return error
  }
}

export async function checkUserAuthentication() {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const {attributes } = user
        console.log("attributes",attributes)
        return attributes;
    } catch (error) {
      console.log('error signing in', error);
    }
}


export async function handleSignOut() {
  try {
    return await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}


           
export async function handleSignUp(obj) {
  const { email, password } = obj;
  try {
      const signUpResponse = await Auth.signUp({
          username: email,
          password,
          attributes: {
              email 
          }
      });
      return signUpResponse;
  } catch (error) {
      return error;
  }
}


export async function initiateResetPassword(username) {
  try {
      const data = await Auth.forgotPassword(username);
      console.log(data); 
      return data;
  } catch (error) {
      console.log('error initiating password reset: ', error);
      return error;
  }
}


export async function completeResetPassword(username, code, newPassword) {
  try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      return data;
  } catch (error) {
      console.log('error completing password reset: ', error);
      return error;
  }
}