<template>
    <div class=" tsukiji-prod-content-align">
        <div class="w-100">
            <h5 class="tsukiji-prod-card-title">{{ actionType }} Campaigns</h5>
            <form @submit.prevent="onSubmit" class="card custom-new-card py-3 px-4">
                <div class="row">
                    <div class="col">
                        <label for="campaigns-name" class="form-label">Campaigns Name</label>
                        <input type="text" id="campaigns-name" class="form-control" v-model="campaignsName"
                            name="campaignsName" @input="this.isFormValidCheck('campaignsName')"
                            :class="{ 'is-invalid': this.editCampaignsName }" required
                            placeholder="Enter Campaigns Name" />
                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <div class="invalid-feedback">
                            Invalid
                        </div>
                    </div>
                    <div class="col">
                        <label for="campaign-subject" class="form-label">Subject</label>
                        <input type="text" id="campaign-subject" class="form-control" v-model="campaignSubject"
                            name="campaignSubject" @input="this.isFormValidCheck('campaignSubject')"
                            :class="{ 'is-invalid': this.editCampaignSubject }" required placeholder="Enter Subject" />
                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <div class="invalid-feedback">
                            Invalid
                        </div>
                    </div>
                </div>

                <div class="row pt-4">
                    <div class="col">
                        <label for="template-type" class="form-label">Template Type</label>
                        <select id="template-type" class="form-select" v-model="templateType"
                            @blur="isFormValidCheck('templateType')" @change="isFormValidCheck('templateType')" required
                            :class="{ 'is-invalid': this.editTemplateType }">
                            <option v-for="(option, index) in options" :key="index" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <div class="invalid-feedback">Invalid</div>
                    </div>
                    <div class="col">
                        <label for="start-date" class="form-label">Start Date</label>
                        <input type="date" class="form-control" id="start-date" name="startDate"
                            @input="isFormValidCheck('startDate')" :class="{ 'is-invalid': this.editStartDate }"
                            v-model="startDate" required :min="now" placeholder="Enter Start Date" />
                        <div class="invalid-feedback">Invalid</div>
                    </div>
                </div>

                <div class="row pt-4">
                    <div class="col">
                        <label for="end-date" class="form-label">End Date</label>
                        <input type="date" class="form-control" id="end-date" name="endDate"
                            @input=" isFormValidCheck('endDate')" :class="{ 'is-invalid': this.editEndDate }"
                            v-model="endDate" required placeholder="Enter End Date" />
                        <div class="invalid-feedback">Invalid</div>
                    </div>
                    <div class="col">
                        <label for="email" class="form-label">Mail to use</label>
                        <select class="form-select" id="email" name="email" @blur="isFormValidCheck('mailToUse')"
                            @change="isFormValidCheck('mailToUse')" :class="{ 'is-invalid': this.editMailToUse }"
                            v-model="mailToUse" required>
                            <option v-for="(option, index) in identities" :value="option.value" :key="index">
                                {{ option.text }}
                            </option>
                        </select>
                        <div class="invalid-feedback">Invalid</div>
                    </div>
                </div>

                <div class="row pt-4">
                    <div class="col">
                        <span class="d-flex align-items-center mb-2">
                            <label for="friendlyName" class="mb-0 form-label">Business Name</label>
                            <i class="fa fa-info-circle ml-2" aria-hidden="true">
                                <span class="tsukiji-prod-tooltip-text">Use a business name that reflects your brand
                                    identity</span>
                            </i>
                        </span>
                        <input type="text" class="form-control" id="friendlyName" name="friendlyName"
                            @input="isFormValidCheck('friendlyName')" :class="{ 'is-invalid': this.editFriendlyName }"
                            v-model="friendlyName" required placeholder="Business Name" />
                        <div class="invalid-feedback">
                            Invalid
                        </div>
                    </div>
                    <div class="col">
                        <label for="group" class="form-label">Group</label>
                        <select id="group-type" class="form-select" v-model="leadsGroups"
                            @blur="isFormValidCheck('leadsGroups')" @change="isFormValidCheck('leadsGroups')"
                            :class="{ 'is-invalid': this.editLeadsGroups }" required="true">
                            <option v-for="(option, index) in groupList" :key="index" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <div class="invalid-feedback">Invalid</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12  mt-4">
                        <button type="button" class=" btn rounded-4 btn-outline-danger me-2 cancel_button"
                            @click="navigateTo">
                            Cancel
                        </button>
                        <button type="submit" class=" btn rounded-4 btn-info">
                            {{ actionType }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import { apiService } from "../../services/apiService/apiService";
export default {
    data() {
        return {
            campaignsId: null,
            campaignsName: null,
            campaignSubject: null,
            templateType: null,
            startDate: null,
            endDate: null,
            leadsGroups: null,
            isFormSubmitted: false,
            mailToUse: null,
            successIdentities: null,
            friendlyName: null,
            // error handling state
            editCampaignSubject: null,
            editCampaignsName: null,
            editTemplateType: null,
            editStartDate: null,
            editEndDate: null,
            editMailToUse: null,
            editFriendlyName: null,
            editLeadsGroups: null,
            // ends
            today: this.getTodayDate(),
            options: apiService.formatOptions(
                this.$store.state.templates,
                "Templates"
            ),
            groupList: apiService.formatOptionsWithKeyValue(
                this.$store.state.leadsGroups,
                "Group",
                "name",
                "id"
            ),
            identities: apiService.formatOptionsWithKey(
                this.$store.state.mailToUse,
                "Identities",
                "email"
            ),
            actionType: null,
            rows: null,

            now: new Date().toISOString(),
        };
    },

    methods: {
        getTodayDate() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const date = String(now.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${date}`;
            return formattedDate
        },
        changeMailToUse(value) {
            this.mailToUse = value;
        },
        checkAllValidation() {
            if (!this.templateType || !this.mailToUse || !this.startDate || !this.endDate || !this.leadsGroups) {
                const newArr = ['templateType', 'mailToUse', 'leadsGroups']
                for (let index = 0; index < newArr.length; index++) {
                    this.isFormValidCheck(newArr[index]);
                }
                return false
            }
            else {
                return true
            }
        },
        onSubmit(evt) {
            this.isFormSubmitted = true;
            if (!this.campaignsName && !this.campaignsId) {
                return false;
            }
            const returnValue = this.checkAllValidation();
            if (returnValue == false) {
                return false
            }
            evt.preventDefault();
            this.create();
        },
        create() {
            const body = {
                name: this.campaignsName,
                subject: this.campaignSubject,
                tid: this.templateType,
                sdate: this.getStartDate(this.startDate),
                edate: this.getEndDate(this.endDate),
                cgrp: this.leadsGroups,
                mailToUse: this.mailToUse,
                friendlyName: this.friendlyName,
            };
            if (this.actionType === "Create") {
                this.$store.state.isLoading = true;
                apiService.createCampaigns(body)
                    .then(() => {
                        this.balanceDeduction()
                        this.$store.state.toastMessage = "Created Successfully !!";
                        this.navigateTo();
                    })
                    .catch((error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message || errorMessage.response.data;
                    })
                    .finally(() => {
                        this.$store.state.toastMessage = "";
                        this.$store.state.isLoading = false;
                    });
            } else {
                body["cid"] = this.campaignsId;
                this.$store.state.isLoading = true;
                apiService.updateCampaigns(body)
                    .then(() => {
                        this.$store.state.toastMessage = "Updated Successfully !!";
                        this.navigateTo();
                    })
                    .catch((error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message || errorMessage.response.data;
                    })
                    .finally(() => {
                        this.$store.state.toastMessage = "";
                        this.$store.state.isLoading = false;
                    });
            }
        },

        getStartDate(date) {
            const updatedDate = new Date(date);
            updatedDate.setTime(new Date(date).getTime());
            return updatedDate.toISOString();
        },

        getEndDate(date) {
            const updatedDate = new Date(date);
            updatedDate.setHours(23);
            updatedDate.setMinutes(59);
            updatedDate.setSeconds(59);
            return updatedDate.toISOString();
        },

        navigateTo() {
            const query = Object.assign({}, this.$route.query);
            delete query.page;
            const previousPage = parseInt(this.$route.query.page) || 1;
            this.$router.push({ name: "Campaigns", query: { ...query, page: previousPage } });
        },



        getTemplates() {
            apiService.getTemplates()
                .then((res) => {
                    const arr = [];
                    for (let ele in res.data.All_templates) {
                        arr.push(res.data.All_templates[ele]);
                    }
                    this.$store.state.templates = res.data.Total_templates ? arr : [];
                    this.options = apiService.formatOptions(
                        this.$store.state.templates,
                        "Templates"
                    );
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },

        getCampaignsById(id) {
            apiService.getCampaignsById(id)
                .then((res) => {
                    if (res) {
                        const data = res.data;
                        this.campaignsId = id;
                        this.campaignsName = data.name;
                        this.campaignSubject = data.subject;
                        this.startDate = data.start_date.substring(0, 10);
                        this.templateType = data.template_id;
                        this.endDate = data.end_date.substring(0, 10);
                        this.leadsGroups = data.Customer_group_id;
                        this.mailToUse = data.emailUsed;
                    }
                })
                .catch((error) => {
                    const errorMessage = { ...error };
                    this.$store.state.toastMessage =
                        errorMessage.response.data.message || errorMessage.response.data;
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },

        isFormValidCheck(key) {
            switch (key) {
                case "friendlyName":
                    this.editFriendlyName = !this.friendlyName;
                    break;

                case "campaignsName":
                    this.editCampaignsName = !this.campaignsName;
                    break;

                case "campaignSubject":
                    this.editCampaignSubject = !this.campaignSubject;
                    break;

                case "templateType":
                    this.editTemplateType = !this.templateType;
                    break;

                case "mailToUse":
                    if (!this.mailToUse) {
                        this.editMailToUse = true;
                    } else {
                        this.getFriendlyName();
                        this.editMailToUse = false;
                    }
                    break;

                case "startDate":
                    if (this.endDate) {
                        console.log("inside if");
                        this.editStartDate = this.isoFormat(this.today) > this.isoFormat(this.startDate) || this.isoFormat(this.endDate) <= this.isoFormat(this.startDate);
                    } else {
                        console.log('inside else');
                        this.editStartDate = this.isoFormat(this.today) > this.isoFormat(this.startDate)
                    }
                    break;

                case "endDate":
                    this.editEndDate = this.isoFormat(this.endDate) <= this.isoFormat(this.startDate)
                    break;

                case "leadsGroups":
                    this.editLeadsGroups = !this.leadsGroups;
                    break;

                default:
                    break;
            }
        },

        getGroups() {
            apiService.getGroups()
                .then((res) => {
                    const leadGroup = res.data.filter(
                        (item) => item.leadsCount > 0 && item.status === "ACTIVE"
                    );
                    this.groupList = apiService.formatOptionsWithKeyValue(
                        leadGroup,
                        "Group",
                        "name",
                        "id"
                    );
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    // this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        isoFormat(date) {
            return new Date(date).toISOString();
        },
        getIdentityEmail() {
            this.$store.state.isLoading = true;
            apiService.identityManagement()
                .then((res) => {
                    const identityWithIndex = apiService.objectItemWithIndex(res.data);
                    this.successIdentities = identityWithIndex.filter(
                        (item) => item.status === "Success"
                    );
                    this.identities = apiService.formatOptionsWithKey(
                        this.successIdentities,
                        "Identities",
                        "email"
                    );
                })
                .catch(() => { })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        getFriendlyName() {
            for (let i = 0; i < this.successIdentities.length; i++) {
                if (this.successIdentities[i].email == this.mailToUse) {
                    this.friendlyName = this.successIdentities[i].friendlyName;
                }
            }
        },
        getLeadsGroup() {
            this.$store.state.isLoading = true;
            apiService.getCustomerGroups().then(res => {
                this.$store.state.isLoading = false;
                this.$store.state.leadsGroups = res.data;
                this.rows = res.data;
            }).catch(() => {
                this.$store.state.toastMessage = "Something went wrong!";
            }).finally(() => {
                this.$store.state.toastMessage = "";
                this.$store.state.isLoading = false;
            });
        },
        balanceDeduction() {
            const group = this.rows.find((data) => data.id === this.leadsGroups);
            if (group && this.$store.state.balance) {
                this.$store.state.balance -= group.leadsCount
            }
        },
    },

    created() {
        const params = this.$route.params;
        this.actionType = "Create";
        if (params && params.id && params.id !== "new") {
            this.actionType = "Update";
            this.getCampaignsById(params.id);
        }
        this.getTemplates();
        this.getIdentityEmail();
        this.getLeadsGroup();
        const currentPage = parseInt(this.$route.query.page) || 1;
        this.currentPage = currentPage;
        if (currentPage > 1) {
            this.active = 0;
            this.progress = 0;
        }
    },

};
</script>

<style scoped>
.card-body {
    min-height: calc(100vh - 160px);
}

.tsukiji-prod-card-title {
    margin-bottom: 1.5rem;
}

.btn-outline-danger:hover,
.btn-outline-danger:not(:disabled):not(.disabled):active {
    background-color: #e6dedf;
    border-color: #e6dedf;
    color: darkgray;
}

:deep(.btn-outline-danger:hover) {
    box-shadow: none !important;
}

.fa-info-circle .tsukiji-prod-tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: rgb(40, 37, 37);
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    /* bottom: 60%; */
    bottom: 100%;
    left: 0px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.fa-info-circle:hover .tsukiji-prod-tooltip-text {
    visibility: visible;
}

@media screen and (max-width: 912px) {
    .fa-info-circle .tsukiji-prod-tooltip-text {
        right: -20px;
        left: auto;
    }
}

.tsukiji-prod-content-align {
    display: flex;
    justify-content: center;
    margin: 30px;
}
</style>
