<template>
    <div class="container-fluid main-content-container pb-2">
        <div class="tsukiji-prod-page-header row g-0 pt-3">
            <div class="col-12 col-sm-4 text-center text-sm-start mb-0">
                <h3 class="tsukiji-prod-page-title" style="margin-left:17px;"><b>Identity</b></h3>
            </div>
            <div class="row g-0 py-4 m-0">
                <div class="col-12 col-md-6 col-lg-8">
                    <button type="button" class=" btn rounded-4 btn-outline-danger" data-bs-toggle="modal"
                        data-bs-target="#modalNew" @click="handleClick" style="margin-left:17px;">
                        Verify A New Email Address
                    </button>
                </div>
                <div class="col-12 col-md-6 col-lg-4"></div>
            </div>
        </div>

        <div class="modal fade" id="modalNew" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">
                            Verify A New Email Address
                        </div>

                    </div>
                    <div class="modal-body">
                        <p style="font-size: 15px">
                            To verify a new email address, enter it below and
                            click the Verify This Email Address button. A
                            verification email will be sent to the email address
                            you entered.<span style="color: grey; font-weight: bold">Please check your spam folder if
                                doesn't in
                                inbox.</span>
                        </p>
                        <div class="row g-0 my-3">
                            <div class="col col-sm-3">
                                <label for="email" class="form-label">Email Address:</label>
                            </div>
                            <div class="col col-sm-9">
                                <input id="email" class="form-control" type="text" v-model="email"
                                    @input="isFormValidCheck('email')" :class="[
                            'form-control',
                            { 'is-invalid': this.editEmail },
                        ]" required name="email" />
                                <div class="invalid-feedback">
                                    Enter valid email
                                </div>
                            </div>
                        </div>
                        <div class="row g-0 my-3">
                            <div class="col col-sm-3 d-flex align-items-center">
                                <label for="friendlyName" class="form-label mb-0">Business Name:</label>
                                <i class="fa fa-info-circle ml-2" aria-hidden="true">
                                    <span class="tsukiji-prod-tooltip-text ">Use a business name that reflects your
                                        brand identity</span>
                                </i>
                            </div>
                            <div class="col col-sm-9">
                                <input id="friendlyName" :class="[
                            'form-control',
                            { 'is-invalid': this.editFriendlyName },
                        ]" @input="isFormValidCheck('friendlyName')" type="text" v-model="friendlyName" required
                                    name="friendlyName" />
                                <div class="invalid-feedback">
                                    Enter valid business name
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="revertValues()" type="button" class="btn rounded-4 btn-outline-danger"
                            data-bs-dismiss="modal" aria-label="Close" ref="btnClose">Close</button>
                        <button type="button" class=" btn rounded-4 btn-outline-info" v-if="!isLoading"
                            @click="addEmailForVerification">
                            Verify
                        </button>
                        <button type="button" class=" btn rounded-4 btn-outline-danger" v-if="isLoading">
                            Loading please wait
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div class="card row g-0 m-0" style="margin-left:18px !important;">
            <div class="card col custom-new-card p-4 bg-0">
                <VueGoodTable :rows="tableData" :columns="columns" :sort-options="{ enabled: false }">
                    <template #table-row="{ row, column }">
                        <span v-if="column.field == 'action'">
                            <a>
                                <span class="bi bi-arrow-repeat" @click="refreshEmail(row.email)">
                                </span>&nbsp;&nbsp;
                                <span class="bi bi-trash-fill" @click="deleteEmail(row.email)"></span>
                            </a>
                        </span>
                        <span v-if="column.field == 'resend'">
                            <a v-if="row.status !== 'Success'" class="tsukiji-prod-verified-badge">
                                <span class="bi bi-arrow-clockwise"
                                    @click="resendEmail(row.email, row.friendlyName)"></span>
                                Verify
                            </a>
                            <a v-if="row.status === 'Success'" class="tsukiji-prod-verified-badge">
                                <span class="bi bi-check-circle"></span>
                                Verified
                            </a>
                        </span>
                    </template>
                </VueGoodTable>
            </div>
        </div>
    </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import {apiService} from "@/services/apiService/apiService";
import UTILSService from "@/services/utils/utils.service";
import SweetAlert from "@/services/sweetAlerts/SweetAlerts"
export default {
    name: "IdentityComponent",
    components: {
        VueGoodTable,
    },
    data() {
        return {
            email: null,
            friendlyName: null,
            columns: [
                {
                    label: "Action",
                    field: "action",
                },
                {
                    label: "Email Address Identities",
                    field: "email",
                },
                {
                    label: "Verification Status",
                    field: "status",
                },
                {
                    label: "Business Name",
                    field: "friendlyName",
                },
                {
                    label: "Status",
                    field: "resend",
                },
            ],
            // tableData: this.$store.state.identities,
            tableData: [],
            showModal: false,
            isLoading: false,
            isFormSubmitted: false,
            callApi: true,
            editEmail: null,
            editFriendlyName: null,
        };
    },
    methods: {
        revertValues() {
            this.isFormSubmitted = false;
            this.email = null;
            this.friendlyName = null;
            this.editEmail = false;
            this.editEmail = null;
            this.editFriendlyName = null;
        },
        handleClick() {
            this.isFormSubmitted = false;
            this.showModal = true;
        },
        handleClose() {
            this.email = "";
            this.friendlyName = "";
            this.showModal = false;
        },

        isFormValidCheck(key) {
            if (!this.isFormSubmitted) {
                return null;
            }
            if (this[key]) {
                this[key] = this[key].trim();
            }
            if (key === "email") {
                return UTILSService.EMAIL_REGEX.test(this[key]) ? this.editEmail = false : this.editEmail = true;
            }

            if (key === "friendlyName") {
                return this[key] ? this.editFriendlyName = false : this.editFriendlyName = true;
            }
        },

        addEmailForVerification() {
            this.isFormSubmitted = true;
            this.isFormValidCheck("email");
            this.isFormValidCheck("friendlyName");
            if (this.editEmail || this.editFriendlyName) {
                return false;
            }
            this.$store.state.isLoading = true;
            this.$store.state.toastMessage = "";
            this.isLoading = true;
            const body = {
                email: this.email,
                friendlyName: this.friendlyName,
            };
            apiService.addEmailForVerification(body)
                .then(
                    () => {
                        this.getIdentityEmail();
                        this.showModal = false;
                        this.$refs.btnClose.click();
                        this.email = null;
                        (this.friendlyName = null),
                            (this.$store.state.toastMessage =
                                "Email added successfully please check your email for verification");
                    },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message ||
                            errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                    this.$store.state.isLoading = false;
                });
        },

        getIdentityEmail() {
            this.$store.state.isLoading = true;
            apiService.identityManagement()
                .then((res) => {
                    const identityWithIndex = apiService.objectItemWithIndex(
                        res.data
                    );
                    this.$store.state.identities = identityWithIndex;
                    this.tableData = identityWithIndex;
                    const pendingEmail = this.tableData.filter(
                        (identityWithIndex) =>
                            identityWithIndex.status == "Pending"
                    );
                    const allPendingEmail = pendingEmail.map(
                        (Value) => Value.email
                    );
                    if (allPendingEmail.length > 0 && this.callApi == true) {
                        for (let i = 0; i < allPendingEmail.length; i++) {
                            this.refreshEmail(allPendingEmail[i]);
                        }
                    } else {
                        this.callApi = false;
                    }
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        deleteEmailIdentification(email) {
            this.$store.state.isLoading = true;
            this.$store.state.toastMessage = "";
            apiService.deleteEmailIdentification(email)
                .then(
                    () => {
                        this.getIdentityEmail();
                        this.showModal = false;
                        this.$store.state.toastMessage =
                            "Email deleted successfully";
                    },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message ||
                            errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        refreshEmail(email) {
            this.callApi = false;
            this.$store.state.isLoading = true;
            this.$store.state.toastMessage = "";
            apiService.refreshEmailIdentification(email)
                .then(() => {
                    this.getIdentityEmail();
                    this.showModal = false;
                    this.$store.state.toastMessage = "Identities Refreshed";
                },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message ||
                            errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        deleteEmail(email) {
            SweetAlert.Confirmation({ title: 'Are you sure want delete this email ?' }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteEmailIdentification(email);
                }
            });
        },
        resendEmail(email, friendlyName) {
            const body = {
                email: email,
                friendlyName: friendlyName,
            };
            this.$store.state.isLoading = true;
            this.$store.state.toastMessage = "";
            apiService.addEmailForVerification(body)
                .then(
                    () => {
                        this.getIdentityEmail();
                        this.showModal = false;
                        this.$store.state.toastMessage =
                            "Resend email successfully";
                    },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message ||
                            errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
    },
    created() {
        const query = this.$router.currentRoute.query;
        if (query && query.email) {
            this.refreshEmail(query.email);
            return;
        }
        this.getIdentityEmail();
    },
};
</script>

<style scoped>
.modal-title,
.close,
label {
    font-weight: 600;
}

:deep(.VueTables label) {
    display: none !important;
}

:deep(.VueTables .form-group) {
    padding: 10px 0px !important;
}

:deep(.VueTables__child-row-toggler--closed::before) {
    content: "+";
}

:deep(.VueTables__child-row-toggler--open::before) {
    content: "-";
}

:deep(table th),
:deep(table td) {
    outline: none;
}

:deep(table td:first-child) {
    width: 50px;
}

:deep(table td:nth-child(1)) {
    text-align: center;
}

.spacer {
    flex: 1 1 auto;
}

.template-card {
    cursor: pointer;
    border-radius: 0;
    border: 2px solid transparent;
}

.template-active {
    transition-duration: 1s;
    border: 2px solid #027bfc;
}

label.template {
    font-weight: 600;
}

.tsukiji_custom_card {
    max-width: calc(25% - 0.5rem);
}

.tsukiji_card_hover:hover {
    z-index: 2;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.fa-info-circle .tsukiji-prod-tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: rgb(40, 37, 37);
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 10%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.fa-info-circle:hover .tsukiji-prod-tooltip-text {
    visibility: visible;
}

@media screen and (max-width: 575px) {
    .fa-info-circle .tsukiji-prod-tooltip-text {
        bottom: 100%;
        left: 0px;
    }
}

.disabled {
    pointer-events: none;
}

.tsukiji-prod-verified-badge {
    background-color: #0c244a;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 6px;
    font-style: italic;
}
::v-deep table.vgt-table {
    background-color: transparent;
}
::v-deep .vgt-table thead th {
    background: transparent !important;
}
</style>
