<template>
    <div>
        <div :class="{ 'authentication': !userLogin, 'loading': isLoading }">
            <authenticator v-if="rendered && !userLogin" :form-fields="formFields" :initial-state="initialState"
                :hideSignUp="!hideSignUp">
                <!-- Login Page Content -->
                <template v-slot:header>
                    <div style="text-align: center" class="logo py-4">
                        <img class="amplify-image" alt="Amplify logo" :src="logoPath" />
                    </div>
                </template>
            </authenticator>

            <div v-else :class="[$store.state.layout_style, $store.state.menu_style]">
                <app>
                    <router-view />
                </app>
            </div>
        </div>

        <router-link v-if="showSupportIcon" :to="{ path: '/support' }"
            class="btn btn-danger support-btn rounded-circle">
            <i class="bi bi-question" style="font-size: 40px;"></i>
        </router-link>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import '@/assets/sass/app.scss';
import { useMeta } from '@/composables/use-meta';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Auth, Hub } from 'aws-amplify';

useMeta({ title: 'Sales Admin' });

const store = useStore();
const userLogin = ref(false);
const isLoading = computed(() => store.state.isLoading);
const router = useRouter();

async function setAuthListener() {
    try {
        store.commit('setLoading', true);
        const user = await Auth.currentAuthenticatedUser();
        userLogin.value = true;
        store.user = user;
        router.push('/');
    } catch (err) {
        console.log("No current user:", err);
        router.push('/login');
    } finally {
        store.commit('setLoading', false);
    }

    try {
        const session = await Auth.currentSession();
        store.session = session;
    } catch (error) {
        console.log("Error getting current session:", error);
    }

    Hub.listen("auth", (data) => {
        const { payload } = data;
        if (payload.event === "signIn") {
            console.log("User signed in:", payload.data);
            userLogin.value = true;
            store.user = payload.data;
            router.push('/');
        }
        if (payload.event === "signOut") {
            console.log("User signed out");
            store.user = null;
            router.push('/login');
        }
    });
}

watch(() => store.getters.isAuthenticated, (isAuthenticated) => {
    userLogin.value = isAuthenticated;
});

const layout = computed(() => store.getters.layout);

setAuthListener();

// Track whether the user has navigated to the support page
const showSupportIcon = ref(true);
router.afterEach((to, from) => {
    if (to.path === '/support' || to.path === '/login') {
        showSupportIcon.value = false;
    } else {
        showSupportIcon.value = true;
    }
});
</script>

<script>
import Logo from "../src/assets/logo.png"
import appLayout from './layouts/app-layout.vue';
import { toast } from "vue3-toastify";

export default {
    components: {
        app: appLayout,
    },
    data() {
        return {
            userLogin: false,
            logoPath: Logo,
            rendered: true,
            initialState: "signIn",
            formFields: {
                signIn: {
                    username: {
                        placeholder: "Enter your email",
                        label: "Email",
                    },
                },
                signUp: {
                    username: {
                        placeholder: "Enter your email",
                        label: "Email",
                    },
                },
                resetPassword: {
                    username: {
                        placeholder: "Enter your email",
                        label: "Email",
                    },
                },
            },
        };
    },
    methods: {
        showHideLoader() {
            if (this.$store.state.isLoading) {
                // Check if loadingSpinner is not already initialized
                if (!this.loadingSpinner) {
                    this.loadingSpinner = this.$loading.show({
                        color: "#e7515a",
                        loader: "spinner",
                        width: 128,
                        height: 128,
                        backgroundColor: "#ffffff",
                        opacity: 0.5,
                        zIndex: 9999,
                        fullPage: true,
                        class: "loading-spinner-modal",
                    });
                }
            } else {
                // Check if loadingSpinner is initialized before calling hide()
                if (this.loadingSpinner) {
                    this.loadingSpinner.hide();
                    this.loadingSpinner = null; // Reset loadingSpinner after hiding
                }
            }
        },

        showToastMessage(message) {
            if (!message) {
                return false;
            }
            toast(message, {
                autoClose: 2000,
            });
        },
    },
    computed: {
        getLoaderInfo() {
            return this.$store.state.isLoading;
        },

        getToastMessage() {
            return this.$store.state.toastMessage;
        },
    },
    watch: {
        '$store.state.toastMessage'(newValue) {
            this.showToastMessage(newValue);
        },
        '$store.state.isLoading'() {
            this.showHideLoader();
        }
    },

};
</script>

<style scoped>
.authentication {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: url(../src/assets/bg-image.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: soft-light;
}

:deep(.amplify-button--primary) {
    background-color: #b10214 !important;
    outline: none !important;
}

:deep(.amplify-button--primary:hover) {
    background-color: #e1081e !important;
    outline: none !important;
}

:deep(.amplify-button--link) {
    color: #b10214 !important;
    font-size: 17px;
}

:deep(.amplify-button--link:hover) {
    background-color: #fee3e6 !important;

}

/* .amplify-image {

} */
.amplify-background-image {
    background-color: rgb(0, 0, 0);

}

.support-btn {
    position: fixed;
    bottom: 20px;
    right: 25px;
    z-index: 999;
}

.support-btn:hover,
.support-btn:focus {
    background-color: #dc3545;

}

.btn.rounded-circle {
    height: 60px;
    width: 60px;
}
</style>
