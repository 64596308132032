<template>
    <div class="content-align main-content-container pb-4" v-if="viewType === 'editor'">
        <div class="d-flex mx-0 justify-content-center">
            <SharedModal :showModal="showModal" :handleClose="handleClose" :setImageToEditor="setImageToEditor"
                :activeTab="activeTab" />
            <EmailEditor ref="emailEditor" v-on:load="editorLoaded" v-on:selectImage="onSelectImage"></EmailEditor>
        </div>
    </div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor';
import ImagePicker from './ImagePicker.vue';
export default {
    props: ['viewType'],
    components: {
        SharedModal: ImagePicker,
        EmailEditor
    },
    data() {
        return {
            loading: true,
            showModal: false,
            activeTab: 'existing',
            doneImageLoad: null,
        };
    },
    methods: {
        setImageToEditor(image) {
            console.log("selected Image=>", image)
            if (this.doneImageLoad) this.doneImageLoad({ url: `https://${image}` });
            this.showModal = false;
            setTimeout(() => {
                this.doneImageLoad = null;
            })
        },
        handleClose() {
            this.showModal = false;
        },
        editorLoaded() {
            const ele = document.getElementsByClassName('blockbuilder-branding');
            if (ele.length && ele[0]) ele[0].remove();
            console.log(" => this.$refs.emailEditor: ", this.$refs);

            this.$refs.emailEditor.editor.registerCallback('selectImage', (data, done) => {
                console.log(data)
                this.showModal = true;
                this.doneImageLoad = done;
            });

            this.$refs.emailEditor.editor.addEventListener('design:updated', () => {
                this.$refs.emailEditor?.editor?.exportHtml((data) => {
                    this.$emit('content', data.html);
                })
            });

            console.log(" => this.$refs.emailEditor: ", this.$refs.emailEditor);
        },

        onSelectImage() {
            console.log("Working Here")
        },
        saveDesign() {
            this.$refs.emailEditor.editor.saveDesign((design) => {
                console.log('saveDesign', design);
            })
        },
        exportHtml() {
            return new Promise((resolve) => {
                this.$refs.emailEditor.editor.exportHtml((data) => {
                    resolve(data.html);
                })
            })
        }
    },
    mounted() {
        setTimeout(() => { this.loading = false; });
    }
};
</script>

<style scoped></style>