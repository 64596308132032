<template>
    <div class="form full-form auth-cover">
        <div class="form-container">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">
                                Log In to <router-link to="/"><span class="brand-name">{{
                                    $store.state.layoutConfig.title }}</span></router-link>
                            </h1>
                            <p class="signup-link">New Here? <router-link to="/register">Create an account</router-link>
                            </p>
                            <form class="text-start" @submit.prevent="login">
                                <div class="form">
                                    <div id="username-field" class="field-wrapper input">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <input type="email" class="form-control"
                                            :class="{ 'is-invalid': !isEmailValid.value }" placeholder="Email"
                                            v-model="email" @blur="validateEmail" />
                                        <div class="invalid-feedback" v-if="!isEmailValid.value">{{ isEmailValid.message
                                            }}</div>
                                    </div>

                                    <div id="password-field" class="field-wrapper input pb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock">
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <input type="password" ref="passElement" class="form-control"
                                            :class="{ 'is-invalid': !isPasswordValid.value }" placeholder="Password"
                                            v-model="password" @blur="validatePassword" />
                                        <div class="invalid-feedback" v-if="!isPasswordValid.value">{{
                                    isPasswordValid.message }}</div>
                                    </div>
                                    <div class="invalid-feedback d-block my-2" v-if="authError.value">{{
                                    authError.message }}</div>
                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper toggle-pass d-flex align-items-center">
                                            <p class="d-inline-block">Show Password</p>
                                            <label class="switch s-primary mx-2">
                                                <input type="checkbox" class="custom-control-input" :checked="false"
                                                    :onchange="(e) =>{isShowPass(e.target.checked)}" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary"><span v-if="isLoading"
                                                    style="width: 19px;height: 19px;"
                                                    class="spinner-border text-white me-2 align-self-center loader-sm"></span>{{!isLoading
                                                ? 'Log In' : '' }}</button>
                                        </div>
                                    </div>

                                    <div class="field-wrapper">
                                        <router-link to="/pass-recovery" class="forgot-pass-link">Forgot
                                            Password?</router-link>
                                    </div>
                                </div>
                            </form>
                            <p class="terms-conditions">
                                © 2020 All Rights Reserved. <router-link to="/">{{ $store.state.layoutConfig.title
                                    }}</router-link> is a product of Arrangic Solutions LLP. <a
                                    href="javascript:void(0);">Cookie Preferences</a>,
                                <a href="javascript:void(0);">Privacy</a>, and <a href="javascript:void(0);">Terms</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-image">
                <div class="l-image"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import '@/assets/sass/authentication/auth.scss';
import { handleSignIn } from '@/services/authService/authHelper';
import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Login Cover' });
import { useStore } from 'vuex';
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const email = ref('');
const password = ref('');
const passElement = ref(null);
const started = ref(false);
const store = useStore();
const isLoading = ref(false);
const authError = reactive({
    value: false,
    message: ''
});
const isEmailValid = reactive({
    value: true,
    message: ''
});
const isPasswordValid = reactive({
    value: true,
    message: ''
});


const isShowPass = (checked) => {
    if (checked) {
        passElement.value.type = "text";
    }
    else {
        passElement.value.type = "password";
    }
}

const validateEmail = () => {
    if (!started.value) return

    if (email.value === '') {
        isEmailValid.value = false;
        isEmailValid.message = 'Please enter your email address.';
        return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    isEmailValid.value = emailRegex.test(email.value);

    if (!isEmailValid.value) {
        isEmailValid.value = false;
        isEmailValid.message = 'Please enter a valid email address.';
    }

    else {
        isEmailValid.value = true;
        isEmailValid.message = '';
    }

};

const validatePassword = () => {
    if (!started.value) return
    if (password.value === '') {
        isPasswordValid.value = false;
        isPasswordValid.message = 'Please enter your password.';
        return;
    }
    else if (password.value.length < 4) {
        isPasswordValid.value = false;
        isPasswordValid.message = 'Please enter a valid password (minimum 4 characters).';
    }
    else {
        isPasswordValid.value = true;
        isPasswordValid.message = '';
    }
};

const resetValidation = () => {
    isEmailValid.value = true;
    isEmailValid.message = '';
    isPasswordValid.value = true;
    isPasswordValid.message = '';
};

const resetAuthMessages = () => {
    authError.value = false;
    authError.message = '';
};


const login = () => {
    if (!started.value) {
        started.value = true;
    }

    validateEmail();
    validatePassword();
    resetAuthMessages()

    if (!isEmailValid.value || !isPasswordValid.value) {
        return;
    }

    const data = {
        email: email.value,
        password: password.value
    };

    console.log("it comes here ", data)

    isLoading.value = true;
    handleSignIn(data).then((res) => {
        if (res.attributes) {
            console.log("res.attributes", res.attributes)
            store.commit('setUser', res.attributes);
            store.commit('setIsAuthenticated', true);
            console.log("it runs here ")
            router.push("/");
        }
        else {
            authError.value = true;
            authError.message = res.message;
        }
    }).catch((err) => {
        console.log("got the Error Value finally ---->", err)
    }).finally(() => {
        isLoading.value = false;
    })
};
</script>
@/services/authService/authHelper
<style scoped>
.form-form-wrap form .field-wrapper input {
    padding: 0px 0px 0px 35px !important;
}
</style>