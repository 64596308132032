<template>
    <div class="container-fluid px-0 pb-4" v-if="globalTemplates?.length">
        <div no-gutters class="tsukiji-prod-temp-header d-flex justify-content-between tsukiji-prod-page-header py-4">
            <h3 class="tsukiji-prod-page-title">Template Library</h3>
            <div v-if="!templatesLength">
                <TemplateModal :ShowTemplateModal="ShowTemplateModal" :handleClose="handleClose"
                    :saveModalChanges="saveModalChanges" />
            </div>

        </div>
        <CardCarousel :contentLength="globalTemplates.length">
            <div v-for="(template, index) in globalTemplates" :key="template.data" :style="delayTemplateStyle(index)">
                <ThumbnailCard :url="(template.thumbnail || template.thumbnail_url)" :name="template.name"
                    @clicked="editTemplate(template)" />
            </div>
        </CardCarousel>
    </div>
</template>

<script>
import {apiService} from "@/services/apiService/apiService";
import ThumbnailCard from "./ThumbnailCard.vue";
import TemplateModal from "./TemplateModal.vue";
import CardCarousel from "@/components/Shared/Carousel/CardCarousel.vue";
 export default {
    props: ['templatesLength', 'setGlobalLength'],
    data() {
        return {
            globalTemplates: [],
            ShowTemplateModal: false
        }
    },
    components: {
        ThumbnailCard, TemplateModal, CardCarousel
    },
    methods: {
        saveModalChanges(value) {
            let typeParam = null;
            if (value == 1) {
                typeParam = 'draganddrop'
            }
            else if (value == 2) {
                typeParam = 'customtemplate'
            }
            if (value) {
                this.$router.push({
                    name: "Template-Editor",
                    params: { templateId: "new" },
                    query: { templateIds: this.selectedTemplates, type: typeParam },
                });
                this.$store.state.TemplateName = '';
                this.ShowTemplateModal = false;
            }
        },
        handleClose() {
            this.ShowTemplateModal = false
        },
        loadGlobalTemplates() {
            apiService.loadGlobalTemplate()
                .then((templates) => {
                    this.globalTemplates = templates?.data.filter((item) => item.name);
                    this.setGlobalLength(this.globalTemplates);
                })
                .catch((error) => {
                    console.error('Error loading global templates:', error);

                });
        },

        navigate(template) {
            this.$router.push({
                name: "Template-Editor",
                params: { templateId: template.data },
            });
        },
        editTemplate(item) {
            this.$store.state.TemplateName = item.name;
            this.$router.push({ name: "Template-Editor", params: { templateId: 'new' }, query: { content: item.content } });
        },
        newTemplate() {
            this.ShowTemplateModal = true
        },
        delayTemplateStyle(index) {
            const delay = index * 300 // 2s delay for each index
            return {
                animationDelay: `${delay}ms`,
            };
        },
    },
    created() {
        this.loadGlobalTemplates();
    }
};
</script>

<style scoped>
span.spacer {
    flex: 1 1 auto;
}

.template-card {
    flex: 1 1 auto;
}

.tsukiji-prod-temp-header {
    margin: 0px 18px;
}
</style>