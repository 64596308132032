<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <SidebarItem v-for="(item, index) in sidebarConfig" :key="index" :item="item"  :dropdown="false" parentItem=""/>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import SidebarItem from './sidebarItem.vue';
    import { getSidebarConfig } from '@/router/routerService/router.service';
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    const store = useStore();

    const menu_collapse = ref('dashboard');

    const sidebarConfig = ref(getSidebarConfig());

    onMounted(() => {
        console.log("sidebarConfig", sidebarConfig.value)
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });



    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>
<style scoped>

</style>
