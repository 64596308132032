<template>
    <button class=" btn btn-danger rounded-4" id="create-template" @click="handleClick(2);handleModalChange()">
        Create New Template
    </button>

    <QueryModal :addAiTemplate="addAiTemplate" v-if="isDev" templateIcon="false" />


    <!-- conditional Modal Ends-->
</template>

<script>
import QueryModal from "./ai/QueryModal.vue"
export default {
    name: "TemplateModal",
    props: ['handleClose', 'saveModalChanges', 'ShowTemplateModal'],
    components:{QueryModal},
    data() {
        return {
            selectedTemplate: null,
            queryModal:false
        }
    },
    methods: {
        handleClick(value) {
            this.selectedTemplate = value;
        },
        handleModalChange() {
            this.saveModalChanges(this.selectedTemplate);
        },
        addAiTemplate(query){
            console.log("query",query)
            this.handleClick(2)
            this.saveModalChanges(this.selectedTemplate);
        }
    },
    computed:{
        isDev() {
            return process.env.VUE_APP_Environment === "dev";
        },
    }
}
</script>

<style>
.tsukiji-prod-template-modal {
    width: 100%;
    height: 100px;
    column-gap: 15px;
}

/** UnSelected Class */
.tsukiji-prod-template-box-not {
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    background: #F4F7FD;
}

.tsukiji-prod-template-text-not {
    width: 100%;
    height: 100%;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 114%;
}

/** Selected Class**/
.tsukiji-prod-template-box {
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    width: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid #5a4fdc;
    background: #F4F7FD;
}

.template-text {
    font-size: 114%;
    width: 100%;
    height: 100%;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5a4fdc;
}
</style>