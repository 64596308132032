<template>
    <div style="margin-left: 15px;">
        <div class="d-flex justify-content-between tsukiji-prod-temp-header tsukiji-prod-page-header py-4">
            <h3 class="tsukiji-prod-page-title" id="Widgets-title"><b>Widgets</b></h3>
            <div>
                <button class="btn btn-danger rounded-4" role="button" @click="$refs.openModal.click()"
                    style="margin-right: 25px !important;">Add
                    widget</button>
            </div>
        </div>

        <div class="alert alert-light-primary" role="alert">
            <i class="bi bi-info-circle"></i> <span class="ms-1">widgets ...</span>
        </div>

        <button type="button" class="btn btn-info d-none" data-bs-toggle="modal" ref="openModal"
            data-bs-target="#exampleModal">
            Launch demo modal
        </button>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add Widget</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3">
                        <div class="row">
                            <div class="col-6">
                                <label for="end-date" class="form-label">Name</label>
                                <input type="text" class="form-control" v-model="newWidget.name" required
                                    placeholder="Enter Widget Name" />
                            </div>
                            <div class="col-6">
                                <label for="email" class="form-label"> Type</label>
                                <select class="form-select" id="email" name="email" v-model="newWidget.type" required>
                                    <option value="NewsLetter">News Letter</option>
                                    <option value="PromotionBanner">Promotion Banner</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label for="email" class="form-label mt-3"> Leads Group</label>
                                <select class="form-select" id="email" name="email" v-model="newWidget.groupId"
                                    required>
                                    <option v-for="(option, index) in groupList" :key="index" :value="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger rounded-4" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-info rounded-4"
                            v-if="newWidget.name && newWidget.type && newWidget.groupId"
                            @click="setNewWidget(newWidget)">Save
                            changes</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-left: 9px !important;">
            <div v-for="(item, index) in widgets" :key="index" class="col-6">
                <EditNl v-if="item.type.toUpperCase() == 'NEWSLETTER'" :updateWidget="updateWidget"
                    :deleteWidget="deleteWidget" :widget="item" :saveWidget="saveWidget" :apiKey="apiKey"
                    :editWidget="saveEditedWidget" :index="index" class="card col-lg-6" />
                <EditPb v-if="item.type.toUpperCase() == 'PROMOTIONBANNER'" :updateWidget="updateWidget"
                    :deleteWidget="deleteWidget" :widget="item" :saveWidget="saveWidget" :apiKey="apiKey"
                    :editWidget="saveEditedWidget" :index="index" class="card col-lg-4" />
            </div>
        </div>


    </div>
</template>

<script>
import {apiService} from "@/services/apiService/apiService";
import EditNl from "./Templates/Newsletter/EditNl.vue"
import EditPb from "./Templates/PromoBanner/EditPb.vue"
import { pbConfig } from "./Templates/PromoBanner/pbTemplate";
import { nlConfig } from "./Templates/Newsletter/nlTemplate";


export default {
    name: "Widgets-vue",
    data() {
        return {
            showNl: false,
            showPb: false,
            nlConfig: nlConfig,
            pbConfig: pbConfig,
            groupList: [],
            widgets: [],
            newWidget: {
                name: "",
                type: "NewsLetter",
                groupId: null
            }
        }
    },
    components: {
        EditNl, EditPb
    },
    methods: {
        setNewWidget(newWidget) {
            this.widgets.push({
                id: null,
                name: newWidget.name,
                type: newWidget.type,
                config: newWidget.type.toUpperCase() == 'NEWSLETTER' ? nlConfig : pbConfig,
                leadsGroup: newWidget.groupId,
                saved: false,
                widget_url: ""
            })
            this.newWidget = { name: "", type: "", groupId: null };
            this.$refs.closeModal.click()
        },
        saveEditedWidget(newConfig, index) {
            console.log("newConfig : saving Stage", newConfig, index)
            if (newConfig && index) this.widgets[index].config = newConfig;
        },

        saveWidget(obj, index) {
            console.log("index", index)
            this.$store.state.isLoading = true
            apiService.createWidgets(obj).then(() => {
                this.$store.state.toastMessage = "Widget Added  Successfully";
            }).catch((error) => {
                const errorMessage = { ...error };
                this.$store.state.toastMessage =
                    errorMessage.response.data.message || errorMessage.response.data;
            }).finally(() => {
                this.$store.state.isLoading = false;
                this.widgets.splice(index, 1);
                this.widgets = this.widgets.filter((item) => item.saved != true);
                this.getWidget();
            })
        },
        updateWidget(obj, id) {
            this.$store.state.isLoading = true
            apiService.updateWidget(obj, id).then(() => {
                this.$store.state.toastMessage = "Widget Updated  Successfully";
            }).catch((error) => {
                const errorMessage = { ...error };
                this.$store.state.toastMessage =
                    errorMessage.response.data.message || errorMessage.response.data;
            }).finally(() => {
                this.$store.state.isLoading = false;
                this.getWidget();
            })
        },
        deleteWidget(id) {
            this.$store.state.isLoading = true
            apiService.deleteWidget(id).then(() => {
                this.$store.state.toastMessage = "Widget Deleted  Successfully";
            }).catch((error) => {
                const errorMessage = { ...error };
                this.$store.state.toastMessage =
                    errorMessage.response.data.message || errorMessage.response.data;
            }).finally(() => {
                this.$store.state.isLoading = false;
                this.getWidget();
            })
        },
        getWidget() {
            this.$store.state.isLoading = true
            this.widgets = [];
            apiService.getWidgets().then((res) => {
                this.addOldWidgets(res.data)
            }).catch((error) => {
                // Handle error here, for example:
                console.error('Error fetching widgets:', error);
            }).finally(() => {
                this.$store.state.isLoading = false
            })
        },

        // Functions for setting the Data
        addOldWidgets(data) {
            console.log('res.data', data);
            this.testLoading = true
            Object.keys(data.All_widgets).forEach((key) => {
                const item = data.All_widgets[key];
                this.setOldWidgetItem(item);
            });
            this.testLoading = false
        },
        async setOldWidgetItem(item) {
            try {
                const widgetConfig = await apiService.getWidgetData(item.config_url);
                if (widgetConfig) {
                    const obj = {
                        id: item.id,
                        name: item.name,
                        type: item.tag,
                        config: widgetConfig.data,
                        saved: true,
                        leadsGroup: null,      // Might be changes in future
                        widget_url: item.widget_url
                    }
                    this.widgets.push(obj);
                }
            } catch (error) { console.error('Error fetching widget data:', error) }
        },
        getGroups() {
            apiService.getGroups()
                .then((res) => {
                    const leadGroup = res.data.filter(
                        (item) => item.leadsCount > 0 && item.status === "ACTIVE"
                    );
                    this.groupList = apiService.formatOptionsWithKeyValue(
                        leadGroup,
                        "Group",
                        "name",
                        "uuid"
                    );
                    console.log(" => this.groupList: ", this.groupList);
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
    },
    mounted() {
        this.getWidget();
        this.getGroups();
    }
}
</script>
<style>
.alert-light-primary{

    font-size: 15px;
    margin: 0px 17px 0px 17px;

}
.col-6 {
    flex: 0 0 auto;
    width: 49.5%;
}
.tsukiji-prod-page-title{
    margin-left: 17px !important;
}

</style>
