<template>
    <div class="container-fluid main-content-container pb-4">
        <div no-gutters class="tsukiji-prod-content-align d-flex tsukiji-prod-page-header py-4">
            <h3 class="tsukiji-prod-tsukiji-prod-page-title">
                <i class="bi bi-arrow-left" @click="backToLeads" aria-hidden="true"></i>&nbsp; {{ groupName }}
            </h3>
            <span class="spacer"></span>
            <button class=" btn rounded-4 btn-danger" @click="navigateTo('new')" style="position:absolute ; right: 20px !important;">
                Create Leads
            </button>
        </div>
        <!-- List Templates -->
        <div class="card">
            <VueGoodTable mode="remote" v-on:page-change="onPageChange" v-on:per-page-change="onPerPageChange"
                :totalRows="totalRecords" :pagination-options="{
                    enabled: true,
                    perPageDropdownEnabled: false,
                    setCurrentPage: currentPage,
                }" :sort-options="{ enabled: false }" :rows="rows" :columns="columns" class="content-align"
                @on-pagination-change="onPaginationChange">
                <template #table-row="props">
                    <span v-if="props.column.field == 'group'">
                        {{ props.row.group.name }}
                    </span>
                    <span v-if="props.column.field == 'name'">
                        {{ props.row.firstName }} {{ props.row.lastName }}
                    </span>
                    <span v-if="props.column.field == 'action'" class="d-flex">
                        <span class="fa bi  bi-pencil-fill" @click="navigateTo(props.row.id)"></span>&nbsp;&nbsp;
                        <span class="fa bi bi-trash-fill " style="color:red"
                            @click="deleteLead(props.row.id, props.row.firstName.concat(' ', props.row.lastName))"></span>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </VueGoodTable>
        </div>
    </div>
</template>

<script>
import {apiService} from "@/services/apiService/apiService";
import { VueGoodTable } from "vue-good-table-next";
import SweetAlert from "@/services/sweetAlerts/SweetAlerts"



export default {
    name: "Leads-Info",
    components: {
        VueGoodTable
    },
    data() {
        return {
            groupId: null,
            totalRecords: 0,
            currentPage: 1,
            groupName: null,
            columns: [
                {
                    label: "S No.",
                    field: "SNo",
                },
                {
                    label: "Group",
                    field: "group.name",
                },
                {
                    label: "Name",
                    field: "name",
                },
                {
                    label: "Email",
                    field: "email",
                },
                {
                    label: "Phone Number",
                    field: "phoneNumber",
                },
                {
                    label: "Status",
                    field: "group.status",
                },
                {
                    label: "Action",
                    field: "action",
                },
            ],
            rows: [],
            serverParams: {
                page: 1,
                perPage: 10,
            },
        };
    },
    methods: {
        getCustomers(groupId) {
            this.$store.state.isLoading = true;
            apiService.getCustomers(groupId, this.serverParams.page)
                .then((res) => {
                    this.currentPage = this.serverParams.page;
                    this.$store.state.isLoading = false;
                    this.$store.state.customers = res.data.customers;
                    this.totalRecords = res.data.totalCount;
                    this.rows = apiService.itemsWithIndex(this.$store.state.customers, this.serverParams.page - 1, 10);
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        navigateTo(id) {
            const currentPage = this.$route.query.page || 1;
            this.$router.push({
                name: "addUpdateLeads",
                params: { group: this.groupId, groupName: this.groupName, id: id }, query: { page: currentPage }
            });
        },
        backToLeads() {
            const leadsGroupPage = parseInt(this.$route.query.leadsGroupPage) || 1;
            this.$router.push({ name: "Leads", query: { leadsGroupPage: leadsGroupPage } });
        },
        deleteLead(id, fullName) {
            SweetAlert.Confirmation({ title: `Are you sure want to delete lead ${fullName} ? ` }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.state.isLoading = true;
                    apiService.deleteCustomers(id)
                        .then(() => {
                            this.$store.state.isLoading = false;
                            this.$store.state.toastMessage = "Deleted successfully!";
                            this.getCustomers(this.groupId);
                        })
                        .catch((err) => {
                            const error = { ...err };
                            this.$store.state.toastMessage = error?.response?.data || "Something went wrong!";
                        })
                        .finally(() => {
                            this.$store.state.toastMessage = "";
                            this.$store.state.isLoading = false;
                        });
                }
            });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.isLoading = false;
            this.getCustomers(this.groupId);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            const currentPage = params.currentPage;
            const leadsGroupPage = parseInt(this.$route.query.leadsGroupPage) || 1;
            this.$router.push({ name: "leads-group", query: { leadsGroupPage: leadsGroupPage, page: currentPage } });
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            const currentPage = this.currentPage;
            const leadsGroupPage = parseInt(this.$route.query.leadsGroupPage) || 1;
            this.$router.push({ name: "leads-group", query: { leadsGroupPage: leadsGroupPage, page: currentPage } });
        },
    },

    created() {
        const { group, groupName } = this.$route.params;
        const { page } = this.$route.query;
        if (group && group !== "new") {
            this.groupId = group;
            this.groupName = groupName;
            this.serverParams.page = page || 1;
            this.currentPage = parseInt(page) || 1;
            this.getCustomers(this.groupId);
        }
    },
};
</script>

<style scoped>
.tsukiji-prod-content-align {
    margin: 0 15px;
}


.leads_button {
    margin: 0 0.5rem;
}

.fa {
    cursor: pointer;
}

::deep .v-client-table label {
    display: none !important;
}

::deep .v-client-table .form-group {
    padding: 10px 0px !important;
}

/* Don't forget to hide the original file input! */
.file-select>input[type="file"] {
    display: none;
}

.tsukiji-prod-tsukiji-prod-page-title {
    text-transform: capitalize;
}
::v-deep table.vgt-table {
    background-color: transparent !important;
}

::v-deep .vgt-wrap__footer,
::v-deep .vgt-table thead th {
    background: transparent !important;
}
</style>
