<template>
    <div class="modal fade" :class="{ show: showModal, 'd-block': showModal }" id="myModal">
        <div class=" modal-dialog modal-dialog-centered modal-lg">
            <div class=" modal-content">
                <div class="card modal-header">
                    <h5 class="modal-title">Add Image</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="handleClose"></button>
                </div>
                <div class="card modal-body">
                    <!-- step 1 -->
                    <div v-if="activeTab === 'existing' && step == 1" class="row col-12 m-0 p-0">
                        <div class="w-100 text-center">
                            <button class="btn rounded-4 tsukiji-prod-upload-button" @click="onPickFile">Upload
                                Image</button>
                            <input type="file" style="display: none" ref="fileInput" accept="image/*"
                                @change="onFilePicked" />
                        </div>
                        <div class="w-100 text-center my-2">
                            <div class="btn-group flex-wrap">
                                <button class="btn rounded-4 btn-light" v-for="item in imageUploadedDate"
                                    :class="{ 'btn-primary text-white': activeImageDate === item }"
                                    @click="getImagesByDate(item)" :key="item">{{ item }}</button>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap mt-2">
                            <div v-for="image in uploadedImages" :key="image">
                                <img style="max-width: 200px; max-height: 200px; border: 2px solid transparent;"
                                    @click="selectedImage = image" class="rounded"
                                    :class="{ 'tsukiji-prod-image-selected': selectedImage === image }"
                                    :src="'https://' + image" />
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-end mt-2" v-if="step == 1 && selectedImage">
                            <button v-if="selectedImage" class="btn rounded-4 btn-primary"
                                @click="step = 2">Next</button>
                        </div>
                    </div>

                    <!-- Step 2 -->
                    <div v-if="activeTab === 'existing' && step == 2 && selectedImage" class="row col-12 m-0 p-0">
                        <div class="w-100 text-center fw-medium mb-3">Image Properties</div>
                        <div class="row m-0" style="row-gap: 10px;">
                            <div class="col-6">
                                <label class="form-label">Width</label>
                                <input type="number" class="form-control" placeholder="Enter Width"
                                    v-model="imgProperties['width']">
                            </div>
                            <!-- Other input fields -->
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                            <button v-if="selectedImage" class="btn rounded-4 btn-secondary"
                                @click="step = 1">Back</button>
                            <button v-if="selectedImage" class="btn rounded-4 btn-primary mx-2"
                                @click="setImageToEditor(selectedImage, imgProperties)">Submit</button>
                        </div>
                    </div>

                    <!-- New Tab Content -->
                    <div v-if="activeTab === 'new'" class="row col-12 m-0 p-0">
                        <form class="w-100">
                            <div class="tsukiji-prod-dropbox">
                                <input type="file" multiple @change="filesChange($event.target.files)" accept="image/*"
                                    class="tsukiji-prod-input-file" />
                                <p>Drag your file(s) here to begin<br />or click to browse</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { apiService } from '@/services/apiService/apiService';
export default {
    props: ['activeTab', 'handleClose', 'setImageToEditor', 'showModal'],
    new: "",
    data() {
        return {
            imageUploadedDate: [],
            uploadedImages: [],
            selectedImage: "",
            step: 1,
            imgProperties: {}
        }
    },
    methods: {
        onPickFile() {
            this.$refs.fileInput.click();
        },
        onFilePicked(event) {
            const files = event.target.files;
            console.log(files);
            this.uploadImage(files[0]);
        },
        async uploadImage(file) {
            this.$store.state.isLoading = true;
            const extension = (file.name && file.name.split(".").pop()) || "png";
            const preAssUrlInfo = await this.getPreAssignedUrlToUploadImg(extension);
            await this.uploadImageToS3(preAssUrlInfo, file);
            this.$store.state.isLoading = false;
            this.getDatesForImage();
        },
        getPreAssignedUrlToUploadImg(ext) {
            return new Promise((resolve, reject) => {
                try {
                    apiService.getPreImgUrl(ext).then((res) => {
                        resolve(res.data);
                    });
                } catch (e) {
                    reject(e);
                }
            });
        },
        uploadImageToS3(preAssUrlInfo, file) {
            return new Promise((resolve, reject) => {
                try {
                    var data = new FormData();
                    data.append("Content-Type", preAssUrlInfo.fields["Content-Type"]);
                    data.append("key", preAssUrlInfo.fields["key"]);
                    data.append("AWSAccessKeyId", preAssUrlInfo.fields["AWSAccessKeyId"]);
                    data.append(
                        "x-amz-security-token",
                        preAssUrlInfo.fields["x-amz-security-token"]
                    );
                    data.append("policy", preAssUrlInfo.fields["policy"]);
                    data.append("signature", preAssUrlInfo.fields["signature"]);
                    data.append("file", file);
                    apiService.uploadImageToS3(preAssUrlInfo.url, data).then((res) => {
                        resolve(res);
                    });
                } catch (e) {
                    reject(e);
                }
            });
        },
        getDatesForImage() {
            this.$store.state.isLoading = true;
            apiService.getDatesForTheImage().then((res) => {
                this.imageUploadedDate = res.data;
                this.$store.state.isLoading = false;
                this.imageUploadedDate.forEach((value, index) => {
                    if (index == this.imageUploadedDate.length - 1) {
                        this.getImagesByDate(value);
                    }
                })
            }).catch((e) => {
                console.log(e)
            });
        },
        getImagesByDate(date) {
            this.activeImageDate = date;
            this.$store.state.isLoading = true;
            apiService.getImagesByDate(date.replaceAll("/", "-"))
                .then((res) => {
                    this.uploadedImages = res.data;
                })
                .catch((error) => {
                    console.error('Error fetching images:', error);

                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        }

    },
    mounted() {
        this.getDatesForImage()
    }
}
</script>

<style>
.tsukiji-prod-dropbox {
    outline: 2px dashed grey;
    /* the dash box */
    outline-offset: -10px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    /* minimum height */
    position: relative;
    cursor: pointer;
}

.tsukiji-prod-input-file {
    opacity: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    cursor: pointer;
}

.tsukiji-prod-dropbox:hover {
    background: lightblue;
    /* when mouse over to the drop zone, change color */
}

.tsukiji-prod-dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

.custom-input {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-input:not(:hover):not(:focus) {
    border-color: transparent;
}

.tsukiji-prod-image-selected {
    border-color: #dd6b10 !important;
}


.tsukiji-prod-upload-button {
    background-color: #ed8b3c !important;
    border-color: #ed8b3c !important;
}

:deep(.cke_contents) {
    height: calc(100vh - 480px) !important;
}
</style>
