<template>
    <li  :class="{'menu':!dropdown}">
      <template v-if="item.children && item.children.length">
        <a v-if="!dropdown" class="dropdown-toggle" data-bs-toggle="collapse" :data-bs-target="'#'+item.name" :aria-controls="item.name" aria-expanded="false">
            <div class="d-flex align-items-center" >
                <i class="bi me-4"  :class="item.icon" style="font-size: 18px;"></i>
                <span>{{ $t(item.name) }}</span>
            </div>
            <div>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
            </div>
          </a>
            <a v-else class="dropdown-toggle" :href="'#' + item.name"  :data-bs-parent="'#'+parentItem"   data-bs-toggle="collapse" role="button" aria-expanded="false">
                {{ $t(item.name) }}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                >
                    <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
            </a>
            <ul :id="item.name" class="collapse  list-unstyled" :class="{'submenu':!dropdown,'sub-submenu':dropdown}">
              <li v-for="(child, index) in item.children" :key="index">
                    <router-link v-if="!child.children" :to="child.path" >{{ child.name}}</router-link>
                    <SidebarItem  v-else :item="child" :dropdown="true" :parentItem="parentItem || item.name"/>
              </li>
            </ul>
      </template>
      <template v-else>
        <router-link :to="item.path" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="d-flex align-items-center">
                <i class="bi me-4" :class="item.icon" style="font-size: 18px;"></i>
                <span>{{ $t(item.name) }}</span>
            </div>
        </router-link>
      </template>
    </li>
  </template>
  
  <script>
  export default {
    name: 'SidebarItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
      dropdown: {
        type: Boolean,
        default: false,
      },
      parentItem: {
        type: String,
        default: "",
      },
    },
    methods: {
      toggleMobileMenu() {
        // Your toggleMobileMenu method here
      },
    },
  };
  </script>
  