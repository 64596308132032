import api from "./api";
import { Auth } from "aws-amplify";
import Store from "@/store";
import axios from "axios"
class APIService {
    url = process.env.VUE_APP_ROOT_API;

    itemsWithSerialIndex(array, page) {
        console.log("page value", page);
        const serialVal = (page !== 1 ? Number((page - 1) + "1") : page);
        return array.map((items, SNo) => ({
            ...items,
            SNo: SNo + serialVal,
        }));
    }

    itemsWithIndex(array, currentPage = 0, pageSize = 10) {
        return array.map((items, SNo) => ({
            ...items,
            SNo: SNo + 1 + (currentPage * pageSize),
        }));
    }


    objectItemWithIndex(data, currentPage = 0, pageSize = 10) {
        const array = [];
        for (let i in data) {
            data[i]["id"] = i;
            array.push(data[i]);
        }
        return this.itemsWithIndex(array, currentPage, pageSize);
    }

    objectItemIndex(data, currentPage = 0, pageSize = 10) {
        const array = [];
        for (let i in data) {
            data[i]["index"] = i;
            array.push(data[i]);
        }
        return this.itemsWithIndex(array, currentPage, pageSize);
    }

    formateDate(arr, key) {
        arr.map((item) => {
            item[key] = item[key].substring(0, 10);
        });
        return arr;
    }

    formatOptions(array, name) {
        const options = [];
        const defaultValue = {
            value: null,
            text: "Select " + name,
        };
        options.push(defaultValue);
        array &&
            array.map((item) => {
                const data = {
                    value: item.id,
                    text: item.name,
                };
                options.push(data);
            });
        return options;
    }

    formatOptionsWithKey(array, name, key) {
        const options = [];
        const defaultValue = {
            value: null,
            text: "Select " + name,
        };
        options.push(defaultValue);
        array &&
            array.map((item) => {
                const data = {
                    value: item[key],
                    text: item[key],
                };
                options.push(data);
            });
        return options;
    }

    formatOptionsWithKeyValue(array = [], name, key, value) {
        const options = [];
        const defaultValue = {
            value: null,
            text: "Select " + name,
        };
        options.push(defaultValue);
        array.map((item) => {
            const data = {
                value: item[value],
                text: item[key],
            };
            options.push(data);
        });
        return options;
    }

    // create id with number of digit
    makeId(length) {
        let result = "";
        let characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    }

    // get formatted date
    getFormattedDate(date) {
        date = new Date(date);
        let dd = date.getDate();

        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }

        if (mm < 10) {
            mm = "0" + mm;
        }
        date = yyyy + "-" + mm + "-" + dd;
        return date;
    }

    // get next date with option of how many next
    getNextDay(next) {
        let currentDate = new Date();
        return this.getFormattedDate(
            currentDate.setDate(currentDate.getDate() + next)
        );
    }

    async getJwtToken() {
        return await Auth.currentSession().then(
            (data) => data.idToken.jwtToken
        );
    }

    tokenCheck() {
        if (!Store.getters.jwtToken) {
            Store.dispatch("fetchJwtToken")
                .then((info) => {
                    console.log(" => info from the api: ", info);
                })
                .catch((err) => console.log(" => error from api : ", err));
        }
    }

    async oldGetHeaders() {
        const jwt = await this.getJwtToken();
        const config = {
            headers: {
                'content-Type': 'application/json',
                "authorizationToken": jwt
            }
        }
        return config;
    }

    getCustomHeaders() {
        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };
        return config;
    }

    getCustomHeadersWithToken() {
        this.tokenCheck();
        const config = {
            headers: {
                authorizationToken: Store.getters.jwtToken,
            },
        };
        return config;
    }

    getHeaders() {
        this.tokenCheck();
        const config = {
            headers: {
                "content-Type": "application/json",
                authorizationToken: Store.getters.jwtToken,
            },
        };
        return config;
    }

    getFormDataHeaders() {
        this.tokenCheck();
        const config = {
            headers: {
                "content-Type": "multipart/form-data",
                authorizationToken: Store.getters.jwtToken,
            },
        };
        return config;
    }

    async getDashboard(filterVal = null) {
        const config = await this.oldGetHeaders();
        if (filterVal !== null && filterVal?.startTime) {
            return api.get(`dashboard?startTime=${filterVal.startTime}&endTime=${new Date().toISOString()}`, config);
        }
        else return api.get("dashboard", config);
    }

    async getBalanceUser() {
        const config = await this.oldGetHeaders();
        return api.get("payment/getbalance-user", config);
    }

    getCampaignsStats(cid, paginator) {
        const config = this.getHeaders();
        return api.get(
            `campaigns/stats?cid=${cid}&paginator=${paginator}`,
            config
        );
    }

    getCampaigns(page, active, progress, pc, comp, terminated) {
        const config = this.getHeaders();
        return api.get(
            "campaigns?paginator=" +
            (page - 1) +
            "&active=" +
            active +
            "&progress=" +
            progress +
            "&pc=" +
            pc +
            "&comp=" +
            comp +
            "&terminated=" +
            terminated,
            config
        );
    }

    createCampaigns(body) {
        const config = this.getHeaders();
        return api.post("campaigns/unary", body, config);
    }

    getCampaignsById(id) {
        const config = this.getHeaders();
        return api.get("campaigns/unary?cid=" + id, config);
    }

    updateCampaigns(body) {
        const config = this.getHeaders();
        return api.put("campaigns/unary", body, config);
    }

    deleteCampaigns(id) {
        const config = this.getHeaders();
        return api.delete("campaigns/unary?cid=" + id, config);
    }

    getCustomerGroups() {
        const config = this.getHeaders();
        return api.get("customergroup", config);
    }

    createCustomerGroups(body) {
        const config = this.getHeaders();
        return api.post("customergroup", body, config);
    }

    updateCustomerGroups(body) {
        const config = this.getHeaders();
        return api.put("customergroup/" + body.id, body, config);
    }

    deleteCustomerGroups(id) {
        const config = this.getHeaders();
        return api.delete("customergroup/" + id, config);
    }

    getCustomers(groupId, page) {
        const config = this.getHeaders();
        if (groupId) {
            return api.get(
                "customers?groupId=" + groupId + "&page=" + page,
                config
            );
        } else {
            return api.get("customers?page=" + page, config);
        }
    }

    getCustomerById(id) {
        const config = this.getHeaders();
        return api.get("customers?id=" + id, config);
    }

    createCustomers(body) {
        const config = this.getHeaders();
        return api.post("customers", body, config);
    }

    updateCustomers(body) {
        const config = this.getHeaders();
        return api.put("customers", body, config);
    }

    deleteCustomers(id) {
        const config = this.getHeaders();
        return api.delete("customers/" + id, config);
    }

    getTemplates(number) {
        const config = this.getHeaders();
        if (number > -1) {
            return api.get("templates?paginator=" + number, config);
        } else {
            return api.get("templates/list", config);
        }
    }

    async loadGlobalTemplate() {
        const config = this.getHeaders();
        return api.get("templates-global", config);
    }

    importUser(formData) {
        const config = this.getFormDataHeaders();
        return api.post("importUsers/", formData, config);
    }

    getGroups() {
        const config = this.getHeaders();
        return api.get("customergroup/", config);
    }

    saveCampaignTemplate(body) {
        const config = this.getHeaders();
        return api.post("campaign-storage", body, config);
    }

    getCampaignTemplate(bucketId) {
        const config = this.getHeaders();
        return api.get("campaign-storage?template_id=" + bucketId, config);
    }

    getUrlInfo(url) {
        return api.get(url);
    }

    updateCampaignTemplate(body) {
        const config = this.getHeaders();
        return api.put("campaign-storage/" + body.template_id, body, config);
    }

    deleteCampaignTemplate(body) {
        const config = this.getHeaders();
        return api.delete("campaign-storage/" + body.template_id, config);
    }

    getPreAssignedUrl(name, thumb_url) {
        const config = this.getHeaders();
        return api.get(
            "storagefacility?template_name=" + name + "&thumb_url=" + thumb_url,
            config
        );
    }

    gePutPreAssignedUrl(id, thumb_url) {
        const config = this.getHeaders();
        return api.get(
            "storagefacility/updatetemplate?template_id=" +
            id +
            "&thumb_url=" +
            thumb_url,
            config
        );
    }

    uploadCustomDocument(url, formData) {
        const config = {
            headers: {
                "Content-Type": "application/xml",
            },
        };
        return api.post(url, formData, config);
    }

    // identity management
    addEmailForVerification(body) {
        const config = this.getHeaders();
        return api.post("identitymanagement", body, config);
    }

    identityManagement() {
        const config = this.getHeaders();
        return api.get("identitymanagement", config);
    }

    deleteEmailIdentification(email) {
        const config = this.getHeaders();
        return api.delete("identitymanagement?email=" + email, config);
    }

    refreshEmailIdentification(email) {
        const config = this.getHeaders();
        return api.put("identitymanagement", { email }, config);
    }

    getStripeCards(body) {
        const config = this.getHeaders();
        return api.post("payment/stripe-methods", body, config);
    }

    addStripeCard(body) {
        const config = this.getHeaders();
        return api.put("payment/stripe-methods", body, config);
    }

    deleteStripeCard(body) {
        const config = this.getHeaders();
        return api.delete(
            "payment/stripe-methods?methodId=" + body.methodId,
            config
        );
    }

    updateStripeCard(body) {
        const config = this.getHeaders();
        return api.put("payment/update-card", body, config);
    }

    getPreImgUrl(ext) {
        const config = this.getHeaders();
        return api.get("cloudfront-image-upload?extension=" + ext, config);
    }

    uploadImageToS3(url, formdata) {
        return api.post(url, formdata);
    }

    getDatesForTheImage() {
        const config = this.getHeaders();
        return api.get("cloudfront-image-date", config);
    }

    getImagesByDate(date) {
        const config = this.getHeaders();
        return api.get(
            "cloudfront-image-date/imagefile?image_date=" + date,
            config
        );
    }

    getLeadsPreAssignedUrl() {
        const config = this.getHeaders();
        return api.get("leads", config);
    }

    getPlans() {
        const config = this.getCustomHeadersWithToken();
        return api.get("subscription-plans", config);
    }

    subscribePlan(body) {
        const config = this.getCustomHeadersWithToken();
        return api.post("payment/subscribe-stripe-plan", body, config);
    }

    async getCurrentPlan() {
        const config = await this.oldGetHeaders();
        return api.get("subscription-plans/user-current-plan", config);
    }

    cancelSubscription(body) {
        const config = this.getCustomHeadersWithToken();
        return api.post("payment/cancel-stripe-subscription", body, config);
    }

    getReceipts(paginator = 1) {
        const config = this.getHeaders();
        return api.get("all-receipts?paginator=" + (Number(paginator) - 1), config);
    }

    requestAnAccount(body) {
        const config = this.getCustomHeaders();
        return api.post("request-an-account", body, config);
    }
    contactUs(body) {
        return api.post("feedback", body);
    }

    updateFeedback(body) {
        const config = this.getHeaders();
        return api.post("feedback", body, config);
    }

    updateSupport(body) {
        const config = this.getHeaders();
        return api.post("support", body, config);
    }
    getFooterPresignedUrl() {
        const config = this.getHeaders();
        return api.get("profile", config);
    }
    updateUserProfile(body) {
        const config = this.getHeaders();
        return api.put("profile", body, config);
    }
    uploadFooterDetails(url, formData) {
        const config = this.getHeaders();
        return api.post(url, formData, config);
    }
    getFooterTemplate(url) {
        const config = this.getHeaders();
        return api.get(url, config);
    }

    getWidgets() {
        const config = this.getHeaders();
        return api.get("widgets", config);
    }

    createWidgets(body) {
        body.json_content = JSON.stringify(body.json_content);
        const config = this.getHeaders();
        return api.post(`widgets`, body, config);
    }

    updateWidget(body, id) {
        body.json_content = JSON.stringify(body.json_content);
        const config = this.getHeaders();
        return api.put(`widgets/${id}`, body, config);
    }

    deleteWidget(id) {
        const config = this.getHeaders();
        return api.delete(`widgets/${id}`, config);
    }

    getWidgetData(url) {
        return axios.get(url)
    }

    /**------------------------------------------------------
     * API KEY
     */
    getApiKey() {
        const config = this.getHeaders();
        return api.get("api-key", config);
    }

    createApiKey() {
        const config = this.getHeaders();
        return api.post("api-key", {}, config);
    }

    deleteApiKey() {
        const config = this.getHeaders();
        return api.delete("api-key", config);
    }

    connectApps(name) {
        const config = this.getHeaders();
        return api.post("app/authorize?app_name=" + name, {}, config);
    }

    importWithSalesForce() {
        const config = this.getHeaders();
        return api.get("leads/import?app_name=salesforce", config);
    }

    fetchLeadsData() {
        const config = this.getHeaders();
        return api.get("app", config);
    }

}
export const apiService = new APIService();
