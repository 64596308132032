<template>
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-primary d-none" ref="modalTarget" data-bs-toggle="modal"
        data-bs-target="#integrationModal">
        connect
    </button>

    <!-- Modal -->
    <div class="modal fade" id="integrationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Connect to {{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="this.formSubmitted = false"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="col" :class="{ 'mt-2': index !== 0 }" v-for="(item, index) in fieldsConfig" :key="index">
                            <label for="campaigns-name" class="form-label">{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value"
                                :class="{ 'is-invalid': formSubmitted && !item.value }" name="connectionName" required
                                placeholder="Specify the new connection Name" />
                            <div class="invalid-feedback">
                                Invalid {{ item.label }}
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        @click="this.formSubmitted = false">Close</button>
                    <button type="button" class="btn btn-primary" @click="saveConfig()">Connect</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import APIService from "../../../Services/ApiService/apiService";
export default {
    name: "configModal",
    data() {
        return {
            fieldsConfig: [],
            title: "",
            connectionName: "",
            clientId: "",
            clientSecret: "",
            accountId: "",
            formSubmitted: false
        }
    },
    components: {
    },
    methods: {
        openModal(integrationConfig) {
            this.title = integrationConfig.title;
            this.fieldsConfig = integrationConfig.fields;
            console.log("the fields config", this.fieldsConfig)
            this.$refs.modalTarget.click();
        },
        saveConfig() {
            this.formSubmitted = true;
            if (this.fieldsConfig.every(item => item.value)) {
                this.getFormattedConfig(this.fieldsConfig);
            }
        },
        getFormattedConfig(fieldsConfig) {
            console.log("fields config", fieldsConfig)
            let formattedConfig = {};
            fieldsConfig.map(item => {
                formattedConfig[item.label] = item.value;
            });
            console.log("formatted config", formattedConfig)
        }

    },
    mounted() {

    }
}
</script>
<style></style>