import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import {getRouterConfig} from './routerService/router.service';
import {checkUserAuthentication} from '../services/authService/authHelper';


const routes = getRouterConfig()

console.log("routes",routes)

const router = new createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});


function setLayout(value){
    if(store.getters.value != value){
        console.log("value",value)
        store.commit('setLayout', value);
    }
}

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth && store.getters.isAuthenticated) {
        setLayout("app");
        next();
    } else if (!to.meta.requiresAuth && store.getters.isAuthenticated) {
        setLayout("app");
        next("/");
    }
    const user = await checkUserAuthentication();
    console.log("userValue", typeof user);
    if (user && user.sub) {
        console.log("userValue ---final", user);
        store.commit("setIsAuthenticated", true);
        store.commit("setUser", user);
    }

    if (to.meta.requiresAuth && !user) {
        setLayout("auth");
        next("/login");
    } else if (!to.meta.requiresAuth && (user && user.sub)) {
        setLayout("app");
        next("/");
    } else if (!to.meta.requiresAuth && !user) {
        setLayout("auth");
        next();
    }
    next();
});

export default router;