<template>
    <div>
        <!-- Main content -->
        <div class="main-content tsukiji-prod-content-dashboard">
            <div class="main-content-container">
                <!-- Header -->
                <div class="d-flex justify-content-between pb-3 pt-4">
                    <h3 class="tsukiji-prod-page-title" style="text-align: left">
                        <b>Dashboard</b>
                    </h3>
                    <!-- Dropdown for filter -->
                    <div class="dropdown">
                        <button class="btn btn-danger dropdown-toggle rounded-4" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ statsFilter.type }}
                            <i class="bi bi-caret-down-fill"></i>
                        </button>
                        <!-- Dropdown menu -->
                        <ul class="dropdown-menu py-0 mt-3 rounded-md" aria-labelledby="dropdownMenuButton1">
                            <li v-for="(item, index) in statusFilterConstant" :key="index">
                                <a :class="{ 'btn-danger': statsFilter.type == item }"
                                    class="btn dropdown-item rounded-0 ">{{ item }}</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Charts section -->
                <div class="row pb-3">
                    <!-- Column chart for campaigns -->
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <!-- Loading effect -->
                                <div v-if="isLoading" class="d-flex justify-content-center"
                                    style="margin-top: 15px !important;">
                                    <div>
                                        <free-style-shimmer :is-loading="true" height="180px" width="180px"
                                            border-radius="90px" color="#bdbdbd" />
                                        <paragraph-shimmer class="mt-2" :is-loading="true" :lines="2"
                                            :random-size="true" />
                                    </div>
                                </div>
                                <!-- Column chart -->
                                <highCharts v-else :options="columnChartCampaigns" style="margin-top: 10px !important;">
                                </highCharts>
                                <!-- Icon if no data -->
                                <i v-if="!hasChartData" class="bi bi-column-chart-fill tsukiji-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <!-- Column chart for spam -->
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <!-- Loading effect -->
                                <div v-if="isLoading" class="d-flex justify-content-center"
                                    style="margin-top: 15px !important;">
                                    <div>
                                        <free-style-shimmer :is-loading="true" height="180px" width="180px"
                                            border-radius="90px" color="#bdbdbd" />
                                        <paragraph-shimmer class="mt-2" :is-loading="true" :lines="2"
                                            :random-size="true" />
                                    </div>
                                </div>
                                <!-- Column chart -->
                                <highCharts v-else :options="columnChartSpam" style="margin-top: 10px !important;">
                                </highCharts>
                                <!-- Icon if no data -->
                                <i v-if="!hasSpamData" class="bi bi-pie-column-fill tsukiji-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <!-- Column chart for bounce -->
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <!-- Loading effect -->
                                <div v-if="isLoading" class="d-flex justify-content-center"
                                    style="margin-top: 15px !important;">
                                    <div>
                                        <free-style-shimmer :is-loading="true" height="180px" width="180px"
                                            border-radius="90px" color="#bdbdbd" />
                                        <paragraph-shimmer class="mt-2" :is-loading="true" :lines="2"
                                            :random-size="true" />
                                    </div>
                                </div>
                                <!-- Column chart -->
                                <highCharts v-else :options="columnChartBounce" style="margin-top: 10px !important;">
                                </highCharts>
                                <!-- Icon if no data -->
                                <i v-if="!hasBounceData" class="bi bi-column-chart-fill tsukiji-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <!-- Button section -->
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <h5 class="card-title text-center mb-4 mt-2"><b>Campaigns Overview</b></h5>
                                <div class="d-grid gap-3">
                                    <button type="button" class="btn btn-primary btn-lg">
                                        <span class="bi bi-bar-chart me-2"></span> Recent Campaign
                                    </button>
                                    <button type="button" class="btn btn-success btn-lg">
                                        <span class="bi bi-graph-up-arrow me-2"></span> Most Popular Campaign
                                    </button>
                                    <button type="button" class="btn btn-danger btn-lg">
                                        <span class="bi bi-graph-down-arrow me-2"></span> Least Popular Campaign
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Modal -->
                    <div class="modal fade" id="newModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg tsukiji-prod-stats-modal-dialog">
                            <div class="modal-content" v-if="showStats">
                                <div class="modal-header">
                                    <div class="modal-title">
                                        <strong>{{ campaignStatsDetails.name }} Stats</strong>
                                    </div>
                                    <span>
                                        <!-- Button to switch modal content -->
                                        <button class="btn rounded-4 btn-primary mx-1" @click="modalSwitch()">
                                            <span class="bi bi-bar-chart-fill mx-1">
                                            </span>
                                            {{ modalBtnText }}
                                        </button>
                                        <!-- Close button -->
                                        <button type="button" class="btn-close m-0 pt-0" data-bs-dismiss="modal"
                                            aria-label="Close" @click="closeStats()"></button>
                                    </span>
                                </div>
                                <div class="modal-body">
                                    <!-- Conditional rendering of stats or graph -->
                                    <CampaignStats v-if="!showStatsGraph"
                                        :campaignStatsDetails="campaignStatsDetails" />
                                    <CampaignStatsGraph v-else :campaignStatsDetails="campaignStatsDetails" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Funnel chart -->
                    <div class="col-sm-12 col-md-6 my-3">
                        <div v-if="isLoading" class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <free-style-shimmer :is-loading="true" class="h-100" height="300px" width="100%"
                                    border-radius="50px" color="#bdbdbd" style="margin: 15px;" />
                            </div>
                        </div>
                        <div v-else class="card custom-new-card card-small h-100">
                            <div v-if="getStackedBarChartConfig" class="card-body">
                                <highCharts :options="funnelChart"></highCharts>
                            </div>
                        </div>
                    </div>
                    <!-- Line chart for campaigns -->
                    <div class="col-sm-12 col-md-6 my-3">
                        <div v-if="isLoading" class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <free-style-shimmer :is-loading="true" class="h-100" height="300px" width="100%"
                                    border-radius="50px" color="#bdbdbd" style="margin: 15px;" />
                            </div>
                        </div>
                        <div v-else class="card custom-new-card stats-small stats-small--1 card card-small h-100">
                            <div class="card-body" style="margin-top: 10px !important;">
                                <highCharts :options="lineCampaigns"></highCharts>
                                <i v-if="!hasLineChartData"
                                    class="bi bi-bar-chart-line-fill tsukiji-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <!-- Radar chart -->
                    <div class="col-sm-12 col-md-6 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <high-charts :options="radarChart"></high-charts>
                        </div>
                    </div>
                    <!-- Available Balance -->
                    <div class="col-sm-12 col-md-6 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <!-- Wallet info -->
                            <div class="wallet-info border-bottom text-center">
                                <span class="wallet-title mt-2" style="font-size: 18px; font-weight: 600px;">
                                    <b>Available Balance</b>
                                </span>
                                <p class="total-amount" style="font-size: 38px; font-weight: 600; color: #007bff;">
                                    93{{ this.$store.state.balance }}.88
                                </p>

                            </div>
                            <!-- Recent transactions -->
                            <ul class="list-group list-group-media" style="border: none !important;">
                                <li class="list-group-item" style="border: none !important;">
                                    <!-- Transaction details -->
                                    <div class="media mb-3">
                                        <div class="me-3">
                                            <img alt="avatar" src="../../assets/check.png"
                                                class="img-fluid rounded-circle" style="width: 42px; height: 42px;">
                                        </div>
                                        <div class="media-body">
                                            <p class="amount text-success mt-3" style="float: right;">₹
                                                {{ this.lastTransactionAmount }}99</p>
                                            <h6 class="tx-inverse">{{ this.lastTransaction.Status }}</h6>
                                            <p class="mg-b-0"> {{ this.lastTransactionpaymentDate }}Apr 16, 10:34
                                            </p>
                                        </div>
                                    </div>
                                    <!-- Second transaction -->
                                    <div class="media mb-3">
                                        <div class="me-3">
                                            <img alt="avatar" src="../../assets/check.png"
                                                class="img-fluid rounded-circle" style="width: 42px; height: 42px;">
                                        </div>
                                        <div class="media-body">
                                            <p class="amount text-success mt-3" style="float: right;">₹
                                                {{ this.lastTransactionAmount }}49</p>
                                            <h6 class="tx-inverse">{{ this.lastTransaction.Status }}</h6>
                                            <p class="mg-b-0"> {{ this.lastTransactionpaymentDate }}Apr 10, 12:34
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <!-- View all transactions button -->
                            <div class="row" style="margin: 20px; margin-top: 80px">
                                <router-link :to="{ name: 'transaction-history' }"
                                    class="btn btn-secondary w-100 mt-3 _effect--ripple waves-effect waves-light">View
                                    all Transactions</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ParagraphShimmer, FreeStyleShimmer } from 'vue3-shimmer'

NoDataToDisplay(Highcharts);

export default {
    name: "dashboard-vue",
    components: {
        highCharts: Chart,
        FreeStyleShimmer: FreeStyleShimmer,
        ParagraphShimmer: ParagraphShimmer,
    },
    data() {
        return {
            statsFilter: {
                type: 'All Time',
                startTime: null
            },
            statusFilterConstant: [
                'All Time', "Today", "Yesterday", "Last Week", "Last Month", "Last 6 Month", "Last Year", "Last 2 Year"
            ],
            stackedBarChart: {},
            dashboardData: {},
            latestCamp: "",
            latestTemplate: "",
            lastTransaction: "",
            availBalance: 0,
            numberOfCampaigns: 0,
            activeCampaign: {},
            campaignData: {},
            campaignStatsDetails: {},
            showStats: false,
            showStatsGraph: false,
            modalBtnText: "View Stats",
            funnelHasData: false,
            hasLineChartData: true,
            hasBounceData: true,
            hasSpamData: true,
            hasChartData: true,
            isLoading: true,

            lineCampaigns: {
                title: {
                    text: `<span><b>Your Campaigns States</b></span>`,
                    useHTML: true,
                    margin: 18,
                },
                credits: {
                    enabled: false
                },
                subtitle: {
                    text: "",
                },
                chart: {
                    height: "350px",

                },
                yAxis: {
                    visible: !this.hasLineChartData,
                    title: {
                        text: "Number of Campaigns",
                    },
                },
                xAxis: {
                    accessibility: {
                        rangeDescription: "Range: Week-1 to Week-4",
                    },
                    categories: ["Week-1", "Week-2", "Week-3", "Week-4"],
                    lineWidth: this.hasLineChartData ? 1 : 0,
                },
                legend: {
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "top",
                    enabled: !this.hasLineChartData,
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        color: "#4d80b4",
                    },
                },
                series: [{
                    name: "Number of Active Campaigns",
                    data: [] // Random data will be inserted here
                }],
                lang: {
                    noData: 'No Campaigns States Data'
                },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 200,
                            },
                            chartOptions: {
                                legend: {
                                    layout: "horizontal",
                                    align: "center",
                                    verticalAlign: "bottom",
                                },
                            },
                        },
                    ],
                },
            },

            columnChartCampaigns: {
                chart: {
                    chartBackground: "#f8f9fa",
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "column",
                    height: "250px",
                },
                title: {
                    text: `<span><b>Campaigns</b></span>`,
                    useHTML: true,
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: ['Active', 'In Progress', 'Completed']
                },
                yAxis: {
                    title: {
                        text: 'Percentage'
                    }
                },
                series: [{
                    name: "Campaigns",
                    colorByPoint: true,
                    data: [{
                        name: "Active",
                        y: null,
                        color: "#63ace5",  // Blue for Active
                    },
                    {
                        name: "In Progress",
                        y: null,
                        color: "#6fdcc6", // Green for In Progress
                    },
                    {
                        name: "Completed",
                        y: null,
                        color: "#7E7E7E", // Gray for Completed
                    },
                    ],
                },],
                lang: {
                    noData: 'No Active Campaigns Data'
                }
            },

            columnChartBounce: {
                chart: {
                    chartBackground: "#f8f9fa",
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "column",
                    height: "250px",
                },
                title: {
                    text: `<span><b>Bounce Rate</b></span>`,
                    useHTML: true,
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: ['Bounce Rate', 'Active Rate']
                },
                yAxis: {
                    title: {
                        text: 'Percentage'
                    }
                },
                series: [{
                    name: "Bounce",
                    colorByPoint: true,
                    data: [{
                        name: "Bounce Rate",
                        y: null,
                        color: "#6495ED", // Cornflower blue for Bounce Rate
                    },
                    {
                        name: "Active Rate",
                        y: null,
                        color: "#FFD700", // Gold for Active Rate
                    },
                    ],
                },],
                lang: {
                    noData: 'No Bounce Rate Data'
                }
            },

            columnChartSpam: {
                chart: {
                    chartBackground: "#f8f9fa",
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "column",
                    height: "250px",
                },
                title: {
                    text: `<span><b>Spam Rate</b></span>`,
                    useHTML: true,
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: ['Spam Rate', 'Active Rate']
                },
                yAxis: {
                    title: {
                        text: 'Percentage'
                    }
                },
                series: [{
                    name: "Spam",
                    colorByPoint: true,
                    data: [{
                        name: "Spam Rate",
                        y: null,
                        color: "#008000", // Dark green for Spam Rate
                    },
                    {
                        name: "Active Rate",
                        y: null,
                        color: "#0096FF", // Blue for Active Rate
                    },
                    ],
                },],
                lang: {
                    noData: 'No Spam Rate Data'
                }
            },

            radarChart: {
                chart: {
                    polar: true,
                    type: 'line',

                },
                title: {
                    text: "<span><b>Email Statistics</b></span>"
                },
                xAxis: {
                    categories: ['Sent Email', 'Opened Email', 'Bounce Rate', 'Spam Rate']
                },
                yAxis: {
                    title: {
                        text: 'Percentage'
                    }
                },
                plotOptions: {
                    series: {
                        color: "#4d80b4" // Set the line color here
                    }
                },
                series: [{
                    name: 'Statistics',
                    data: [70, 50, 10, 5], // Example values, representing Sent Email, Opened Email, Bounce Rate, Spam Rate respectively
                    pointPlacement: 'on'
                }],
                credits: {
                    enabled: false
                }
            }




        };
    },
    computed: {
        emailStatistics() {
            const hasEmailOpened = this.dashboardData.Email_Statistics && this.dashboardData.Email_Statistics.Latest_Campaign_Opened_Email;
            const hasEmailSent = this.dashboardData.Email_Statistics && this.dashboardData.Email_Statistics.Latest_Campaign_Sent_Email;
            return {
                hasEmailOpened,
                hasEmailSent,
                bounceRate: this.dashboardData.Bounce_Rate,
                spamRate: this.dashboardData.Spam_Rate
            };
        },
    },
    watch: {
        emailStatistics: {
            handler(newVal) {
                this.radarChart.series[0].data = [
                    newVal.hasEmailSent,
                    newVal.hasEmailOpened,
                    newVal.bounceRate,
                    newVal.spamRate
                ];
            },
            deep: true
        }
    },
    created() {
        // Generate random data when component is created
        this.generateRandomData();
    },
    methods: {
        generateRandomData() {
            // Simulate loading state
            this.isLoading = true;

            // Simulate random data for the charts
            this.lineCampaigns.series[0].data = Array.from({ length: 4 }, () => Math.floor(Math.random() * 100));
            this.columnChartCampaigns.series[0].data.forEach(item => item.y = Math.random() * 100);
            this.columnChartBounce.series[0].data.forEach(item => item.y = Math.random() * 100);
            this.columnChartSpam.series[0].data.forEach(item => item.y = Math.random() * 100);
            this.funnelChart = this.getStackedBarChartConfig({
                delivered: Math.random() * 100,
                unique_opens: Math.random() * 100,
                unique_clicks: Math.random() * 100,
                email_unsubscribed: Math.random() * 100,
            });

            // Simulate API response time
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        },
        getStackedBarChartConfig(data) {
            return {
                chart: {
                    type: "bar",
                },
                title: {
                    text: `<b>Stacked Bar Chart</b>`,
                    useHTML: true, // This allows the use of HTML in the title
                },
                xAxis: {
                    categories: ["Category1", "Category2", "Category3"] // Add your categories here
                },
                yAxis: {
                    title: {
                        text: 'Count'
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                    },
                },
                series: [
                    {
                        name: "Series1",
                        data: [10, 20, 30], // Sample data for Series1
                        color: "#0c244a",
                    },
                    {
                        name: "Series2",
                        data: [20, 30, 40], // Sample data for Series2
                        color: "#B0C4DE",
                    },
                    // Add more series as needed
                ],
                credits: {
                    enabled: false // Disable the Highcharts.com anchor tag
                }
            };
            // other methods...
        }
    }
};
</script>
<style scoped>
text.highcharts-credits {
    display: none;
}

.tsukiji-prod-headerColor {
    background: none !important;
}

.tsukiji-prod-headerColor h6 {
    font-size: 17px;
    color: #333333;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
        sans-serif;
}

.active-campaign {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 500;
}

.tsukiji-prod-content-dashboard {
    margin: 0 30px;
    min-height: calc(100vh - 113px) !important;
}

.highcharts-figure {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.btn-outline-primary {
    min-height: 45px;
    text-align: left;
    overflow: hidden;
}

.tsukiji-prod-stats-modal-dialog {
    min-width: 80vw !important;
}

.tsukiji-prod-content-dashboard .card-body {
    min-height: 120px;
    position: relative;
}

.highcharts-credits {
    display: none;
}

.tsukiji-prod-email-title {
    width: 250px;
}

.tsukiji-prod-template-title {
    width: 100px;
}

.tsukiji-prod-template-desc {
    margin-right: 10px;
}

.tsukiji-prod-chart-icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10em;
    color: rgb(0 0 0 / 4%);
}

.tsukiji-prod-no-funnel-data {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #666666;
}
.dropdown-item:hover{
    color: red !important;

}

@media (max-width: 767px) {
    .tsukiji-prod-template-mobile {
        padding-bottom: 2em;
    }
}

@media (max-width: 890px) {
    .tsukiji-prod-template-mobile {
        width: 100%;
        padding-bottom: 2em;
    }

    .tsukiji-prod-balance-mobile {
        width: 100%;
    }
}


.navigate-link {
    font-size: 14px !important;
    color: #5a4fdc !important;
}

.navigate-link:hover {
    transform: scale(1.1);
}

.stats-card {
    height: 54px !important;
    font-size: 15px;
}

.stats-card:hover {
    transform: translateY(-2px);
}

.bi-caret-down-fill {
    font-size: 10px;
    margin-top: 2px !important;
    margin-left: 2px;
}
::v-deep .card-body {
    padding: 12px !important;

}
</style>
