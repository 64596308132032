<template>
    <button :class="{ 'd-none': templateIcon }" class=" btn rounded-4 btn-outline-primary ms-3" id="query-template"
        ref="aiButton" data-bs-toggle="modal" data-bs-target="#ai-modal">
        Create Template With AI
    </button>

    <!-- Modal -->
    <div class="modal fade" id="ai-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Write prompt for Template</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="">
                        <!-- <label for="campaigns-name" class="form-label">Campaigns Name</label> -->
                        <textarea pe="text" id="campaigns-name" class="form-control" v-model="query"
                            name="campaignsName" :class="{ 'is-invalid': query.length <= 1 && editQuery }" required
                            placeholder="Enter Prompt" />
                        <div class="invalid-feedback">
                            No Text
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" ref="closeModal"
                        data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="savePrompt()">Done</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: "QueryModal",
    props: ['addAiTemplate', 'templateIcon'],
    data() {
        return {
            query: "",
            editQuery: false
        }
    },
    methods: {
        openModal() {
            this.$refs.aiButton.click()
        },
        savePrompt() {
            if (!this.editQuery) this.editQuery = true;
            if (this.query.length <= 1) return

            // here comes the above code
            this.$refs.closeModal.click()
            if (this.editQuery) this.editQuery = false
            this.addAiTemplate(this.query)
        }
    }
}
</script>