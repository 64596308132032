<template>
    <div class="container-fluid main-content-container pb-4">
        <div class="row align-items-center tsukiji-prod-page-header py-4">
            <h3 class="col tsukiji-prod-page-title">
                <i class="bi bi-arrow-left me-3" @click="backToLeads" aria-hidden="true"></i>
                Your All Leads
            </h3>
            <div class="col-auto">
                <button class=" btn rounded-4 btn-danger" @click="navigateTo('new')">
                    Create Leads
                </button>
            </div>
        </div>
        <!-- List Templates -->
        <div class="custom-new-card py-4 px-2">
            <VueGoodTable :columns="columns" :rows="rows" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: false,
                setCurrentPage: currentPage,
            }" :sort-options="{ enabled: false }" v-on:page-change="onPageChange"
                v-on:per-page-change="onPerPageChange" class="tsukiji-prod-content-align">

                <template #table-row="props">
                    <span v-if="props.column.label == 'group'" class="text-truncate">
                        {{ props.row.group.name }}
                    </span>
                    <span v-if="props.column.field == 'name'">
                        {{ props.row.firstName }} {{ props.row.lastName }}
                    </span>
                    <span v-if="props.column.field == 'action'" class="d-flex">
                        <span class="fa bi bi-pencil-fill" @click="navigateTo(props.row.id)"></span>&nbsp;&nbsp;
                        <span class="fa bi-trash-fill" style="color:red" @click="deleteLead(props.row.id)"></span>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </VueGoodTable>
        </div>
    </div>
</template>

<script>
import {apiService} from "@/services/apiService/apiService";
import { VueGoodTable } from "vue-good-table-next";
import SweetAlert from "@/services/sweetAlerts/SweetAlerts"
export default {
    components: {
        VueGoodTable
    },
    data() {
        return {
            isLoading: false,
            totalRecords: 0,
            currentPage: 1,
            columns: [
                {
                    label: "S No.",
                    field: "SNo",
                },
                {
                    label: "Group",
                    field: "group.name",
                },
                {
                    label: "Name",
                    field: "name",
                },
                {
                    label: "Email",
                    field: "email",
                },
                {
                    label: "Phone Number",
                    field: "phoneNumber",
                },
                {
                    label: "Status",
                    field: "group.status",
                },
                {
                    label: "Action",
                    field: "action",
                    width: "80px",
                },
            ],
            rows: [],
            serverParams: {
                page: 1,
                perPage: 10,
            },
        };
    },

    methods: {
        getCustomers() {
            this.$store.state.isLoading = true;
            apiService.getCustomers("", this.serverParams.page)
                .then((res) => {
                    this.currentPage = this.serverParams.page;
                    this.$store.state.isLoading = false;
                    this.$store.state.customers = res.data.customers;
                    this.totalRecords = res.data.totalCount;
                    console.log("rows", res.data.customers);
                    this.rows = apiService.itemsWithSerialIndex(this.$store.state.customers, this.currentPage);
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        navigateTo(id) {
            this.$router.push({
                name: "addUpdateLeads",
                params: { id: id, group: "123", groupName: "123" },
            });
        },
        backToLeads() {
            this.$router.push({ name: "Leads" });
        },
        deleteLead(id) {
            SweetAlert.Confirmation({ title: `Are you sure want to delete lead ${id} ? ` }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.state.isLoading = true;
                    apiService.deleteCustomers(id)
                        .then(() => {
                            this.$store.state.isLoading = false;
                            this.$store.state.toastMessage = "Deleted successfully!";
                            this.getCustomers();
                        })
                        .catch((err) => {
                            const error = { ...err };
                            this.$store.state.toastMessage = error?.response?.data || "Something went wrong!";
                        })
                        .finally(() => {
                            this.$store.state.toastMessage = "";
                            this.$store.state.isLoading = false;
                        });
                }
            });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.isLoading = false;
            this.getCustomers();
        },

        onPageChange(params) {
            console.log(" => it runs:");
            this.updateParams({ page: params.currentPage });
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
        },
    },

    created() {
        console.log(" => it runs : ");
        this.getCustomers();
    },
};
</script>
<style scoped>
.tsukiji-prod-content-align {
    margin: 0 15px;
}

.fa {
    cursor: pointer;
}

::deep .v-client-table label {
    display: none !important;
}

::deep .v-client-table .form-group {
    padding: 10px 0px !important;
}
::v-deep table.vgt-table {
    background-color: transparent !important;
}

::v-deep .vgt-wrap__footer,
::v-deep .vgt-table thead th {
    background: transparent !important;
}

</style>
