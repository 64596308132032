<template>
    <div class="container-fluid pb-4">
        <div class="tsukiji-prod-page-title" style="margin: 17px;">
            Tell us your issue, and we'll help you resolve it.
        </div>
        <div class="row m-0">
            <div class="col-sm-12 col-md-6">
                <div class="card custom-new-card p-3" style="max-height: 340px; margin-left: 9px;">
                    <form @submit.prevent="onSubmit">
                        <div class="mb-3 row">
                            <label for="f3_Issue" class="col-sm-3 col-form-label">Issue type</label>
                            <div class="col-sm-9">
                                <select class="form-select" id="f3_Issue" v-model="form.issue_type"
                                    aria-label="Select issue type">
                                    <option selected disabled>--Select--</option>
                                    <option value="billing">Billing</option>
                                    <option value="technical">Technical</option>
                                    <option value="subscription">Subscription</option>
                                    <option value="general_question">General Question</option>
                                </select>
                            </div>
                        </div>

                        <div class="mb-3 row">
                            <label for="f3_Descritpion" class="col-sm-3 col-form-label">Description</label>
                            <div class="col-sm-9">
                                <textarea id="f3_Descritpion" class="form-control" v-model="form.description"
                                    placeholder="Enter description" :rows="5" :max-rows="6" required></textarea>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center">
                            <button type="submit" class="btn rounded-4 btn-danger">Submit</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {apiService} from "../../services/apiService/apiService";
import { Auth } from "aws-amplify";

export default {
    name: "SupportComponent",
    data() {
        return {
            user: {},
            form: {
                issue_type: "",
                description: "",
            },
        };
    },
    methods: {
        onSubmit() {
            const body = {
                email: this.user?.attributes?.email,
                issue_type: this.form.issue_type,
                description: this.form.description,
            };
            apiService
            .updateSupport(body)
                .then(() => {

                    this.$store.state.toastMessage = "Query submitted successfully";
                    this.form.issue_type = "";
                    this.form.description = "";
                })
                .catch((error) => {
                    console.log("Query not submitted", error)
                    const errorMessage = { ...error };
                    this.$store.state.toastMessage = errorMessage.response.data.description || errorMessage.response.data;
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
    },
    created() {
        Auth.currentUserInfo().then((user) => {
            this.user = user;
        });
    },
};
</script>

<style scoped>

.tsukiji-prod-page-header {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.tsukiji-prod-page-title {
    font-size: 1.2rem;
    font-weight: bold;
    color:black;
}
</style>
