<template>
    <SharedModal :showModal="showModal" :handleClose="handleClose" :setImageToEditor="setImageToEditor" :activeTab="activeTab" />
    <div class="editor-view position-relative" :class="{'visually-hidden' : viewType !== 'editor'}">
        <div class="tsukiji-toolbar">
                <span class="pe-2 preview-icon" @click="toggleView()" v-if="content" style="z-index: 2">
                    Preview
                    <i class="bi bi-eye-fill" aria-hidden="true"></i>
                </span>
            </div>
        <Editor :content="content" ref="customEditor" @updateContent="updateContentValue($event)" v-if="loadEditor"
            key="" @toggleView="toggleView()" />
    </div>
</template>

<script>
import {apiService} from "@/services/apiService/apiService";
import ImagePicker from "./ImagePicker.vue";
import CustomEditor from "@/components/Shared/CustomEditor/CustomEditor.vue";
export default {
    name: "Editor-vue",
    props: ["viewType", "toggleView", "editorView","savedContent"],
    components: {
        SharedModal: ImagePicker,
        Editor: CustomEditor
    },
    data() {
        return {
            templateModal: true,
            loadEditor: false,
            content: "",
            viewContent: "",
            editCatName: false,
            tempName: this.$store.state.TemplateName,
            CatName: this.$store.state.TemplateCategory,
            showModal: false,
            activeTab: "existing",
            imageUploadedDate: [],
            uploadedImages: [],
            selectedImage: "",
            imageAddedData: {
                Editor: null,
                cursorLocation: null,
                resetUploader: null,
            },
            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {
                        modules: ["Resize", "DisplaySize", "Toolbar"],
                    },
                },
            },
            isFormSubmitted: false,
        };
    },
    methods: {
        updateContentValue(value) {
            this.content = value
            this.$emit('updateContent', this.content);
        },

        /**------------------------------------------------------
         * Used IN Parent Component (Template Editor)
         */
        getContent() {                                                                  //
            this.content = this.$refs.customEditor.editorContent;
            return this.content;
        },

        getTemplateById(bucketId) {
            if (!bucketId) {
                return false;
            }
            this.$store.state.isLoading = true;
            apiService.getCampaignTemplate(bucketId)
                .then((res) => {
                    this.tempName = res.data.name;
                    this.CatName = res.data.tag;
                    if (res?.data?.data) this.getTemplateByUrl(res.data.data);
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },

        getTemplateByUrl(url) {
            apiService.getUrlInfo(url).then((template) => {
                this.content = template.data;
            });
        },

        getTemplateFromUrl(url) {
            return new Promise((resolve, reject) => {
                try {
                    apiService.getUrlInfo(url).then((template) => {
                        resolve(template);
                    });
                } catch (e) {
                    reject(e);
                }
            });
        },

        getAllTemplateUrl(bucketId) {
            return new Promise((resolve, reject) => {
                try {
                    apiService.getCampaignTemplate(bucketId).then((res) => {
                        resolve(res.data.data);
                    });
                } catch (e) {
                    reject(e);
                }
            });
        },

        handleClick() {
            this.showModal = true;
        },
        handleClose() {
            this.showModal = false;
        },

        getDatesForImage() {
            this.$store.state.isLoading = true;
            apiService.getDatesForTheImage().then((res) => {
                this.imageUploadedDate = res.data;
                this.$store.state.isLoading = false;
                this.imageUploadedDate.forEach((value, index) => {
                    if (index == this.imageUploadedDate.length - 1) {
                        this.getImagesByDate(value);
                    }
                });
            });
        },

        getImagesByDate(date) {
            this.activeImageDate = date;
            this.$store.state.isLoading = true;
            apiService.getImagesByDate(date.replaceAll("/", "-")).then((res) => {
                this.uploadedImages = res.data;
                this.$store.state.isLoading = false;
            });
        },

        setImageToEditor(selectedImage) {
            if (this.imageAddedData.cursorLocation && selectedImage) {
                this.imageAddedData.Editor.insertEmbed(
                    this.imageAddedData.cursorLocation,
                    "image",
                    "https://" + this.selectedImage
                );
                this.imageAddedData.resetUploader();
            } else if (selectedImage) {
                this.content = this.content.concat(
                    `<img alt="" src="https://${selectedImage}"/>`
                );
            }
            this.selectedImage = null;
            this.showModal = false;
        },
    },

    async created() {
        const params = this.$route.params;
        const queryParams = this.$route.query;
        this.actionType = "Create";
        console.log("it runs ")
        if(this.savedContent){
            this.content = this.savedContent;
            this.loadEditor = true;
            return
        }
        if (params.templateId === "new" && queryParams.content) {
            this.loadEditor = true;
            const fileResponse = await this.getTemplateFromUrl(queryParams.content);
            this.content = fileResponse.data;
            console.log(" => new runs: ", this.loadEditor);
            return;
        }
        if (queryParams && queryParams.templateIds) {
            this.loadEditor = true;
            this.getTemplateyUrl(queryParams.templateIds);
        }
        if (
            params &&
            params.templateId &&
            params.templateId !== "new" &&
            !isNaN(Number(params.templateId))
        ) {
            this.templateId = params.templateId;
            this.actionType = "Update";
            this.getTemplateById(params.templateId);
            this.loadEditor = true;
        } else if (params.templateId !== "new") {
            this.content = params.templateId;
            this.loadEditor = true;
        }
        else {
            this.loadEditor = true;
        }
    },
};
</script>

<style >
.tsukiji-toolbar .preview-icon {
    cursor: pointer;
    background-color: #0c244a;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 6px;
}
</style>
