<template>
    <div class="container-fluid main-content-container px-4 pb-4">
        <div class="row">
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Name</strong></div>
                    <div class="col">
                        <strong>: {{ campaignStatsDetails.name }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Start Date</strong></div>
                    <div class="col">
                        <strong>: {{ campaignStatsDetails.s_date }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>End Date</strong></div>
                    <div class="col">
                        <strong>: {{ campaignStatsDetails.e_date }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Status</strong></div>
                    <div class="col">
                        <strong>: {{ campaignStatsDetails.c_status }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Identity</strong></div>
                    <div class="col">
                        <strong>: {{ campaignStatsDetails.email_used }}</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <!-- Rate -->
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Bounce Rate</strong></div>
                    <div class="col">
                        <strong>:
                            {{ Math.round(totalBounceRate) }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Spam Rate</strong></div>
                    <div class="col">
                        <strong>:
                            {{ Math.round(totalSpamRate) }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Delivery Rate</strong></div>
                    <div class="col">
                        <strong>:
                            {{ Math.round(totalDeliveryRate) }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Open Rate</strong></div>
                    <div class="col">
                        <strong>:
                            {{ Math.round(totalOpenedRate) }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Rejection Rate</strong></div>
                    <div class="col">
                        <strong>:
                            {{ Math.round(totalRejectRate) }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col"><strong>Sent Rate</strong></div>
                    <div class="col">
                        <strong>: {{ Math.round(totalSentRate) }}</strong>
                    </div>
                </div>
            </div>
        </div>

        <VueGoodTable mode="remote" v-on:page-change="onPageChange" v-on:per-page-change="onPerPageChange"
            :sort-options="{ enabled: false }" :totalRows="totalRecords" :pagination-options="{
                            enabled: true,
                            perPageDropdownEnabled: false,
                            setCurrentPage: currentPage,
                        }" max-height="300px" :rows="rows" :fixed-header="true" :columns="columns"
            class="advance-table">
            <template #table-row="{ row, column }">
                <span v-if="column.field == 'bounce'">
                    <span v-if="row.bounce == 'yes'" class="bi bi-check tsukiji-prod-icon-green">
                    </span>
                    <span v-else class="bi bi-x tsukiji-prod-icon-red"></span>
                </span>
                <span v-if="column.field == 'delivery'">
                    <span v-if="row.delivery == 'yes'" class="bi bi-check tsukiji-prod-icon-green">
                    </span>
                    <span v-else class="bi bi-x tsukiji-prod-icon-red"></span>
                </span>
                <span v-if="column.field == 'opened'">
                    <span v-if="row.opened == 'yes'" class="bi bi-check tsukiji-prod-icon-green">
                    </span>
                    <span v-else class="bi bi-x tsukiji-prod-icon-red"></span>
                </span>
                <span v-if="column.field == 'reject'">
                    <span v-if="row.reject == 'yes'" class="bi bi-check tsukiji-prod-icon-green">
                    </span>
                    <span v-else class="bi bi-x tsukiji-prod-icon-red"></span>
                </span>
                <span v-if="column.field == 'sent'">
                    <span v-if="row.sent == 'yes'" class="bi bi-check tsukiji-prod-icon-green">
                    </span>
                    <span v-else class="bi bi-x tsukiji-prod-icon-red"></span>
                </span>
                <span v-if="column.field == 'spam'">
                    <span v-if="row.spam == 'yes'" class="bi bi-check tsukiji-prod-icon-green">
                    </span>
                    <span v-else class="bi bi-x tsukiji-prod-icon-red"></span>
                </span>
            </template>
        </VueGoodTable>
    </div>
</template>

<script>
import { apiService } from "../../services/apiService/apiService";
import { VueGoodTable } from "vue-good-table-next";

export default {
    name: "CampaignStats",
    props: ["campaignStatsDetails"],
    components: {
        VueGoodTable,
    },

    data() {
        return {
            isLoading: false,
            totalRecords: 0,
            currentPage: 1,
            campaignsHealth: 0,
            totalOpenedRate: 0,
            totalSentRate: 0,
            totalDeliveryRate: 0,
            totalBounceRate: 0,
            totalSpamRate: 0,
            totalRejectRate: 0,
            columns: [
                {
                    label: "S No.",
                    field: "SNo",
                },
                {
                    label: "Name",
                    field: "name",
                },
                {
                    label: "Email",
                    field: "cust_email",
                },
                {
                    label: "Bounce",
                    field: "bounce",
                },
                {
                    label: "Delivery",
                    field: "delivery",
                },
                {
                    label: "Opened",
                    field: "opened",
                },
                {
                    label: "Reject",
                    field: "reject",
                },
                {
                    label: "Sent",
                    field: "sent",
                },
                {
                    label: "Spam",
                    field: "spam",
                },
            ],
            rows: [],
            serverParams: {
                page: 1,
                perPage: 10,
            },
        };
    },

    methods: {
        getCampaignStats() {
            this.$store.state.isLoading = true;
            apiService.getCampaignsStats(
                this.campaignStatsDetails.id,
                this.serverParams.page - 1
            )
                .then((res) => {
                    this.currentPage = this.serverParams.page;
                    this.$store.state.isLoading = false;
                    this.$store.state.campaigns = res.data.campaign_stats;
                    this.totalRecords = res.data.total_customers_mapped;
                    const row = apiService.objectItemIndex(this.$store.state.campaigns);
                    this.rows = row.map(function (el) {
                        const o = Object.assign({}, el);
                        if (el.customer_details.first_name === null && el.customer_details.last_name === null) {
                            o.name = "- -";
                        } else {
                            o.name = el.customer_details.first_name + " " + el.customer_details.last_name;
                        }
                        o.cust_email = el.customer_details["cust_email "];
                        return o;
                    });
                    this.campaignsHealth = res.data.campaign_health_stats_current_page;
                    this.totalOpenedRate = res.data.total_opened_rate;
                    this.totalSentRate = res.data.total_sent_rate;
                    this.totalDeliveryRate = res.data.total_delivery_rate;
                    this.totalBounceRate = res.data.total_bounce_rate;
                    this.totalSpamRate = res.data.total_spam_rate;
                    this.totalRejectRate = res.data.total_reject_rate;
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.isLoading = false;
            this.getCampaignStats();
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
        },

        setMinimumWidth() {
            const ele = document.getElementsByClassName("modal-dialog")[0];
            ele.classList.add("stats-modal-dialog");
        },
    },

    created() {
        this.getCampaignStats();
        setTimeout(() => {
            this.setMinimumWidth();
        });
    },
};
</script>

<style scoped>
strong {
    font-weight: bold;
}

.tsukiji-prod-icon-green {
    display: inline-block;
    padding: 0px 20px;
    font-size: 25px;
    color: green;
}

.tsukiji-prod-icon-red {
    display: inline-block;
    padding: 0px 20px;
    color: red;
    font-size: 25px;
}
</style>
