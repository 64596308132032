<template>
    <div class="w-100 h-100">
        <div class="w-100">
            <template v-for="(item, index) in  errorMsg">
                <div class="d-flex justify-content-center" v-if="item.shown == true" :key="index">
                    <div class="alert alert-danger tsukiji-prod-w-90" role="alert">{{ item.name }}</div>
                </div>
            </template>
        </div>
        <div class="w-100 h-100">
            <div v-if="currentStep.index == 0" class="w-100 d-flex">

                <div v-if="selectedFiles.length >= 1" style="width:200px;height:297px;gap:10px; overflow-x: auto;"
                    class=" d-flex flex-column  align-items-center ps-3">

                    <div v-for="(item, index) in selectedFiles" :key="index" class="pb-2 border tsukiji-prod-file-item"
                        style="height: 100px;width: 121px;background-color: #F4F7FD;border-radius: 4px;position: relative;">
                        <div class="h-75 w-100 d-flex justify-content-center align-items-center"><i
                                class="material-icons bi bi-file-earmark my-auto" style="font-size:57px"></i></div>
                        <p class="text-center text-truncate px-2 pt-1 w-100">{{ item.name }}</p>
                        <i class="material-icons  bi bi-x-lg tsukiji-prod-delete-icon" @click="removeFile(index)"
                            style="font-size:20px;position: absolute;left: 84%;top: -3px;cursor: pointer"></i>
                    </div>

                </div>

                <div class="d-flex flex-grow-1  justify-content-center flex-column align-items-center main-upload pt-3">
                    <div class="d-flex flex-column justify-content-center align-items-center ">

                        <span><i class="material-icons bi bi-file-earmark-fill w-100 h-100 "
                                style="font-size:36px"></i></span>
                        <h3 class="w-100 h-100 text-center">
                            <p>Upload Your Contact List</p>
                        </h3>
                        <p class="w-100 h-100 text-center" style="font-size:18px">Supported File Formats : .xlsx ,.xls,
                            .csv, .ods</p>
                    </div>


                    <div class="row mt-4 w-100 ">
                        <div class="col-6 d-flex justify-content-end"><input type="file" ref="fileInput"
                                v-on:change="handleFileChange" style="display: none;"
                                accept=".xlsx,.xls,.csv,." /><button class="btn-danger  btn rounded-4 me-2 "
                                @click="fileSelected"><i class="material-icons bi bi-file-earmark-plus me-1"></i>Add
                                Files</button></div>
                        <div class="col-6"> <input type="file" ref="folderInput" v-on:change="handleFolderChange"
                                style="display:none" webkitDirectory /><button class=" btn rounded-4 btn-danger ms-2"
                                @click="folderSelected"><i class="material-icons me-1 bi bi-folder-plus"></i>Add
                                Folder</button>
                        </div>
                    </div>
                </div>
            </div>


            <div v-if="currentStep.index == 1"
                class="w-100 d-flex justify-content-center align-items-center pt-2 h-100">

                <div class="row w-75 h-100">
                    <div class="row p-1 col-12  bg-light">
                        <div class=" col-6 p-0 text-center" style="font-size:18px">Available Columns</div>
                        <div class="text-center col-6" style="font-size:18px">Detected Columns</div>
                    </div>

                    <div class="w-50 h-100">
                        <div class="w-100" v-for="(item, index) in dataKeys" :key="index">
                            <div v-if="item.error == true"
                                class="w-100 p-2  d-flex align-items-center justify-content-between"
                                style="font-size:18px">
                                <span style="color:red">{{ item.available }} ! </span><i
                                    class="material-icons bi bi-arrow-right" style="font-size:18px"></i>
                            </div>
                            <div v-else class="w-100 p-2  d-flex align-items-center justify-content-between"
                                style="font-size:18px"><span>{{ item.available }}</span><i
                                    class="material-icons bi bi-arrow-right" style="font-size:18px"></i></div>
                        </div>
                    </div>
                    <div class="w-50 h-100">
                        <div v-for="(item, index) in dataKeys" :key="index"
                            class="d-flex justify-content-center align-items-baseline w-100">
                            <div class="btn-group w-75">
                                <button type="button" class=" btn rounded-4 mt-2 btn-outline-success dropdown-toggle"
                                    style="height: 34px; width: 70%;" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ item.selected }}
                                </button>
                                <ul class="dropdown-menu">
                                    <template v-for="(dItem, dIndex) in sheetHeaders">
                                        <li :key="dIndex" v-if="dItem.taken == false"><a class="dropdown-item"
                                                @click=" changeDataKeys(dItem, dIndex, index, item.selected)">{{dItem.value}}</a></li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="currentStep.index == 2" class="pt-1">
                <div v-if="validatedError == true"
                    class="d-flex align-items-center justify-content-center flex-column h-100" style="gap:10px">
                    <div class="d-flex align-items-center justify-content-center flex-column tsukiji-prod-w-81 pt-3 "
                        style="gap:16px;height:305px;overflow:auto">
                        <template v-for="(item, index) in validatedData">
                            <div v-if="item.emailError == true" :key="index" style="height:51px;border-radius: 4px"
                                class=" w-100 border d-flex  justify-content-between p-1 align-items-center">
                                <p class="ms-2">Sheet1 / Row {{ index }} / column 0</p>
                                <span>Email <i class="material-icons bi bi-arrow-right" style="font-size:18px"></i>{{
                                    item.Email }}</span>
                                <div>
                                    <div class="btn-group">
                                        <button type="button" class=" btn rounded-4 btn-success dropdown-toggle"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            Skip
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" @click="skipRow(index)" href="#">Skip Row</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div>
                        <button class=" btn rounded-4 btn-primary" @click="skipAll()">Skip All</button>
                    </div>
                </div>
                <p v-else class="text-center">Ready to Import Leads. Tap Finish</p>
            </div>
        </div>
    </div>
</template>

<script>
import dataMappingService from '@/services/utils/datamapping.service';
import spreadsheetService from '@/services/utils/spreadsheet.service';
import validateImportService from "@/services/utils/validateimport.service"
export default {
    name: "StepperComponent",
    props: ['clickedNext', 'currentStep', 'share-data', 'clicked', 'nextStep'],
    data() {
        return {
            stepperClicked: this.clicked,
            staticKeys: dataMappingService.availableKeys,
            sheetHeaders: dataMappingService.sheetHeaders,
            sheetData: spreadsheetService.sheetData,
            selectedFiles: [],
            filesData: [],
            dataKeys: dataMappingService.mapData,
            errorMsg: dataMappingService.errorMsg,
            headers: [],
            mappedData: [],
            validatedData: validateImportService.validatedMappedData,
            validatedError: validateImportService.dataError,
        }
    },
    methods: {
        checkError() {
            let checkValue = null;
            for (let i = 0; i < this.validatedData.length; i++) {
                if (this.validatedData[i].emailError == true) {
                    checkValue = true;
                    break;
                } else {
                    checkValue = false;
                }
            }

            this.validatedError = checkValue;
        },
        skipRow(index) {
            const newValidatedValue = validateImportService.skipRow(this.validatedData, index);
            this.validatedData = newValidatedValue;
            this.checkError()
        },
        skipAll() {
            const newValue = validateImportService.skipAll();
            this.validatedError = false
            this.validatedData = newValue
            console.log(" => new validated Data: ", this.validatedData);
        },
        // Validation Check for the step 2
        changeDataKeys(dItem, dIndex, index, dataItem) {
            const newObj = dataMappingService.changeAvailableValues(dItem, dIndex, index, dataItem, this.clicked,this.dataKeys);
            this.dataKeys = newObj.data;
            if (newObj.next == true) {
                this.$emit('can-continue', { value: true });
                this.mappedData = dataMappingService.validateKeys(this.dataKeys, this.sheetData);
            }
        },
        folderSelected() {
            this.$refs.folderInput.click();
        },
        fileSelected() {
            this.$refs.fileInput.click();
        },
        buttonClicked() {
            this.$emit('can-continue', { value: true });
        },
        handleFolderChange() {
            const selectedFolder = this.$refs.folderInput.files[0];
            this.selectedFiles.push(selectedFolder)
        },
        handleFileChange() {
            const selectedFile = this.$refs.fileInput.files[0];
            spreadsheetService.readFile(selectedFile);
            if(this.selectedFiles.length >= 1) {
                this.selectedFiles = []
                spreadsheetService.unselectFile();
                this.revertValues();
            }
            this.selectedFiles.push(selectedFile)
            this.$emit('can-continue', { value: true });
        },
        removeFile(index) {
            this.$refs.fileInput.value = null
            this.selectedFiles.splice(index, 1);
            spreadsheetService.unselectFile();
            this.revertValues();
            this.$emit('can-continue', { value: false });
        },
        checkAvailableKeysLength() {
            let takenValue = 0;
            this.sheetHeaders.map((item) => {
                if (item.taken == true) {
                    takenValue++;
                }
            })
            return takenValue
        },
        validationImportsStep() {
            const valCheck = validateImportService.validationCheck(this.mappedData);
            this.validatedData = validateImportService.validatedMappedData;
            this.validatedError = valCheck.check
        },
        revertValues() {
            dataMappingService.revertMapData();
            this.dataKeys = dataMappingService.mapData,
            this.sheetHeaders = dataMappingService.sheetHeaders
        }
    },
    mounted() {
        this.revertValues()
    },
    watch: {
        currentStep: function (newValue) {
            if (newValue.index == 2) {
                this.validationImportsStep()
            }
        },
        clicked: function () {
            if (this.nextStep === false) {
                if (this.currentStep.index == 1 && !this.clicked == 0) {
                    dataMappingService.checkDataMapping();
                }
            }

            if (this.validatedError == false && this.currentStep.index == 2 && !this.clicked == 0) {
                this.$emit('can-continue', { value: true });
                this.$emit('share-data', { data: this.validatedData, groupName: spreadsheetService.groupName });
            }
        }
    }
}
</script>
<style scoped>
.w-40 {
    width: 40% !important;
}

.tsukiji-prod-w-81 {
    width: 81%;
}

.tsukiji-prod-w-90 {
    width: 90% !important;
}

.tsukiji-prod-delete-icon {
    display: none;
}

.tsukiji-prod-file-item:hover .tsukiji-prod-delete-icon {
    display: block
}

.btn-sm {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}
</style>