// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsConfig = {
    "aws_project_region"            : process.env.VUE_APP_aws_project_region,
    "aws_cognito_identity_pool_id"  : process.env.VUE_APP_aws_cognito_identity_pool_id,
    "aws_cognito_region"            : process.env.VUE_APP_aws_cognito_region,
    "aws_user_pools_id"             : process.env.VUE_APP_aws_user_pools_id,
    "aws_user_pools_web_client_id": process.env.VUE_APP_aws_user_pools_web_client_id,

};

export default awsConfig;
