<template>
    <div>
        <div class="d-flex justify-content-between tsukiji-prod-temp-header tsukiji-prod-page-header py-4">
            <h3 class="tsukiji-prod-page-title" id="Widgets-title">App & Integration</h3>
        </div>
        <div class="d-flex flex-wrap" style="margin-left: 10px;">
            <div class="card" style="width: 18rem; margin-right: 10px; margin-bottom: 10px;"
                v-for="(app, index) in appData" :key="index">
                <img :src="app.logoUrl" style="height: 163px !important;" class=" border card-img-top">
                <div class="card-body d-flex flex-column justify-content-between">
                    <p class="card-text">{{ app.name.charAt(0).toUpperCase() + app.name.slice(1) }} </p>
                    <button class="btn btn-primary mt-auto" @click="connectApps(app.name)">Connect</button>
                </div>
            </div>
        </div>
        <ConfigModal ref="modalRef" />
    </div>
</template>

<script>
import configModal from '../app&Integrate/ConfigModal.vue'
import { apiService } from "../../services/apiService/apiService";


export default {
    name: "integration-vue",
    data() {
        return {

            appData: null
        }
    },
    components: {
        ConfigModal: configModal
    },
    methods: {
        connectApps(name) {
            this.$store.state.isLoading = false;
            apiService.connectApps(name).then((res) => {
                console.log("we got this response from salesforce", res);
                if (res?.data?.url) {
                    console.log("comes here ");
                    window.location.href = res?.data?.url;
                }
            }).catch(error => {
                console.error("Error connecting app:", error);
            }).finally(() => {
                this.$store.state.isLoading = false;
            });
        },
        fetchApp() {
            this.$store.state.isLoading = false;
            apiService.fetchLeadsData().then((res) => {
                this.appData = res.data;
                console.log("Fetched app data:", this.appData);
            }).catch(error => {
                this.$store.state.isLoading = false;
                console.error("Error fetching app data:", error);
            }).finally(() => {
                this.$store.state.isLoading = false;
            });
        }
    },

    mounted() {
        this.fetchApp();
    }
}
</script>
<style></style>
