<template>
    <div class="container-fluid main-content-container pb-4">
        <div class="row no-gutters content-align tsukiji-prod-page-header py-4">
            <div class="col-sm-4  text-sm-left mb-4 mb-sm-0">
                <h3 class="tsukiji-prod-page-title"><b>Launch Template</b></h3>
            </div>
        </div>
        <div class="row m-0">
            <div class="card col-sm-12 col-md-6  custom-new-card py-4 px-4" style="margin-left: 18px;">
                <form @submit.prevent="launchTemplate">
                    <div class="row">
                        <div class="col mb-4 mb-sm-2">
                            <label for="campaign-subject" class="form-label">Subject</label>
                            <input type="text" id="campaign-subject" class="form-control" v-model="campaignSubject"
                                name="campaignSubject" @input="this.isFormValidCheck('campaignSubject')"
                                :class="{ 'is-invalid': this.editCampaignSubject }" required
                                placeholder="Enter Subject" />
                            <!-- This will only be shown if the preceding input has an invalid state -->
                            <div class="invalid-feedback">
                                Invalid
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-4 mb-sm-2">
                            <label for="template" class="form-label">Template</label>
                            <select id="template" v-model="template" class="form-select form-control"
                                @change="isFormValidCheck('template')" :class="{ 'is-invalid': this.editTemplate }"
                                required>
                                <option v-for="(option, index) in templateList" :key="index" :value="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                            <div class="invalid-feedback" :class="{ 'd-block': this.editTemplate }">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-4 mb-sm-2">
                            <label for="group" class="form-label">Group</label>
                            <select id="group" v-model="leadsGroups" @change="isFormValidCheck('leadsGroups')"
                                class="form-select form-control" :class="{ 'is-invalid': this.editLeadsGroups }"
                                required>
                                <option v-for="(option, index) in groupList" :key="index" :value="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                            <div class="invalid-feedback" :class="{ 'd-block': this.editLeadsGroups }">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-4 mb-sm-2">
                            <label for="email" class="form-label">Mail to use</label>
                            <select id="email" v-model="mailToUse" class="form-select form-control"
                                @change="isFormValidCheck('mailToUse')" :class="{ 'is-invalid': this.editMailToUse }"
                                required>
                                <option v-for="(option, index) in mailToUseList" :key="index" :value="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                            <div class="invalid-feedback" :class="{ 'd-block': this.editMailToUse }">
                                Required
                            </div>
                        </div>
                    </div>

                    <div class="tsukiji-prod-page-header py-2 ">
                        <button class=" btn rounded-4 btn-danger">Launch Template</button>
                    </div>

                </form>
            </div>
            <div class="col-sm-12 col-md d-flex justify-content-center">
                <img :src="require('@/assets/launch.png')" class="img-fluid" style="max-width: 400px;" />
            </div>
        </div>
    </div>
</template>

<script>
import { apiService } from '@/services/apiService/apiService';
export default {
    name: "Launch-vue",
    data() {
        return {
            campaignSubject: null,
            template: null,
            leadsGroups: null,
            mailToUse: null,
            editCampaignSubject: null,
            editTemplate: null,
            editLeadsGroups: null,
            editMailToUse: null,
            templateList: this.formatOptions(this.$store.state.templates, "Template"),
            groupList: [],
            mailToUseList: apiService.formatOptionsWithKey(
                this.$store.state.mailToUse,
                "Identities",
                "email"
            ),
            second: false,
            secondStepError: null,
            isFormSubmitted: false,
            successIdentities: null,
            friendlyName: null,
            editFriendlyName: null,
            rows: null
        };
    },
    methods: {
        getCampaignName() {
            const template = this.templateList.find(
                (template) => template.value === this.template
            );
            const name =
                template.text +
                apiService.getFormattedDate(new Date()) +
                apiService.makeId(1);
            return name;
        },
        launchTemplate() {

            this.isFormSubmitted = true;
            const checkValue = this.checkAllValidation()
            if (!checkValue) return
            this.$store.state.isLoading = true;
            const body = {
                name: this.getCampaignName(),
                subject: this.campaignSubject,
                tid: this.template,
                sdate: apiService.getFormattedDate(new Date()),
                edate: apiService.getNextDay(1),
                cgrp: this.leadsGroups,
                mailToUse: this.mailToUse,
                friendlyName: this.friendlyName,
            };

            apiService.createCampaigns(body)
                .then(() => {
                    this.balanceDeduction()
                    this.$store.state.isLoading = false;
                    this.$store.state.toastMessage = "Template Launched Successfully !!";
                    this.isFormSubmitted = false;
                    this.template = null;
                    this.leadsGroups = null;
                    this.mailToUse = null;
                    this.friendlyName = null;
                    this.campaignSubject = null;
                })
                .catch((error) => {
                    const errorMessage = { ...error };
                    this.$store.state.toastMessage =
                        errorMessage.response.data.message || errorMessage.response.data;
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        balanceDeduction() {
            const group = this.rows.find((data) => data.id === this.leadsGroups);
            if (group && this.$store.state.balance) {
                this.$store.state.balance -= group.leadsCount
            }
        },
        setError() {
            this.secondStepError = "This is an error!";
        },
        isFormValidCheck(key) {
            switch (key) {
                case 'mailToUse':
                    if (!this.mailToUse) {
                        this.editMailToUse = true;
                    } else {
                        this.editMailToUse = false;
                        this.getFriendlyName();
                    }
                    break;

                case "campaignSubject":
                    this.editCampaignSubject = !this.campaignSubject;
                    break;

                case 'template':
                    this.editTemplate = !this.template;
                    break;

                case 'leadsGroups':
                    this.editLeadsGroups = !this.leadsGroups;
                    break;

                case 'friendlyName':
                    this.editFriendlyName = !this.friendlyName;
                    break;

                default:
                    break;
            }
        },
        checkAllValidation() {
            if (!this.leadsGroups || !this.mailToUse || !this.template || !this.friendlyName || !this.campaignSubject) {
                const newArr = ['template', 'leadsGroups', 'mailToUse', 'friendlyName', 'campaignSubject']
                for (let index = 0; index < newArr.length; index++) {
                    this.isFormValidCheck(newArr[index]);
                }
                return false
            }
            else return true
        },
        formatOptions(array, name) {
            const options = [];
            const defaultValue = {
                value: null,
                text: "Select " + name,
            };
            options.push(defaultValue);
            array &&
                array.map((item) => {
                    const data = {
                        value: item.id,
                        text: item.name,
                    };
                    options.push(data);
                });
            return options;
        },
        getTemplates() {
            apiService.getTemplates()
                .then((res) => {
                    this.$store.state.templates = res.data;
                    const arr = [];
                    for (let ele in res.data.All_templates) {
                        arr.push(res.data.All_templates[ele]);
                    }
                    this.$store.state.templates = res.data.Total_templates ? arr : [];
                    this.templateList = this.formatOptions(
                        this.$store.state.templates,
                        "Template"
                    );
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        backToLaunch() {
            this.$router.push({ name: "launch" });
        },
        getGroups() {
            apiService.getGroups()
                .then((res) => {
                    const leadGroup = res.data.filter(
                        (item) => item.leadsCount > 0 && item.status === "ACTIVE"
                    );
                    this.groupList = apiService.formatOptionsWithKeyValue(
                        leadGroup,
                        "Group",
                        "name",
                        "id"
                    );
                    console.log(" => this.groupList: ", this.groupList);
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        getIdentityEmail() {
            this.$store.state.isLoading = true;
            apiService.identityManagement()
                .then((res) => {
                    const identityWithIndex = apiService.objectItemWithIndex(res.data);
                    this.successIdentities = identityWithIndex.filter(
                        (item) => item.status === "Success"
                    );
                    this.mailToUseList = apiService.formatOptionsWithKey(
                        this.successIdentities,
                        "Identities",
                        "email"
                    );
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        navigateTo() {
            this.$router.push({ name: "launch" });
        },
        getFriendlyName() {
            for (let i = 0; i < this.successIdentities?.length; i++) {
                if (this.successIdentities[i].email == this.mailToUse) {
                    console.log(" => identities: ", this.successIdentities[i]);
                    this.friendlyName = this.successIdentities[i].friendlyName;
                }
            }
        },
        getLeadsGroup() {
            this.$store.state.isLoading = true;
            apiService.getCustomerGroups().then(res => {
                this.$store.state.isLoading = false;
                this.$store.state.leadsGroups = res.data;
                this.rows = res.data;
            }).catch(() => {
                this.$store.state.toastMessage = "Something went wrong!";
            }).finally(() => {
                this.$store.state.toastMessage = "";
                this.$store.state.isLoading = false;
            });
        },
    },
    created() {
        this.getTemplates();
        this.getGroups();
        this.getIdentityEmail();
        this.getLeadsGroup();
    },
    mailToUse(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.friendlyName = ''; // clear the friendlyName value
            this.isFormValidCheck('mailToUse'); // perform form validation
        }
    },
};
</script>

<style>
.tsukiji-prod-form-block {
    height: 0px;
}

.btn-primary {
    padding: 0.5625rem 1rem;
}
.tsukiji-prod-page-title{
    margin-left: 17px;
}
</style>
